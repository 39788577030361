import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import _ from 'lodash';

import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader, Stack } from '@mui/material';

import BaseOptionChart from '../BaseChartOption/base-chart-options';
import { Shimmer } from '../../../../ui-components';

const CHART_HEIGHT = 475;
const LEGEND_HEIGHT = 125;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
    height: CHART_HEIGHT,
    marginTop: 0,
    '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
    '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
        overflow: 'visible',
    },
    '& .apexcharts-legend': {
        height: LEGEND_HEIGHT,
        alignContent: 'center',
        position: 'relative !important',
        borderTop: `solid 1px ${theme.palette.divider}`,
        top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
    },
}));

const PieChart = ({
    heading,
    dataLoading,
    dataSource
}) => {
    const theme = useTheme();

    const chartOptions = merge(BaseOptionChart(), {
        labels: _.map(dataSource, entry => entry.label),
        stroke: { colors: [theme.palette.background.paper] },
        legend: { floating: true, horizontalAlign: 'center' },
        dataLabels: { enabled: true, dropShadow: { enabled: false } },
        tooltip: {
            fillSeriesColor: false,
            y: {
                // formatter: (seriesName) => fNumber(seriesName),
                title: {
                    formatter: (seriesName) => `${seriesName}`,
                },
            },
        },
        plotOptions: {
            pie: {
                // customScale: 1.25,
                donut: {
                    size: '65%',
                    labels: {
                        name: {
                            color: theme.palette.text.secondary
                        },
                        total: {
                            formatter: (w) => {
                                const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);

                                return sum;
                            }
                        }
                    }
                }
            }
        }
    });

    const CHART_DATA = _.map(dataSource, entry => entry.count);

    return (
        <Card>
            <CardHeader title={heading} sx={{ textAlign: 'center' }} />
            <ChartWrapperStyle dir="ltr">
                {dataLoading
                    ?
                    <Stack justifyContent="center" alignItems="center">
                        <Shimmer variant="circle" width={300} height={300} />
                    </Stack>
                    :
                    <ReactApexChart type="donut" series={CHART_DATA} options={chartOptions} height={350} />
                }
            </ChartWrapperStyle>
        </Card>
    );
}

export default PieChart;
import React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Avatar,
    Typography,
    Button,
    Link,
    Grid
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    root: {
        padding: 12,
        [theme.breakpoints.up('lg')]: {
            marginLeft: 100,
            marginRight: 100
        },
        [theme.breakpoints.up('xl')]: {
            marginLeft: 150,
            marginRight: 150
        }
    },
    card: {
        marginBottom: 8,
        position: "relative",
        height: 240
    },
    cardContent: {
        marginBottom: "35px"
    },
    cardAction: {
        position: "absolute",
        bottom: 0
    },
    title: {
        fontSize: 13,
    },
    description: {
        marginBottom: 12,
    }
}));

const LinkRouter = props => <Link {...props} component={RouterLink} />;

export const BlockMenu = (props) => {
    const classes = useStyles();

    let history = useHistory();

    const itemClickHandler = (item) => {
        history.push(item.routePath);
    }

    return (
        <Grid container
            className={classes.root}
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start">
            {props.menuItems && props.menuItems
                .map((item, index) =>
                    <Grid item sm={12} md={6} lg={4} xl={3}>
                        <Card key={index} className={classes.card}>
                            <CardHeader
                                title={
                                    <Typography variant="h6">
                                        <LinkRouter key={index} color="textSecondary" to={item.routePath}>
                                            {item.title}
                                        </LinkRouter>
                                    </Typography>
                                }
                                avatar={
                                    <Avatar className={classes.avatar}>
                                        {item.icon}
                                    </Avatar>
                                }
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography className={classes.description}>
                                    {item.description}
                                </Typography>
                            </CardContent>
                            <CardActions className={classes.cardAction}>
                                <Button size="small" onClick={() => itemClickHandler(item)}>Open</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                )}
        </Grid>
    );
}
import {
    Accordion, AccordionActions, AccordionDetails,
    AccordionSummary, Tooltip, Button, Divider, Grid, TextField, Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from "lodash";
import React, { useContext, useEffect, useMemo } from 'react';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SearchContext, UserContext } from '../../../../contexts';
import { useADUsersOptions } from '../../../../hooks';
import useStyles from "../../../../styles/useStyles";
import { KeyValueListSoDAnalysis, LoadingButton2 } from '../../../../ui-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import SodAnalysisResultTable from './SodAnalysisResultTable';
import { NavRoutes } from "../../../../utils";

const getUserData = (newSoDAnalysis) => {
    if (newSoDAnalysis !== undefined) {
        return {
            incident: newSoDAnalysis.incident,
            user: newSoDAnalysis.user?.accountName,
            userCompany: newSoDAnalysis.user?.companyName,
            jobProfile: newSoDAnalysis.user?.jobTitle,
            riskOwner: newSoDAnalysis.user?.displayName,
            userInvolved: newSoDAnalysis.usersInvolved,
            tjpInvolved: newSoDAnalysis.tjPs?.map(x =>
                <Tooltip
                    key={x.id}
                    title={x.roleName} >
                    <Typography style={{ fontSize: 14 }} noWrap>{x.roleName}</Typography>
                </Tooltip >)
        };
    }
};

const NewSoDAnalysisForm = ({ hook: sodAnalysisHook }) => {
    const classes = useStyles();
    const history = useHistory();
    const {
        fetchUserOptions,
        userOptions,
        loadingUserOptions
    } = useADUsersOptions();

    const {
        state: state,
        isLoadingEntityOptions,
        loadEntityOptions,
        cleanEntityOptions
    } = useContext(SearchContext);

    const { state: userState } = useContext(UserContext);

    const {
        getNewSoDAnalysis,
        newSoDAnalysis,
        loadSodActivities,
        newSoDAnalysisCompleted,
        loadingNewSoDAnalysis,
        sodAnalysesLoading
    } = sodAnalysisHook;

    useEffect(() => {
        async function fetchFromApi() {
            await loadEntityOptions(0);
        }
        fetchFromApi();
        return () => {
            cleanEntityOptions();
        }
    }, [])

    const userData = useMemo(() => getUserData(newSoDAnalysis), [newSoDAnalysis]);

    const dataLabels = [
        { property: 'incident', label: 'Incident' },
        { property: 'user', label: 'User' },
        { property: 'userCompany', label: 'User Company' },
        { property: 'userManager', label: 'User\'s Manager' },
        { property: 'jobProfile', label: 'Job Profile' },
        { property: 'riskOwner', label: 'Risk Owner' },
        { property: 'userInvolved', label: 'Users involved' },
        { property: 'tjpInvolved', label: 'TJP Involved' }
    ];

    const [incidentNumValue, setIncidentNumValue] = React.useState('');
    const [ownerSelected, setOwnerSelected] = React.useState();
    const [transactionSelected, setTransactionSelected] = React.useState([]);

    const onChangeOwner = (event, item) => {
        setOwnerSelected(item);
    };

    const renderOptionLabelOwner = option => `${option?.displayName} - ${option?.samAccountName}`;

    const searchOptionsOwner = async (e, searchText) => { e && e.type !== "click" && await fetchUserOptions(searchText, 25); }

    const onChangeTransactions = (item) => { setTransactionSelected(item); };

    const handleChange = (event) => { setIncidentNumValue(event.target.value); };

    const renderOptionLabelTransactions = option => { return option.value; };

    const searchOptionsTransactions = async (e, searchText) => { e && e.type !== "click" && await loadEntityOptions(0, searchText); }

    const onClickButton = async () => {
        const newSoDAnalysisInput = {
            AnalystUser: {
                Id: userState.applicationUser.id,
                DisplayName: userState.applicationUser.displayName,
                SamAccountName: userState.applicationUser.accountName,
                Email: userState.applicationUser.mail
            },
            Owner: ownerSelected,
            Transactions: transactionSelected,
            IncidentNumber: incidentNumValue
        }
        if (!loadingNewSoDAnalysis) {
            await getNewSoDAnalysis(newSoDAnalysisInput);
        }
    };

    const onClickDetailsButton = async () => {
        if (newSoDAnalysis !== undefined)
            history.push(NavRoutes.SodAnalysisAdmin(newSoDAnalysis?.id));
    };

    return (
        <Accordion
            defaultExpanded
            expanded={newSoDAnalysisCompleted}
        >
            <AccordionSummary >
                <Grid container justifyContent="center">
                    <Typography variant="h6">
                        <center>New SoD Analysis</center>
                    </Typography>
                    <Grid container justifyContent="space-evenly">
                        <Grid item xs={12} style={{ flexBasis: "15%" }}>
                            <Autocomplete
                                onChange={(event, item) => {
                                    onChangeOwner(event, item);
                                }}
                                value={ownerSelected}
                                size="small"
                                options={userOptions}
                                getOptionLabel={renderOptionLabelOwner}
                                getOptionSelected={(option, value) =>
                                    value === undefined || value === "" || option.key === value.key
                                }
                                required
                                loading={loadingUserOptions}
                                onInputChange={_.debounce(searchOptionsOwner, 600)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="User"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        InputLabelProps={{
                                            style: { fontSize: 14 }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ flexBasis: "15%" }}>
                            <Autocomplete
                                onChange={(event, item) => {
                                    onChangeTransactions(item);
                                }}
                                value={transactionSelected}
                                size="small"
                                options={state.entities}
                                getOptionLabel={renderOptionLabelTransactions}
                                getOptionSelected={
                                    (option, value) =>
                                        value === undefined || value === "" || option.key === value.key
                                }
                                required
                                loading={isLoadingEntityOptions}
                                multiple={true}
                                onInputChange={_.debounce(searchOptionsTransactions, 600)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Name"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        InputLabelProps={{
                                            style: { fontSize: 14 }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ flexBasis: "15%" }}>
                            <TextField
                                className={classes.fontTitleSmall}
                                size="small"
                                value={incidentNumValue}
                                variant="outlined"
                                margin="normal"
                                id="incidentNumberId"
                                label="Incident number"
                                name="incidentNumber"
                                onChange={handleChange}
                                autoComplete="off"
                                required
                                InputProps={{
                                    style: { fontSize: 14 }
                                }}
                                InputLabelProps={{
                                    style: { fontSize: 14 }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-end">
                        <LoadingButton2
                            color='primary'
                            size='medium'
                            type='submit'
                            variant='contained'
                            loading={loadingNewSoDAnalysis || sodAnalysesLoading}
                           // disabled={loadingNewSoDAnalysis || sodAnalysesLoading}
                            title="Run SoD Analysis"
                            // done={!loadingNewSoDAnalysis}
                            onClick={onClickButton}
                        />
                        {/* 
                        <LoadingButton
                            color='primary'
                            size='medium'
                            type='submit'
                            variant='contained'
                            onClick={onClickButton}
                            loading={loadingNewSoDAnalysis}
                        >
                            Run SoD Analysis
                        </LoadingButton> */}
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Divider />

                {newSoDAnalysisCompleted && loadSodActivities
                    ?
                    <CircularProgress />
                    :
                    <Grid style={{ display: "flex", }}>
                        <Grid item sm={4}>
                            <KeyValueListSoDAnalysis data={userData} labels={dataLabels} />
                        </Grid>
                        <Grid item sm={12}>
                            <SodAnalysisResultTable items={newSoDAnalysis} />
                        </Grid>
                    </Grid>
                }
                <Divider />
            </AccordionDetails>
            <Divider />
            <AccordionActions  >
                {newSoDAnalysisCompleted ?
                    <Grid container justifyContent="flex-end">
                        <Button
                            color='primary'
                            size='medium'
                            type='submit'
                            variant='contained'
                            onClick={onClickDetailsButton}
                            disabled={newSoDAnalysis?.StatusTypology === 0}
                        >
                            Open SoD Analysis
                        </Button>
                    </Grid>
                    :
                    undefined}
            </AccordionActions>
        </Accordion>
    );
}
export default NewSoDAnalysisForm;
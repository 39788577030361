import React, { useContext, useEffect, useState } from "react";
import { List, ListItem, LinearProgress, makeStyles, Divider, Box, ListItemSecondaryAction, ListItemText, IconButton, Paper, Tooltip } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { ApplicationContext } from "../contexts";
import { useHistory } from "react-router";
import { NavRoutes } from "../utils";
import AddBoxIcon from "@material-ui/icons/AddBox";

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 8
    },
    item: {
        padding: theme.spacing(1.2)
    },
    paginator: {
        justifyContent: "center",
        padding: "10px"
    },
    progress: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export const ApplicationList = () => {
    const {
        isLoadingApplications,
        loadRunningApplications,
        state
    } = useContext(ApplicationContext);

    useEffect(() => {
        loadRunningApplications();
        // return () => {
        //     cleanup
        // }
    }, [])

    const classes = useStyles();
    const itemsPerPage = 10;
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(state.applications.length / itemsPerPage)
    );

    const handleChange = (event, value) => {
        setPage(value);
    };

    let history = useHistory();

    return (
        <Paper className={classes.root}>
            { isLoadingApplications && <LinearProgress size={24} className={classes.progress} />}
            <Tooltip title="Add application">
                <IconButton
                    aria-label="add-application"
                >
                    <AddBoxIcon />
                </IconButton>
            </Tooltip>
            <List dense compoent="span">
                {state.applications
                    .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                    .map(item => {
                        const labelId = `list-application-${item.name}`;
                        return (
                            <ListItem
                                key={item.id}
                                button
                            >
                                <ListItemText
                                    id={labelId}
                                    primary={item.name}
                                    secondary={
                                        `Owner: ${item.owner === 0 ? 'Custom' : 'Active Directory'} / Roles: ${item.roleCount}`
                                    }
                                    className={classes.item}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton variant="link" edge="end" aria-label="open" onClick={() => history.push(NavRoutes.Application(item.id))}>
                                        <KeyboardArrowRightIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
            </List>
            <Divider />
            <Box component="span">
                <Pagination
                    count={noOfPages}
                    page={page}
                    onChange={handleChange}
                    defaultPage={1}
                    color="primary"
                    size="large"
                    showFirstButton
                    showLastButton
                    classes={{ ul: classes.paginator }}
                />
            </Box>
        </Paper>
    );
};
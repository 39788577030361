import React, { useContext, useMemo } from "react";
import { ControlledTable } from "../../../../../ui-components/table";
import { UserContext } from "../../../../../contexts";

export const FireFighterTable = ({ title }) => {

    const {
        state,
        isLoadingSapFireFighters,
        loadSapFireFighters
    } = useContext(UserContext);

    const data = useMemo(() => state.sapFireFighters.items ?? [], [state.sapFireFighters.items]);
    const columns = useMemo(
        () =>
            [
                {
                    Header: 'Firefight ID',
                    accessor: 'firefightID'
                },
                {
                    Header: 'Valid to date',
                    accessor: 'validToDate'
                }
            ],
        []
    );

    const fetchData = React.useCallback(({ sortBy, pageIndex, pageSize }) => {
        const sortProperty = sortBy[0];
        loadSapFireFighters(state.accountName, pageSize, pageIndex, sortProperty?.id, sortProperty?.desc);
    }, []);

    return (
        <ControlledTable
            title={title}
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={isLoadingSapFireFighters}
            pageCount={(Math.floor(state.sapFireFighters.totalItems / state.sapFireFighters.pageSize) + 1) ?? 0}
            total={state.sapFireFighters.totalItems ?? 0}
        />
    )
}
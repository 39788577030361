import React, { useMemo } from "react";
import {
    IconButton, Typography, Tooltip, List, ListItem, ListItemAvatar, ListItemText, Avatar, ListItemSecondaryAction, Switch
} from "@material-ui/core";
import Moment from 'moment';
import { Formats } from '../utils';
import GetAppIcon from '@material-ui/icons/GetApp';
import DescriptionIcon from '@material-ui/icons/Description';
import { useSnackbar } from "notistack";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ShareIcon from '@material-ui/icons/Share';
import DeleteWithConfirm from "./DeleteWithConfim";

export const FileManager = (props) => {
    const {
        onDownload,
        files,
        onVisibilityChange,
        entityId,
        onDelete,
        category,
        editable
    } = props;

    const dateFormat = Formats.DateFormat;

    const { enqueueSnackbar } = useSnackbar();

    const onCopy = () => enqueueSnackbar('File url copied!', { variant: "success" });

    const filteredFiles = useMemo(() => {
        if (category)
            return editable ?
                files?.filter(x => x.category === category) :
                files?.filter(x => x.category === category && x.isVisible === true);
        return editable ?
            files :
            files?.filter(x => x.isVisible === true)
    }
        , [category, files, editable]
    );

    if (!filteredFiles) return null;

    return (
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {filteredFiles.length > 0
                ?
                filteredFiles.map(file => (
                    <ListItem key={file.fileId}>
                        <ListItemAvatar>
                            <Avatar>
                                <DescriptionIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            style={{ overflowWrap: 'break-word' }}
                            primary={file.fileName}
                            secondary={`uploaded ${Moment(file.createDateUtc).format(dateFormat)}`}
                        />

                        <ListItemSecondaryAction>
                            {editable && onVisibilityChange && (
                                <Tooltip title={file.isVisible ? "Hide file from users" : "Show file to users"}>
                                    <Switch
                                        color="primary"
                                        checked={file.isVisible}
                                        onChange={(e) => onVisibilityChange(file.fileId, file.isVisible)}
                                    />
                                </Tooltip>
                            )}

                            <Tooltip title="Download">
                                <IconButton onClick={() => onDownload(file.fileId, file.fileName)}>
                                    <GetAppIcon />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Share">
                                <CopyToClipboard text={`${window.location.origin}/download/${file.fileId}/${file.fileName}`} onCopy={onCopy}>
                                    <IconButton>
                                        <ShareIcon />
                                    </IconButton>
                                </CopyToClipboard>
                            </Tooltip>

                            {editable && (
                                <Tooltip title="Delete">
                                    <span>
                                        <DeleteWithConfirm
                                            handleDelete={async () => {
                                                await onDelete(file.fileId, entityId);
                                            }} />
                                    </span>
                                </Tooltip>
                            )}
                        </ListItemSecondaryAction>
                    </ListItem>
                ))
                :
                <ListItem key="empty-item">
                    <ListItemText
                        secondary={(
                            <Typography style={{ fontWeight: 'bold' }} variant="body2">
                                <center>{"no files"}</center>
                            </Typography>
                        )}
                    />
                </ListItem>
            }
        </List>
    );
}
import { makeStyles } from '@material-ui/core/styles';
const drawerWidth = 0;

const useStyles = makeStyles((theme) => ({
    typography: {
        fontSize: 8,
        subtitle1: {
            fontSize: 10,
        },
        body1: {
            fontSize: 10,
        },
        subtitle2: {
            fontSize: 10,
        },
        body2: {
            fontSize: 10,
        },
        button: {
            fontSize: 10,
        },
        h1: {
            fontSize: 10,
        },
        h2: {
            fontSize: 10,
        },
        h3: {
            fontSize: 10,
        },
        h4: {
            fontSize: 10,
        },
        h5: {
            fontSize: 10,
        },
        h6: {
            fontSize: 10,
        },
    },
    displayInRow: {
        display: 'flex',
        alignItems: 'center'
    },
    displayInTab: {
        fontSize: 14.5,
        verticalAlign: 'middle',
        display: 'inline-flex'
    },
    searchFormItems: {
        height: 50
    },
    searchFormItemsError: {
        height: 25
    },
    fontTitleBig: {
        fontSize: 17
    },
    fontTitle: {
        fontSize: 11.5
    },
    fontDescription: {
        fontSize: 12
    },
    fontDescriptionBig: {
        fontSize: 14
    },
    fontDescriptionMedium: {
        fontSize: 13
    },
    fontTitleSmall: {
        fontSize: 9
    },
    fontTitleMedium: {
        fontSize: 14.5
    },
    mainContainer: {
        backgroundColor: theme.palette.background.default,
        minHeight: window.innerHeight,
    },
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        fontSize: 11.5
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        margin: 'auto',
        textAlign: 'center',
        height: '30px',
        maxHeight: '70%',
    },
    logoMobile: {
        margin: 'auto',
        marginTop: 100,
        textAlign: 'center',
        height: '25px',
        maxHeight: '70%',
    },
    logoHorizontallyCenter: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    mainSectionWrapper: {
        width: '100%',
        paddingBottom: theme.spacing(5),
    },
    mainHeader: {
        backgroundColor: theme.palette.common.white,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        fontStretch: 'condensed',
    },
    criteriaContainer: {
        marginTop: theme.spacing(5),
        flexGrow: 1,
    },
    pop: {
        backgroundColor: 'red !important',
    },
    whiteBg: {
        backgroundColor: theme.palette.common.white,
    },
    footer: {
        color: 'white',
        backgroundColor: 'rgba(0, 0, 0, 0.87)',
        top: 'auto',
        bottom: 0,
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth
        },
    },
    footerToolBar: {
        minHeight: '32px',
    },
    stickyHeaderList: {
        width: '100%',
        marginTop: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
    },
    padding_3: {
        padding: theme.spacing(3),
    },
    padding_2: {
        padding: theme.spacing(2),
    },
    padding_1: {
        padding: theme.spacing(1),
    },
    scrollablePaper: {
        maxHeight: 600,
        overflow: 'auto',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(0),
    },
    scrollablePaperBig: {
        maxHeight: 1200,
        overflow: 'auto',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(0),
    },
    search: {
        padding: theme.spacing(3),
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        maxWidth: 600
    },
    tableFooter: {
        position: 'relative',
        right: '30%'
    },
    tableFooterMembers: {
        position: 'relative',
        right: '25%'
    },


    navigationMenuContainer: {
        alignItems: 'center',
        backgroundColor: theme.palette.gray.main,
        boxShadow: theme.shadows[3],
        '& .navigation-item': {
            height: '64px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            alignItems: 'center',
            alignContent: 'center',
            padding: '0 35px',
            fontSize: '18px',
            '& .MuiSvgIcon-root': {
                marginRight: 10,
                marginTop: -3
            }
        }
    }
}));

export default useStyles;
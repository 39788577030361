import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';

import {
    Button,
    Paper,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination,
    TableContainer,
    Typography,
    makeStyles,
} from '@material-ui/core';
import MaUTable from '@material-ui/core/Table';
import GetAppIcon from '@material-ui/icons/GetApp';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

import { NavRoutes, downloadGroupMembers } from "../../../../utils";

import { TablePaginationActions, TableToolbar } from '../../../../ui-components/table';
import { StatusIndicator } from '../../../../ui-components';

const useLocalStyles = makeStyles((theme) => ({
    displayName: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
        alignItems: 'center',
        flexDirection: 'row'
    },
    searchLegendaTitle: {
        fontSize: 13,
        textAlign: 'center'
    },
    chip: {
        padding: 5,
        margin: 3
    },
    tableFooter: {
        position: 'relative',
        right: '30%'
    }
}));

export const MembersTable = (props) => {
    const data = useMemo(() => props.members, [props.members]);

    const localClasses = useLocalStyles();

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'firstName',
                Cell: ({ row }) => {
                    const { original: rowData } = row;

                    return (
                        <div className={localClasses.displayName}>
                            <Link
                                to={NavRoutes.UserDetails(rowData.id, rowData.accountName)}
                                style={{ color: 'inherit' }}
                            >
                                <PermIdentityIcon />
                            </Link>

                            <Typography
                                noWrap
                                component={Link}
                                to={NavRoutes.UserDetails(rowData.id, rowData.accountName)}
                                style={{
                                    color: 'inherit',
                                    textDecoration: 'inherit',
                                    fontSize: 14
                                }}
                            >
                                {`${rowData.firstName} ${rowData.lastName}`}
                            </Typography>
                        </div>
                    );
                }
            },
            { Header: 'Account', accessor: 'accountName' },
            {
                Header: 'Enabled',
                accessor: 'adAccountEnabled',
                Cell: ({ value }) => (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <StatusIndicator accountEnabled={value} tooltip={'Account disabled.'} />
                    </div>
                )
            },
            { Header: 'Email', accessor: 'email' },
            { Header: 'id', accessor: 'id', show: false }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        gotoPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: {
            pageIndex,
            pageSize,
            globalFilter
        },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                hiddenColumns: ["id"]
            }
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const handleChangePage = (event, newPage) => {
        gotoPage(newPage);
    }

    const handleChangeRowsPerPage = event => {
        setPageSize(Number(event.target.value));
    }

    const totalItems = useMemo(() => rows.length, [rows]);

    return (
        <Paper>
            <TableContainer>
                <TableToolbar
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    setGlobalFilter={(aa) => setGlobalFilter(aa)}
                    globalFilter={globalFilter}
                    placeholderText="members..."
                    title={props.title}
                >
                    <Button
                        color="primary"
                        onClick={() => { downloadGroupMembers(rows, columns, props.groupName) }}
                        variant="contained"
                        disabled={!rows.length}
                        startIcon={<GetAppIcon />}
                    >
                        Export
                    </Button>
                </TableToolbar>

                <MaUTable {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map(headerGroup => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <TableCell {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {rows.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize).map((row, i) => {
                            prepareRow(row);

                            return (
                                <TableRow hover {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <TableCell style={{ height: 12 }}{...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[
                                    10,
                                    25,
                                    50,
                                    { label: 'All', value: totalItems },
                                ]}
                                className={localClasses.tableFooter}
                                count={totalItems}
                                rowsPerPage={pageSize}
                                page={pageIndex}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </MaUTable>
            </TableContainer>
        </Paper>
    );
}
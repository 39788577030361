import clsx from 'clsx';
import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import {
    Button,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Tooltip,
    CircularProgress,
    colors
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';

import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
    root: {

    },
    dropZone: {
        border: "1px",
        padding: "8px",
        outline: "none",
        display: "flex",
        flexWrap: "wrap",
        backgroundColor: "#e1e1e1",
        opacity: 1,
        "&:hover": {
            backgroundColor: "#f1f1f1",
            opacity: 1,
            cursor: "pointer"
        }
    },
    dragActive: {
        backgroundColor: colors.grey[50],
        opacity: 0.5
    },
    image: {
        width: 130
    },
    info: {
        marginTop: 1
    },
    list: {
        maxHeight: 320
    },
    actions: {
        marginTop: 2,
        display: "flex",
        justifyContent: "flex-end",
        "& > * + *": {
            marginLeft: 2
        }
    },
    removeButton: {
        marginRight: 10
    }
}));

export const InputFiles = (props) => {
    const classes = useStyles();

    const [files, setFiles] = useState([]);

    const handleDrop = useCallback((acceptedFiles) => {
        setFiles((prevFiles) => [...prevFiles].concat(acceptedFiles));
    }, []);

    const removeFile = (index) => {
        const newFiles = [].concat(files)
        newFiles.splice(index, 1)
        setFiles(newFiles)
    }

    const removeAllFiles = () => {
        setFiles([])
    }

    const [isUploading, setIsUploading] = useState(false);
    const updateAllFiles = async (entityId, category) => {
        setIsUploading(true);

        await props.appendFiles(entityId, files, category);

        setFiles([]);

        setIsUploading(false);
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleDrop
    });

    return (
        <div>
            <div
                className={clsx({
                    [classes.dropZone]: true,
                    [classes.dragActive]: isDragActive
                })}
                {...getRootProps()}>
                <input {...getInputProps()} />
                <div>
                    <Typography
                        gutterBottom
                        variant="h6">
                        {props.title}
                    </Typography>
                    <Typography
                        className={classes.info}
                        color="textSecondary"
                        variant="body1">
                        Drop files here or
                        {' '}
                        <Link color="textSecondary" underline="always">browse</Link>
                        {' '}
                        your machine
                    </Typography>
                </div>
            </div>
            {files && files.length > 0 && (
                <>
                    <List className={classes.list}>
                        {files.map((file, i) => (
                            <ListItem
                                divider={i < files.length - 1}
                                key={i}>
                                <ListItemIcon>
                                    {!isUploading ?
                                        <PublishIcon />
                                        :
                                        <CircularProgress size="1.6em" color="primary" />
                                    }
                                </ListItemIcon>
                                <ListItemText
                                    primary={file.name}
                                    primaryTypographyProps={{ variant: 'body1' }} />
                                {!isUploading &&
                                    <Tooltip title="Remove">
                                        <IconButton
                                            color="primary"
                                            edge="end"
                                            onClick={() => removeFile(i)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </ListItem>
                        ))}
                    </List>
                    <div className={classes.actions}>
                        <Button
                            color="primary"
                            className={classes.removeButton}
                            size="small"
                            disabled={isUploading}
                            onClick={() => removeAllFiles()}>
                            Remove all
                        </Button>
                        <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            disabled={isUploading}
                            onClick={() => updateAllFiles(props.entityId, props.category)}>
                            Upload files
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
}

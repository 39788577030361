import React from 'react';

import { Typography, Tooltip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            marginRight: theme.spacing(1)
        }
    },
    badgeSuccess: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        height: 14,
        minWidth: 14,
        borderRadius: '50%'
    },
    badgeNotApplicable: {
        backgroundColor: '#8b8c8b',
        color: '#8b8c8b',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        height: 14,
        minWidth: 14,
        borderRadius: '50%'
    },
    badgeError: {
        backgroundColor: '#e91e63',
        color: '#e91e63',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        height: 14,
        minWidth: 14,
        borderRadius: '50%'
    },
    iconError: {
        color: '#e91e63',
    }
}));

export const StatusIndicator = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {
                renderIndicator(props, classes)
            }
            {props.label && (
                <Typography variant={props.variant || "h6"} component="div">{props.label}</Typography>
            )}
        </div>
    );
}

function renderIndicator(props, classes) {
    if (props.accountEnabled === null)
        return <Tooltip
            title="N/A"
            placement="right"
        >
            <div className={classes.badgeNotApplicable} />
        </Tooltip>
    else
        return props.accountEnabled
            ?
            <div className={classes.badgeSuccess} />
            :
            <>
                {props.negativeIcon &&
                    <Tooltip
                        title={props.tooltip || `Disabled.`}
                        placement="right"
                    >
                        <props.negativeIcon className={classes.iconError} />
                    </Tooltip>}
                <Tooltip
                    title={props.tooltip || `Disabled.`}
                    placement="right"
                >
                    <div className={classes.badgeError} />
                </Tooltip>
            </>;
}

import { Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useStyles from "../../../styles/useStyles";
import { Shimmer } from "../../../ui-components";
import CardRequestDetail from "./components/CardRequestDetail";
import CardRiskAnalysisDetail from "./components/CardRiskAnalysisDetail";
import CardUsersInvolvedDetail from "./components/CardUsersInvolvedDetail";
import { SoDAnalysisTransactionTable } from "./components/SoDAnalysisTransactionTable";
import useSodAnalysis from "./hooks/useSodAnalysis";
import CircularProgress from '@material-ui/core/CircularProgress';

export const SodAnalysisPage = () => {
    const { id } = useParams();
    const classes = useStyles();

    const {
        getSodAnalysis,
        cleanSodAnalysis,
        sodAnalysisLoading,
        sodAnalysis,
        company,
        managersEmail,
        updateAnalysisState,
        usersInvolved,
        updatePage,
        loadingUsersInvolved
    } = useSodAnalysis();

    useEffect(() => {
        async function fetchFromApi() {
            await getSodAnalysis(id);
        }
        fetchFromApi();
        return () => {
            cleanSodAnalysis();
        }
    }, [])

    return (sodAnalysisLoading ?
        <CircularProgress />
        : <Grid container spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="flex-start">
            <Grid container item xs={12}>
                <Grid item xs={12} className={classes.mainHeader}>
                    <Typography className={classes.fontTitleBig}>
                        <center>{`SOD ANALYSIS ${sodAnalysis?.id} DETAILS`}</center>
                    </Typography>
                </Grid>
            </Grid>
            <Grid item color='white' sm={18} md={8}>
                <CardRequestDetail
                    data={sodAnalysis}
                    updateData={updateAnalysisState}
                    managersEmail={managersEmail}
                    company={company}
                />
            </Grid>
            <CardRiskAnalysisDetail data={sodAnalysis} />
            <Grid item color='white' sm={18} md={8}>
                {renderTransactionTable()}
            </Grid>
            <Grid item color='white' sm={18} md={8}>
                <CardUsersInvolvedDetail loadingUsersInvolved={loadingUsersInvolved} updatePage = {updatePage } usersInvolved={usersInvolved} />
            </Grid>
        </Grid>
    );

    function renderTransactionTable() {
        if (sodAnalysisLoading || sodAnalysis?.user?.accountName == null)
            return <Shimmer variant="circle" width={300} height={300} />;
        return <SoDAnalysisTransactionTable accountName={sodAnalysis?.user?.accountName} />;
    }
};
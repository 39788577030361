import React, { useContext, useMemo } from "react";
import { ControlledTable } from "./../../../../ui-components/table"
import { UserContext } from "./../../../../contexts";
import { Chip, Tooltip, Grid, Card, Paper, Typography } from "@material-ui/core";
import { adaptSortAccessor } from "./../../../../utils";
import useStyles from "../../../../styles/useStyles";

export const SoDAnalysisTransactionTable = ({ accountName, title }) => {
    const {
        state,
        isLoadingSapTransactions,
        loadSapTransactions
    } = useContext(UserContext);
    const classes = useStyles();
    const data = useMemo(() => state.sapTransactions.items ?? [], [state.sapTransactions.items]);

    const columns = useMemo(
        () =>
            [
                {
                    Header: 'Sap Role Id',
                    accessor: 'sapRoleId',
                    show: false
                },
                {
                    Header: 'Technical Job Profile',
                    accessor: 'sapRoleName',
                    sortAccessor: 'SapRole.Name'
                },
                {
                    Header: 'TCode',
                    accessor: 'lowAuthorizationValue',
                    disableSortBy: true
                },
                {
                    Header: <Tooltip placement="top" title="if filled in, all values ​​from TCode to High must be considered">
                        <span>High</span>
                    </Tooltip>,
                    accessor: 'highAuthorizationValue',
                    disableSortBy: true
                },
                {
                    Header: 'Transaction Short Description',
                    accessor: 'tCodeMapDescription',
                    disableSortBy: true
                },
                {
                    Header: 'SoD Activities',
                    accessor: 'tCodeMapSodActivities',
                    disableSortBy: true,
                    Cell: SoDActivitiesCell()
                },
                {
                    Header: 'Usage Count',
                    accessor: 'usageCount',
                    disableSortBy: true
                }
            ],
        []
    );

    const fetchData = React.useCallback(({ sortBy, pageIndex, pageSize }) => {
        const sortProperty = sortBy[0];
        const sortPropertyName = adaptSortAccessor(columns, sortBy[0]?.id);
        const onlyImportant = true;
        const filter = [];
        loadSapTransactions(
            accountName,
            pageSize, pageIndex,
            sortPropertyName,
            sortProperty?.desc,
            onlyImportant,
            filter
        );
    }, []);

    return (
        state.sapTransactions.totalItems !== 0 ?

            <Card className={classes.padding_3} >
                <Grid  >
                    <Typography variant="h5">Transactions</Typography>
                    <Paper>
                        <ControlledTable
                            title={title}
                            columns={columns}
                            data={data}
                            // filters={Filters}
                            fetchData={fetchData}
                            loading={isLoadingSapTransactions}
                            pageCount={(Math.floor(state.sapTransactions?.totalItems / state.sapTransactions?.pageSize) + 1) || 0}
                            total={state.sapTransactions.totalItems ?? 0}
                            hideSortIcon={true}
                        //  preRowActions={preRowActions}
                        />
                    </Paper>
                </Grid>
            </Card>
            :
            null
    )
}

function SoDActivitiesCell() {
    return ({ value }) => value ? value.map((x, i) => {
        if (x.description)
            return <Tooltip title={x.description}>
                <Chip
                    key={x.code}
                    variant="outlined"
                    size="small"
                    label={x.code + ' - ' + x.description} />
            </Tooltip>;
        return <Chip
            key={x.code}
            variant="outlined"
            size="small"
            label={x.code} />;
    })
        :
        <></>;
}
import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import _ from "lodash";

import { Autocomplete } from '@material-ui/lab';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AccordionActions,
    Button,
    Divider,
    Grid,
    TextField,
    Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { SearchContext } from '../../../../../contexts';
import { SearchGroupSource } from "../../../../../utils";
import { SearchGroupGroupTypes } from "../../../../../utils";
import useStyles from "../../../../../styles/useStyles";

import { FieldError } from "../../../../../ui-components/FieldError";

const GroupCriteriaForm = (props) => {
    const {
        handleSubmit,
        getValues,
        formState: { errors },
        control
    } = useForm();

    const {
        state,
        toggleOpenSearch
    } = useContext(SearchContext);

    const classes = useStyles();

    const handleValidation = (value) => {
        const name = getValues('name');
        const description = getValues('description');
        const mail = getValues('mail');
        const source = getValues('source');
        const groupTypes = getValues('groupTypes');

        if (!name && !description && !mail && !source && !groupTypes) {
            return 'At least one field is required';
        };

        return true;
    }

    const _handleSubmit = (formValues) => {
        props.onSubmit({
            displayName: formValues.name,
            description: formValues.description,
            mail: formValues.mail,
            source: formValues.source ? formValues.source.value === 'Cloud' : undefined,
            groupTypes: formValues.groupTypes?.value
        });
    }

    return (
        <form onSubmit={handleSubmit(_handleSubmit)}>
            <Accordion
                defaultExpanded
                expanded={state.openSearch}
                onChange={() => toggleOpenSearch()}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">
                        <center>Active Directory Groups</center>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={12} className={classes.searchFormItems}>
                            <Controller
                                control={control}
                                name="name"
                                rules={{
                                    maxLength: 250,
                                    validate: {
                                        required: handleValidation
                                    }
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        size="small"
                                        label="Name"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        autoComplete="off"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                )}
                            />
                        </Grid>
                        {errors?.name && (
                            <Grid item xs={12} className={classes.searchFormItemsError}>
                                <FieldError error={errors?.name} />
                            </Grid>
                        )}

                        <Grid item xs={12} className={classes.searchFormItems}>
                            <Controller
                                control={control}
                                name="description"
                                rules={{
                                    maxLength: 250,
                                    validate: {
                                        required: handleValidation
                                    }
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        size="small"
                                        label="Description"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        autoComplete="off"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                )}
                            />
                        </Grid>
                        {errors?.description && (
                            <Grid item xs={12} className={classes.searchFormItemsError}>
                                <FieldError error={errors?.description} />
                            </Grid>
                        )}

                        <Grid item xs={12} className={classes.searchFormItems}>
                            <Controller
                                control={control}
                                name="mail"
                                rules={{
                                    maxLength: 250,
                                    validate: {
                                        required: handleValidation
                                    }
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        size="small"
                                        label="Email"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        autoComplete="off"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                )}
                            />
                        </Grid>
                        {errors?.mail && (
                            <Grid item xs={12} className={classes.searchFormItemsError}>
                                <FieldError error={errors?.mail} />
                            </Grid>
                        )}

                        <Grid item xs={12} className={classes.searchFormItems}>
                            <Controller
                                control={control}
                                name="source"
                                rules={{
                                    validate: {
                                        required: handleValidation
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        onChange={(ev, item) => onChange(item)}
                                        value={value}
                                        size="small"
                                        options={SearchGroupSource}
                                        getOptionLabel={(option) => option.value || ''}
                                        getOptionSelected={(option, value) =>
                                            value === undefined || value === "" || option.key === value.key
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Source"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        {errors?.source && (
                            <Grid item xs={12} className={classes.searchFormItemsError}>
                                <FieldError error={errors?.source} />
                            </Grid>
                        )}

                        <Grid item xs={12} className={classes.searchFormItems}>
                            <Controller
                                control={control}
                                name="groupTypes"
                                rules={{
                                    validate: {
                                        required: handleValidation
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        onChange={(ev, item) => onChange(item)}
                                        value={value}
                                        size="small"
                                        options={SearchGroupGroupTypes}
                                        getOptionLabel={(option) => option.value || ''}
                                        getOptionSelected={(option, value) =>
                                            value === undefined || value === "" || option.key === value.key
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="GroupTypes"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        {errors?.source && (
                            <Grid item xs={12} className={classes.searchFormItemsError}>
                                <FieldError error={errors?.groupTypes} />
                            </Grid>
                        )}
                    </Grid>
                </AccordionDetails>
                <Divider />
                <AccordionActions>
                    <Grid container justifyContent="center">
                        <Button
                            color='primary'
                            size='medium'
                            type='submit'
                            variant='contained'
                            disabled={props.isLoading}
                        >
                            SEARCH
                        </Button>
                    </Grid>
                </AccordionActions>
            </Accordion>
        </form>
    );
}

export default GroupCriteriaForm;
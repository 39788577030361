import { useMsal } from "@azure/msal-react";
import Button from "@material-ui/core/Button";
import React from "react";
import { loginRequest } from "../../authConfig";
import { InsightContext } from "./../../contexts";

export const SignInButton = () => {
    const { instance } = useMsal();
    const authRequest = {
        ...loginRequest
    };

    const { trackError } = React.useContext(InsightContext);

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(authRequest).catch((error) => {
                trackError('MSAL manual-login loginRedirect error!');
                trackError(error);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(authRequest).catch((error) => {
                trackError('MSAL manual-login loginRedirect error!');
                trackError(error);
            });
        }
    }

    return (
        <Button
            onClick={() => handleLogin("redirect")} key="loginRedirect"
            color="inherit"
        >
            Sign in
        </Button>
    )
};
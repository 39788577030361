import React, { useContext, useEffect } from "react";
import { Grid, TextField, MenuItem, Button } from "@material-ui/core";
import { ApplicationContext } from "../../contexts";
import useStyles from "../../styles/useStyles";
import { KeyValueList, ProgressBar, Shimmer } from "../../ui-components";
import { Controller, useForm } from 'react-hook-form';
import { overrideJust } from "../../utils";

export const AuthenticationForm = ({ editable, applicationId }) => {
    const {
        state,
        updateApplication,
        isLoadingApplication,
        isUpdatingApplication
    } = useContext(ApplicationContext);

    const defaultValues = state.application;

    const {
        handleSubmit,
        control,
        reset,
        watch
    } = useForm({ defaultValues });

    useEffect(() => {
        reset(defaultValues)
    }, [reset, defaultValues, isLoadingApplication])

    const onSubmit = async (formData) => {
        const overridingFields = ["authentication"];
        const request = overrideJust(overridingFields, formData, defaultValues);
        await updateApplication(request);
    }

    const classes = useStyles();

    if (editable) return defaultValues ? renderContent() : <Shimmer variant="text" size={10} />
    return defaultValues && renderContent();

    function renderContent() {
        return <>
            {editable ?
                // admin part
                <form onSubmit={handleSubmit((onSubmit))}>
                    <Grid className={classes.displayInRow} container item sm={12} md={12} spacing={2}>
                        {watch("authentication") !== undefined &&
                            <Grid item sm={12}>
                                <Controller
                                    name="authentication"
                                    control={control}
                                    rules={{ maxLength: 250 }}
                                    render={({ field }) => <TextField
                                        {...field}
                                        select
                                        variant="outlined"
                                        margin="none"
                                        size="small"
                                        fullWidth
                                        label="Authentication"
                                    >
                                        <MenuItem value='Application Credentials'>Application Credentials</MenuItem>
                                        <MenuItem value='Okta'>Okta</MenuItem>
                                        <MenuItem value='MFA'>MFA</MenuItem>
                                        <MenuItem value='SSO'>SSO</MenuItem>
                                    </TextField>}
                                />
                            </Grid>}
                        <Grid item sm={12} md={12}>
                            <center>
                                <Button
                                    color='primary'
                                    variant='contained'
                                    type='submit'
                                    disabled={isUpdatingApplication}
                                >
                                    Save
                                </Button>
                            </center>
                        </Grid>
                        <ProgressBar loading={isUpdatingApplication || isLoadingApplication} />
                    </Grid>
                </form>
                :
                <Grid container sm={12}>
                    <KeyValueList
                        data={defaultValues}
                        labels={[{ property: "authentication", label: "Authentication" }]}
                    />
                </Grid>
            }
        </>;
    }
};
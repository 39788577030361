import { useState, useEffect, useContext, useMemo } from 'react';
import { UserContext } from '../../../../../contexts';

export function usePowerbi() {
    const [hookState, setHookState] = useState();

    const {
        state
    } = useContext(UserContext);

    const powerbiData = useMemo(() => {
        if (state.memberships.length > 0 && state.userReports) {
            const onlyPBI = state.memberships.filter(x => x.isPowerBI);

            onlyPBI.map(group => {
                group.pbiReports = state.userReports[group.id];

                //changing property name due to a bug (maybe "roleProviderDescription" is too long?)
                group.roleDescription = group.roleProviderDescription ?? "";
            });

            return {
                reports: {
                    data: onlyPBI,
                    fields: [
                        { label: "AD Group Name", property: "name" },
                        { label: "Description", property: "description" },
                        { label: "Role Provider Description", property: "roleDescription" }
                    ]
                },
            }
        }
    }, [state.memberships]);

    useEffect(() => {
        setHookState(powerbiData)
    }, []);

    return hookState;
}
import { CustomApplications, downloadUserApplicationBeanstore, downloadUserApplicationBtrace, downloadUserApplicationPowerbi, downloadUserApplicationSalesforce, downloadUserApplicationSap } from "../../../../utils";
import { SapTabContent, useSap } from "./sap";
import { BeanstoreTabContent, useBeanstore } from "./beanstore";
import { BtraceTabContent, useBtrace } from "./btrace";
import { PowerBITabContent, usePowerbi } from "./powerbi";
import { SalesforceTabContent, useSalesforce } from "./salesforce";

export const ApplicationTabsLookup = [
    {
        app: CustomApplications.Sap,
        tabId: 1,
        component: SapTabContent,
        refreshRate: "two times per day",
        downloadCallback: (data, accountName) => downloadUserApplicationSap(data, accountName),
        dataHook: useSap
    },
    {
        app: CustomApplications.Beanstore,
        tabId: 2,
        component: BeanstoreTabContent,
        refreshRate: "every six hours", // At 0 minutes past the hour, every 6 hours
        downloadCallback: (data, accountName) => downloadUserApplicationBeanstore(data, accountName),
        dataHook: useBeanstore
    },
    {
        app: CustomApplications.Btrace,
        tabId: 3,
        component: BtraceTabContent,
        refreshRate: "everyday", // Everyday at 12:00 AM
        downloadCallback: (data, accountName) => downloadUserApplicationBtrace(data, accountName),
        dataHook: useBtrace
    },
    {
        app: CustomApplications.PowerBI,
        tabId: 4,
        component: PowerBITabContent,
        refreshRate: "Synched with Active Directory",
        downloadCallback: (data, accountName) => downloadUserApplicationPowerbi(data, accountName),
        dataHook: usePowerbi
    },
    {
        app: CustomApplications.Salesforce,
        tabId: 5,
        component: SalesforceTabContent,
        refreshRate: "everyday", // Everyday at 03:00 AM
        downloadCallback: (data, accountName) => downloadUserApplicationSalesforce(data, accountName),
        dataHook: useSalesforce
    }
]
import {
    Chip, Paper, Table, TableBody, TableCell, Switch, Typography, TableContainer, TableHead, TableRow, Tooltip, Card, Grid
} from '@material-ui/core';
import useStyles from "../../../../styles/useStyles";
import React, { useMemo } from "react";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function createSoDRiskData(code, soDActivity1, soDActivity2) {
    return { code, soDActivity1, soDActivity2 };
}

const getSoDRiskData = (items) => {
    if (items !== undefined) {
        return items.newRisks?.map(y =>
            createSoDRiskData(
                y.code,
                y.firstSodActivity,
                y.secondSodActivity));
    }
};

const CardRiskAnalysisDetail = ({ data }) => {
    const classes = useStyles();
    const soDRiskData = useMemo(() => getSoDRiskData(data), [data]);
    const renderSwitch = <Grid item sm={18}>
        <FormGroup >
            <FormControlLabel
                control={
                    <Switch
                        checked={data?.transactionSodRelevant}
                        name="checkedA"
                        color="primary"
                        disabled
                    />
                }
                label="Are the requested Transactions SoD-Relevant?" />
        </FormGroup >
        <FormGroup >
            <FormControlLabel
                control={
                    <Switch
                        checked={data?.newActivityFromTransaction}
                        name="checkedB"
                        color="primary"
                        disabled
                    />
                }
                label="Do these Transactions add new SoD Activities to the user?" />
        </FormGroup >
        <FormGroup >
            <FormControlLabel
                control={
                    <Switch
                        checked={data?.newRiskFromActivities}
                        name="checkedC"
                        color="primary"
                        disabled
                    />
                }
                label="Do these SoD Activities add SoD Risks to the users?" />
        </FormGroup >
    </Grid>

    const renderSoDRisk =
        <Grid >
            <Typography variant="h5">New SoD Risks</Typography>
            <Paper sm={18}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>New SoD Risks</TableCell>
                                <TableCell>SoD Activity1</TableCell>
                                <TableCell>SoD Activity2</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {soDRiskData?.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">{row.code}</TableCell>
                                    <TableCell component="th" scope="row">{
                                        < Tooltip title={row.soDActivity1} >
                                            <Chip
                                                style={{
                                                    margin: 2,
                                                    maxWidth: 170,
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis"
                                                }}
                                                label={row.soDActivity1} variant="outlined" />
                                        </Tooltip >
                                    }
                                    </TableCell>
                                    <TableCell component="th" scope="row">{
                                        < Tooltip title={row.soDActivity2} >
                                            <Chip
                                                style={{
                                                    margin: 2,
                                                    maxWidth: 170,
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis"
                                                }}
                                                label={row.soDActivity2} variant="outlined" />
                                        </Tooltip >
                                    }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Grid >


    return (
        <>
            <Grid item color='white' sm={18} md={8}>
                <Card className={classes.padding_3} >
                    <Grid container >
                        {renderSwitch}
                    </Grid>
                </Card>
            </Grid>

            {soDRiskData !== undefined && soDRiskData?.length !== 0 ?

                <Grid item color='white' sm={18} md={8}>
                    <Card className={classes.padding_3} >
                        {renderSoDRisk}
                    </Card>
                </Grid>
                :
                undefined
            }
        </>
    );
}
export default CardRiskAnalysisDetail;
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Divider, Typography } from "@material-ui/core";
import useStyles from "../styles/useStyles";

const styles = theme => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4
    },
    padding_3: {
        padding: theme.spacing(3)
    },
    scrollablePaper: {
        maxHeight: 600,
        overflow: 'auto',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(0)
    },
});

class NestedList extends React.Component {
    state = { open: {} };
    handleClick = key => () => {
        this.setState({ [key]: !this.state[key] });
    };

    render() {
        const {
            data,
            classes,
            renderItem,
            renderChild,
            getItems,
            getKey,
            emptyText,
        } = this.props;
        const notEmpty = data && data.length > 0;

        const childItems = (item) => {
            const data = item ? getItems(item) : [];
            return data;
        };

        return (
            <div className={classes.root}>
                <div className={classes.scrollablePaper}>
                    <List
                        component="nav"
                        className={classes.padding_3}
                    >
                        {notEmpty ? data.map(item => {
                            const key = getKey ? getKey(item) : item.key;
                            const open = this.state[key] || false;
                            return (
                                <div key={key}>
                                    <ListItem key={key} button onClick={this.handleClick(key)}>
                                        {item.icon &&
                                            <ListItemIcon>
                                                <item.icon />
                                            </ListItemIcon>}
                                        <ListItemText primary={renderItem ? renderItem(item) : item.label} />
                                        {getItems && open ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Divider />
                                    {getItems &&
                                        <Collapse in={open} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {childItems(item).map((child) => (
                                                    <ListItem key={child.key} button className={classes.nested}>
                                                        {child.icon &&
                                                            <ListItemIcon>
                                                                <child.icon />
                                                            </ListItemIcon>}
                                                        <ListItemText primary={renderChild ? renderChild(child) : child.label} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Collapse>}
                                </div>
                            );
                        }) : <ListItem>
                                <ListItemText key="empty-item" secondary={<><Typography style={{ fontWeight: 'bold', padding: '30px' }} variant="body2"><center>{emptyText ?? "no data"}</center></Typography></>} />
                        </ListItem>}
                    </List>
                </div>
            </div>
        );
    }
}

NestedList.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NestedList);

import {
    Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip
} from '@material-ui/core';
import React, { useMemo } from "react";

function renderSoDChips(sodActivity) {
    const soDChips = sodActivity?.map((x => {
        let ret = x.code;
        if (x.description !== undefined)
            ret = ret + ' - ' + x.description;
        return ret;
    }));
    return soDChips;
}

function createSoDImpactRows(transaction, description, sodActivity) {
    const soDChips = renderSoDChips(sodActivity);
    return { transaction, description, soDChips };
}

function createSoDRiskData(code, soDActivity1, soDActivity2) {
    return { code, soDActivity1, soDActivity2 };
}

const getSoDImpactData = (items) => {
    if (items !== undefined) {
        return items.selectedTransactions?.map(x =>
            createSoDImpactRows(
                x.transactionName,
                x.transactionShortDescription,
                x.sodActivity));
    }
};

const getSoDRiskData = (items) => {
    if (items !== undefined) {
        return items.newRisks?.map(y =>
            createSoDRiskData(
                y.code,
                y.firstSodActivity,
                y.secondSodActivity));
    }
};

const SodAnalysisResultTable = ({ items }) => {
    const soDImpactData = useMemo(() => getSoDImpactData(items), [items]);
    const soDRiskData = useMemo(() => getSoDRiskData(items), [items]);

    return (<>
        <>
            SoD Impact
            <Paper style={{ marginBottom: "30px" }}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Transaction Requested</TableCell>
                                <TableCell>Transaction Description</TableCell>
                                <TableCell>SoD Activity</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {soDImpactData?.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">{row.transaction}</TableCell>
                                    <TableCell component="th" scope="row">{row.description}</TableCell>
                                    <TableCell component="th" scope="row">{
                                        React.Children.toArray(
                                            row.soDChips?.map(x =>
                                                <Tooltip
                                                    key=""
                                                    title={x}>
                                                    <Chip
                                                        style={{
                                                            margin: 2,
                                                            maxWidth: 170,
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis"
                                                        }}
                                                        label={x} variant="outlined" />
                                                </Tooltip >
                                            ))}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
        {
            soDRiskData !== undefined && soDRiskData?.length !== 0 ?
                <>
                    New SoD Risks
                    <Paper>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>New SoD Risks</TableCell>
                                        <TableCell>SoD Activity1</TableCell>
                                        <TableCell>SoD Activity2</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {soDRiskData?.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell component="th" scope="row">{row.code}</TableCell>
                                            <TableCell component="th" scope="row">{
                                                < Tooltip title={row.soDActivity1} >
                                                    <Chip
                                                        style={{
                                                            margin: 2,
                                                            maxWidth: 170,
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis"
                                                        }}
                                                        label={row.soDActivity1} variant="outlined" />
                                                </Tooltip >
                                            }
                                            </TableCell>
                                            <TableCell component="th" scope="row">{
                                                < Tooltip title={row.soDActivity2} >
                                                    <Chip
                                                        style={{
                                                            margin: 2,
                                                            maxWidth: 170,
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis"
                                                        }}
                                                        label={row.soDActivity2} variant="outlined" />
                                                </Tooltip >
                                            }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </> :
                undefined
        }
    </>
    );
}
export default SodAnalysisResultTable;
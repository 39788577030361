import React, { useContext, useMemo, useState } from "react";
import { ControlledTable } from "../../../../../ui-components/table";
import { FormControlLabel, Grid, Switch, Tooltip, Typography } from "@material-ui/core";
import { UserContext } from "../../../../../contexts";
import { adaptSortAccessor } from "../../../../../utils";
import { FilterList } from "../../../../../ui-components/FilterList";

export const AuthorizationObjectTable = ({ title }) => {

    const {
        state,
        isLoadingSapAuthorizationObjects,
        loadSapAuthorizationObjects,
        isLoadingSapFilters
    } = useContext(UserContext);

    const mapFromApiDto = f => ({
        propertyName: f.name,
        propertyDisplayName: f.displayName,
        value: f.value
    });
    const mapToApiDto = f => ({
        name: f.propertyName,
        displayName: f.propertyDisplayName,
        value: f.value
    });


    const data = useMemo(() => state.sapAuthorizationObjects.items ?? [], [state.sapAuthorizationObjects.items]);
    const availableFilters = useMemo(() => state.sapFilters.authorizations.map(mapFromApiDto) ?? [], [state.sapFilters.authorizations]);

    const columns = useMemo(
        () =>
            [
                {
                    Header: 'SapRoleId',
                    accessor: 'sapRoleId',
                    show: false
                },
                {
                    Header: 'Role Name',
                    accessor: 'sapRoleName',
                    sortAccessor: 'SapRole.Name'
                },
                {
                    Header: 'Authoritazion Object',
                    accessor: 'authoritazionObject'
                },
                {
                    Header: 'Authorization Field Name',
                    accessor: 'authorizationFieldName'
                },
                {
                    Header: 'Value',
                    accessor: 'lowAuthorizationValue',
                    disableSortBy: true
                },
                {
                    Header: <Tooltip placement="top" title="if filled in, all values ​​from Value to High must be considered">
                        <span>High</span>
                    </Tooltip>,
                    accessor: 'highAuthorizationValue',
                    disableSortBy: true
                }
            ],
        []
    );

    const [showAll, setShowAll] = useState(false);
    const [configuredFilters, setConfiguredFilters] = useState([]);

    const Filters = () => <Grid container>
        <Grid item xs={12} md={6}>
            <FormControlLabel
                control={<Switch color="primary" />}
                checked={showAll}
                onChange={(e) => setShowAll(e.target.checked)}
                label={<Typography style={{ fontSize: 14.5 }}>Show all Authorizations</Typography>} />
        </Grid>
        <Grid item xs={12} md={6}>
            <FilterList
                onSubmit={data => setConfiguredFilters(data.filters.map(mapToApiDto))}
                properties={availableFilters}
                loading={isLoadingSapFilters || isLoadingSapAuthorizationObjects} />
        </Grid>
    </Grid>

    const fetchData = React.useCallback(({ sortBy, pageIndex, pageSize }) => {
        const sortProperty = sortBy[0];
        const sortPropertyName = adaptSortAccessor(columns, sortBy[0]?.id);
        const onlyImportant = !showAll;
        loadSapAuthorizationObjects(
            state.accountName,
            pageSize, pageIndex,
            sortPropertyName,
            sortProperty?.desc,
            onlyImportant,
            configuredFilters
        );
    }, [showAll, configuredFilters]);

    return (
        <ControlledTable
            title={title}
            columns={columns}
            data={data}
            filters={Filters}
            fetchData={fetchData}
            loading={isLoadingSapAuthorizationObjects}
            pageCount={(Math.floor(state.sapAuthorizationObjects.totalItems / state.sapAuthorizationObjects.pageSize) + 1) ?? 0}
            total={state.sapAuthorizationObjects.totalItems ?? 0}
        />
    )
}
import React, { useEffect, useContext, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import { SearchContext, UserContext } from "../../../../../contexts";
import useStyles from "../../../../../styles/useStyles";
import _ from "lodash";
import { FieldError } from '../../../../../ui-components/FieldError';
import { isNullOrWhiteSpace } from '../../../../../utils';

const ADCriteriaForm = (props) => {
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors }
    } = useForm();
    const { state, setMemberOfState, isLoadingGroups, loadGroupOptions, toggleOpenSearch, isSubgroupClicked } = useContext(SearchContext);

    const {
        setTab,
        setApplicationTab
    } = useContext(UserContext);

    useEffect(() => {
        // Autocomplete values must be initialized (only for multiple values), because the default value is an empty string (not an array)
        setValue('memberOf', []);

        setTab(0);
        setApplicationTab(0);

        loadGroupOptions();
    }, []);

    useEffect(() => {
        if (state.adSearchMemberOf)
            setValue('memberOf', state.adSearchMemberOf);
    }, [state.adSearchMemberOf]);

    const handleMemberOfChange = (e, searchText) => {
        e && e.type !== "click" && loadGroupOptions(searchText);
    };

    const handleAccordionClick = () => {
        toggleOpenSearch();
    };

    const handleMemberOfSelect = (e, options) => {
        setValue('memberOf', options.map(x => x.id));
        setMemberOfState(options);
    };

    const classes = useStyles();

    const validateAnyRequired = useCallback(() => {
        const errorMessage = 'At least one field is required to perform the search.';
        const allValues = getValues();
        const anyFilledIn = allValues.firstName
            || allValues.lastName
            || allValues.email
            || allValues.account
            || allValues.company
            || allValues.cid
            || allValues.userId
            || allValues.memberOf?.length > 0;
        return !!anyFilledIn || errorMessage;
    }, []);

    const validateCid = useCallback(() => {
        const errorMessage = 'You can search either by CID or by User ID, not both simultaneously.';
        const allValues = getValues();
        const bothFilledIn =
            !isNullOrWhiteSpace(allValues.cid)
            && !isNullOrWhiteSpace(allValues.userId);
        return bothFilledIn ? errorMessage : validateAnyRequired();
    }, []);

    return (
        <form onSubmit={handleSubmit(props.onSubmit)}>
            <Accordion
                defaultExpanded
                expanded={state.openSearch}
                onChange={handleAccordionClick}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">
                        <center>Users</center>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container >
                        <Grid item xs={12} className={classes.searchFormItems}>
                            <TextField
                                className={classes.fontTitleSmall}
                                size="small"
                                variant="outlined"
                                margin="normal"
                                {...register('account', { validate: validateAnyRequired })}
                                fullWidth
                                id="account"
                                label="Account"
                                name="account"
                                autoComplete="off"
                                InputProps={{
                                    style: { fontSize: 14 }
                                }}
                                InputLabelProps={{
                                    style: { fontSize: 14 }
                                }}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.searchFormItems}>
                            <TextField
                                variant="outlined"
                                size="small"
                                margin="normal"
                                {...register('firstName', { validate: validateAnyRequired })}
                                fullWidth
                                id="firstName"
                                label="First Name"
                                name="firstName"
                                autoComplete="off"
                                InputProps={{
                                    style: { fontSize: 14 }
                                }}
                                InputLabelProps={{
                                    style: { fontSize: 14 }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.searchFormItems}>
                            <TextField
                                className={classes.fontTitleSmall}
                                size="small"
                                variant="outlined"
                                margin="normal"
                                {...register('lastName', { validate: validateAnyRequired })}
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="off"
                                InputProps={{
                                    style: { fontSize: 14 }
                                }}
                                InputLabelProps={{
                                    style: { fontSize: 14 }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.searchFormItems}>
                            <TextField
                                className={classes.fontTitleSmall}
                                size="small"
                                variant="outlined"
                                margin="normal"
                                {...register('email', { validate: validateAnyRequired })}
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                InputProps={{
                                    style: { fontSize: 14 }
                                }}
                                InputLabelProps={{
                                    style: { fontSize: 14 }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.searchFormItems}>
                            <TextField
                                className={classes.fontTitleSmall}
                                size="small"
                                variant="outlined"
                                margin="normal"
                                {...register('cid', { validate: validateCid })}
                                onChange={event => {
                                    const { value } = event.target;
                                    return setValue("cid", isNullOrWhiteSpace(value) ? value : value.padStart(10, "0"));
                                }}
                                fullWidth
                                id="cid"
                                label="CID (ID of Employee)"
                                name="cid"
                                autoComplete="off"
                                InputProps={{
                                    style: { fontSize: 14 }
                                }}
                                InputLabelProps={{
                                    style: { fontSize: 14 }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.searchFormItems}>
                            <TextField
                                className={classes.fontTitleSmall}
                                size="small"
                                variant="outlined"
                                margin="normal"
                                {...register('userId', { validate: validateCid })}
                                fullWidth
                                id="userId"
                                label="User ID (i.e. 'RITM' for consultants)"
                                name="userId"
                                autoComplete="off"
                                InputProps={{
                                    style: { fontSize: 14 }
                                }}
                                InputLabelProps={{
                                    style: { fontSize: 14 }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.searchFormItems}>
                            <TextField
                                className={classes.fontTitleSmall}
                                size="small"
                                variant="outlined"
                                margin="normal"
                                {...register('company', { validate: validateAnyRequired })}
                                fullWidth
                                id="company"
                                label="Company"
                                name="company"
                                autoComplete="off"
                                InputProps={{
                                    style: { fontSize: 14 }
                                }}
                                InputLabelProps={{
                                    style: { fontSize: 14 }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.searchFormItems}>
                            <Autocomplete
                                {...register('memberOf', { validate: validateAnyRequired })}
                                multiple
                                size="small"
                                value={state.adSearchMemberOf}
                                loading={isLoadingGroups}
                                options={state.groups}
                                getOptionLabel={option => option.displayName}
                                onChange={(e, options) => handleMemberOfSelect(e, options)}
                                onInputChange={_.debounce(handleMemberOfChange, 600)}
                                limitTags={5}
                                key={isSubgroupClicked}
                                renderInput={(params) => (
                                    <TextField className={classes.fontTitleSmall}
                                        {...params}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="memberOf"
                                        label="Member Of"
                                        name="memberOf"
                                        InputLabelProps={{
                                            style: { fontSize: 14 }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: 14 }}>
                            <Typography variant="body2">
                                Accounts not in AD can be searched only by "Account" field
                            </Typography>
                        </Grid>
                        {Object.keys(errors).length > 0 &&
                            <Grid item xs={12} className={classes.searchFormItemsError}>
                                <FieldError
                                    error={errors[function () { for (var k in errors) return k }()]}
                                    customMessage={errors[function () { for (var k in errors) return k }()].message} />
                            </Grid>
                        }
                    </Grid>
                </AccordionDetails>
                <Divider />
                <AccordionActions>
                    <Grid container justifyContent="center">
                        <Button
                            color='primary'
                            size='medium'
                            type='submit'
                            variant='contained'
                            disabled={props.isLoading}
                        >
                            SUBMIT
                        </Button>
                    </Grid>
                </AccordionActions>
            </Accordion>
        </form>
    );
}

export default ADCriteriaForm;
import React, { useState, useContext } from "react";
import { ApiClient, ApiRoutes, useAggregatedState, Formats } from "../utils";
import { InsightContext } from "./";
import Moment from 'moment';

export const UserContext = React.createContext(null);

const { Provider } = UserContext;
const dateFormat = Formats.DateFormat;

export const UserProvider = (props) => {
    const { trackError } = useContext(InsightContext);
    const [state, setState] = useAggregatedState({
        applicationUser: null,
        user: {},
        aadGroups: [],
        aadGroupsFiltered: [],
        memberships: [],
        sodActivities: [],
        sapUser: {},
        sapAuthorizationObjects: {},
        sapTransactions: {},
        sapRoles: {},
        sapFireFighters: {},
        sapOrganizations: {},
        sapFilters: [],
        beanstoreCashiers: [],
        beanstoreSellers: [],
        btraceUser: {},
        salesforceUser: {},
        userReports: null,
        openTransactionTable: true,
        openAuthObjectTable: false,
        openOrgLvlTable: false,
        openFirefightersTableClick: true
    });

    // ___________ userapp application user (admin, operator) ____________

    const [isLoadingApplicationUser, setIsLoadingApplicationUser] = useState();
    const [tab, setTab] = useState();
    if (tab === undefined)
        setTab(0); // default value

    const [applicationTab, setApplicationTab] = useState();

    const loadApplicationUser = async () => {
        try {
            setIsLoadingApplicationUser(true);

            const r = await ApiClient.get(ApiRoutes.User.GetMe());

            setState({
                applicationUser: r.data
            });

            setIsLoadingApplicationUser(false);

        } catch (e) {
            trackError(e);
            setIsLoadingApplicationUser(false);
        }
    }

    const cleanApplicationUser = () => {
        setState({
            applicationUser: null
        });
    }

    // ___________ other users _______________

    const toggleTransactionTableClick = () => {
        setState({
            openTransactionTable: !state.openTransactionTable
        });
    }

    const toggleAuthObjectTableClick = () => {
        setState({
            openAuthObjectTable: !state.openAuthObjectTable
        });
    }

    const toggleOrgLvlTableClick = () => {
        setState({
            openOrgLvlTable: !state.openOrgLvlTable
        });
    }

    const toggleFirefightersTableClick = () => {
        setState({
            openFirefightersTableClick: !state.openFirefightersTableClick
        });
    }

    const initUser = (userId, accountName) => {
        setState({
            userId,
            accountName
        })
    }

    const [isLoadingUser, setIsLoadingUser] = useState(false);
    const loadUser = async (userId, account, customApplication) => {
        try {
            setIsLoadingUser(true);

            const details = await ApiClient.get(ApiRoutes.User.GetDetails({ userId, account, customApplication }));
            const profilePicture = await ApiClient.get(ApiRoutes.User.GetProfilePicture({ userId }));
            const { aadGroups, ...userInfo } = details.data;

            if (userInfo.createdDateTime && userInfo.createdDateTime.length != dateFormat.length)
                userInfo.createdDateTime = Moment(userInfo.createdDateTime).format(dateFormat);

            if (userInfo.companyStartDate && userInfo.companyStartDate.length != dateFormat.length)
                userInfo.companyStartDate = Moment(userInfo.companyStartDate).format(dateFormat);

            if (userInfo.lastPositionChangeDate && userInfo.lastPositionChangeDate.length != dateFormat.length)
                userInfo.lastPositionChangeDate = Moment(userInfo.lastPositionChangeDate).format(dateFormat);

            if (userInfo.adLastLoginDate && userInfo.adLastLoginDate.length != dateFormat.length)
                userInfo.adLastLoginDate = Moment(userInfo.adLastLoginDate).format(dateFormat);

            if (userInfo.adCreationDate && userInfo.adCreationDate.length != dateFormat.length)
                userInfo.adCreationDate = Moment(userInfo.adCreationDate).format(dateFormat);

            if (userInfo.adExpirationDate && userInfo.adExpirationDate.length != dateFormat.length)
                userInfo.adExpirationDate = Moment(userInfo.adExpirationDate).format(dateFormat);
                
            setState({
                aadGroups: aadGroups,
                aadGroupsFiltered: aadGroups,
                user: {
                    ...userInfo,
                    profilePicture: profilePicture.data,
                }
            });

            setIsLoadingUser(false);

        } catch (e) {
            trackError(e);
            setIsLoadingUser(false);
        }
    }

    const [isLoadingUserMemberships, setIsLoadingUserMemberships] = useState(false);
    const loadUserMemberships = async (userId, account, customApplication) => {
        try {
            setIsLoadingUserMemberships(true);

            const responseMemberships = await ApiClient.get(ApiRoutes.User.GetRunningMemberships({ userId, account, customApplication }));

            setState({ memberships: responseMemberships.data });

            setIsLoadingUserMemberships(false);

        } catch (e) {
            trackError(e);
            setIsLoadingUserMemberships(false);
        }
    }

    const [isLoadingUserReports, setIsLoadingUserReports] = useState(false);
    const loadUserReports = async (reportsIds) => {
        try {
            setIsLoadingUserReports(true);

            const responseReports = await ApiClient.get(ApiRoutes.Search.GetPowerBIReports({ reportsIds: JSON.stringify(reportsIds) }));
    
            setState({ userReports: responseReports.data });

            setIsLoadingUserReports(false);
        } catch (e) {
            trackError(e);
            setIsLoadingUserReports(false);
        }
    }

    const [isLoadingUserSodActivities, setIsLoadingUserSodActivities] = useState(false);
    const loadUserSodActivities = async (userId, account, customApplication) => {
        try {
            setIsLoadingUserSodActivities(true);

            const r = await ApiClient.get(ApiRoutes.User.GetSodActivities({ userId, account, customApplication }));

            setState({
                sodActivities: r.data
            });

            setIsLoadingUserSodActivities(false);

        } catch (e) {
            trackError(e);
            setIsLoadingUserSodActivities(false);
        }
    }

    const [isLoadingSapUser, setIsLoadingSapUser] = useState(false);
    const loadSapUser = async (accountName) => {
        try {
            setIsLoadingSapUser(true);

            const r = await ApiClient.get(ApiRoutes.User.GetSapUser({ accountName }));

            setState({
                sapUser: r.data
            });

            setIsLoadingSapUser(false);

        } catch (e) {
            trackError(e);
            setIsLoadingSapUser(false);
        }
    }

    const [isLoadingSapAuthorizationObjects, setIsLoadingSapAuthorizationObjects] = useState(false);
    const loadSapAuthorizationObjects = async (accountName, size, page, sortBy, sortByDescending, onlyImportant, propertyFilters) => {
        try {
            setIsLoadingSapAuthorizationObjects(true);

            const data = {
                accountName,
                size,
                page,
                sortBy,
                sortByDescending,
                onlyImportant,
                propertyFilters
            };
            const r = await ApiClient.post(ApiRoutes.User.ListSapAuthorizationObjects(), data);

            setState({
                sapAuthorizationObjects: r.data
            });

            setIsLoadingSapAuthorizationObjects(false);

        } catch (e) {
            trackError(e);
            setIsLoadingSapAuthorizationObjects(false);
        }
    }

    const [isLoadingSapTransactions, setIsLoadingSapTransactions] = useState(false);
    const loadSapTransactions = async (accountName, size, page, sortBy, sortByDescending, onlyImportant, propertyFilters) => {
        try {
            setIsLoadingSapTransactions(true);

            const data = {
                accountName,
                size,
                page,
                sortBy,
                sortByDescending,
                onlyImportant,
                propertyFilters
            };
            const r = await ApiClient.post(ApiRoutes.User.ListSapTransactions(), data);

            setState({
                sapTransactions: r.data
            });

            setIsLoadingSapTransactions(false);

        } catch (e) {
            trackError(e);
            setIsLoadingSapTransactions(false);
        }
    }

    const [isLoadingSapRoles, setIsLoadingSapRoles] = useState(false);
    const loadSapRoles = async (accountName, size, page, onlyImportant) => {
        try {
            setIsLoadingSapRoles(true);

            const r = await ApiClient.get(ApiRoutes.User.ListSapRoles({ accountName, size, page, onlyImportant }));

            setState({
                sapRoles: r.data
            });

            setIsLoadingSapRoles(false);

        } catch (e) {
            trackError(e);
            setIsLoadingSapRoles(false);
        }
    }

    const [isLoadingSapFireFighters, setIsLoadingSapFireFighters] = useState(false);
    const loadSapFireFighters = async (accountName, size, page, sortBy, sortByDescending, propertyFilters) => {
        try {
            setIsLoadingSapFireFighters(true);

            const data = {
                accountName,
                size,
                page,
                sortBy,
                sortByDescending,
                propertyFilters
            };
            const r = await ApiClient.post(ApiRoutes.User.ListSapFireFighters(), data);

            const newItems = r.data.items.map(item => {
                return {
                    ...item,
                    validToDate: Moment(item.validToDate).format(dateFormat)
                };
            });

            setState({
                sapFireFighters: {
                    ...r.data,
                    items: newItems
                }
            });

            setIsLoadingSapFireFighters(false);

        } catch (e) {
            trackError(e);
            setIsLoadingSapFireFighters(false);
        }
    }

    const [isLoadingSapOrganizations, setIsLoadingSapOrganizations] = useState(false);
    const loadSapOrganizations = async (accountName, size, page, sortBy, sortByDescending, onlyImportant, propertyFilters) => {
        try {
            setIsLoadingSapOrganizations(true);

            const data = {
                accountName,
                size,
                page,
                sortBy,
                sortByDescending,
                onlyImportant,
                propertyFilters
            };
            const r = await ApiClient.post(ApiRoutes.User.ListSapOrganizations(), data);

            setState({
                sapOrganizations: r.data
            });

            setIsLoadingSapOrganizations(false);

        } catch (e) {
            trackError(e);
            setIsLoadingSapOrganizations(false);
        }
    }

    const [isLoadingSapFilters, setIsLoadingSapFilters] = useState(false);
    const loadSapFilters = async () => {
        try {
            setIsLoadingSapFilters(true);

            const r = await ApiClient.get(ApiRoutes.User.GetSapPropertyFilters());

            setState({
                sapFilters: r.data
            });

            setIsLoadingSapFilters(false);

        } catch (e) {
            trackError(e);
            setIsLoadingSapFilters(false);
        }
    }

    const [isLoadingBeanstoreData, setIsLoadingBeanstoreData] = useState(false);
    const loadBeanstoreData = async (accountName) => {
        try {
            setIsLoadingBeanstoreData(true);

            const r = await ApiClient.get(ApiRoutes.User.GetBeanstoreUserAccounts({ accountName }));

            setState({
                beanstoreCashiers: r.data.filter(x => x.userType === 0),
                beanstoreSellers: r.data.filter(x => x.userType === 1)
            });

            setIsLoadingBeanstoreData(false);

        } catch (e) {
            trackError(e);
            setIsLoadingBeanstoreData(false);
        }
    }

    const [isLoadingBtraceUser, setisLoadingBtraceUser] = useState(false);
    const loadBtraceUser = async (accountName) => {
        try {
            setisLoadingBtraceUser(true);

            const rUser = await ApiClient.get(ApiRoutes.User.GetBtraceUser({ accountName }));
            const rUserRoles = await ApiClient.get(ApiRoutes.User.GetBtraceUserRoles({ accountName }));

            setState({
                btraceUser: {
                    ...rUser.data,
                    roles: rUserRoles.data
                }
            });

            setisLoadingBtraceUser(false);

        } catch (e) {
            trackError(e);
            setisLoadingBtraceUser(false);
        }
    }

    const [isLoadingSalesforceUser, setisLoadingSalesforceUser] = useState(false);
    const loadSalesforceUser = async (accountName) => {
        try {
            setisLoadingSalesforceUser(true);

            const user = await ApiClient.get(ApiRoutes.User.GetSalesforceUser({ accountName }));

            setState({
                salesforceUser: {
                    ...user.data
                }
            });

            setisLoadingSalesforceUser(false);

        } catch (e) {
            trackError(e);
            setisLoadingSalesforceUser(false);
        }
    }

    const cleanup = () => {
        setState({
            user: {},
            userId: null,
            accountName: null,
            memberships: [],
            sodActivities: [],
            sapUser: {},
            sapAuthorizationObjects: {},
            sapTransactions: {},
            sapRoles: {},
            sapFireFighters: {},
            sapOrganizations: {},
            sapFilters: [],
            openTransactionTable: true,
            openAuthObjectTable: false,
            openOrgLvlTable: false,
            openFirefightersTableClick: true
        })
    }

    return (
        <Provider value={{
            loadApplicationUser,
            cleanApplicationUser,
            isLoadingApplicationUser,
            initUser,
            isLoadingUser,
            loadUser,
            isLoadingUserMemberships,
            loadUserMemberships,
            isLoadingUserReports,
            loadUserReports,
            loadUserSodActivities,
            isLoadingUserSodActivities,
            isLoadingSapUser,
            loadSapUser,
            isLoadingSapAuthorizationObjects,
            loadSapAuthorizationObjects,
            isLoadingSapTransactions,
            loadSapTransactions,
            isLoadingSapRoles,
            loadSapRoles,
            isLoadingSapFireFighters,
            loadSapFireFighters,
            isLoadingSapOrganizations,
            loadSapOrganizations,
            isLoadingSapFilters,
            loadSapFilters,
            loadBeanstoreData,
            isLoadingBeanstoreData,
            loadBtraceUser,
            isLoadingBtraceUser,
            loadSalesforceUser,
            isLoadingSalesforceUser,
            cleanup,
            state,
            tab,
            setTab,
            applicationTab,
            setApplicationTab,
            toggleTransactionTableClick,
            toggleAuthObjectTableClick,
            toggleOrgLvlTableClick,
            toggleFirefightersTableClick
        }}>
            {props.children}
        </Provider>
    );
}
import React, { useEffect, useContext } from "react";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import { FieldError } from "../../../../../ui-components/FieldError";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { Controller, useForm } from 'react-hook-form';
import { SearchContext } from '../../../../../contexts';
import _ from "lodash";
import useStyles from "../../../../../styles/useStyles";
import { SapCriteriaSearchEntities, truncateString } from "../../../../../utils";
import { useMemo } from "react";

const SapCriteriaForm = (props) => {

    const {
        handleSubmit,
        setValue,
        watch,
        getValues,
        formState: { errors },
        control
    } = useForm({
        defaultValues: {
            entityName: SapCriteriaSearchEntities[0]
        }
    });

    const watchedEntityType = watch("entityName");

    const {
        state,
        toggleOpenSearch,
        isLoadingEntityOptions,
        loadEntityOptions,
        cleanEntityOptions
    } = useContext(SearchContext);

    const searchOptions = async (e, searchText) => {
        e && e.type !== "click" && await loadEntityOptions(watchedEntityType.key, searchText);
    }

    const isMultipleAutocomplete = useMemo(() => watchedEntityType?.multiple ?? false,
        [watchedEntityType]);

    useEffect(() => {
        async function fetchFromApi() {
            await loadEntityOptions(watchedEntityType.key);
        }

        cleanEntityOptions();
        setValue('entityObjectIds', isMultipleAutocomplete ? [] : undefined)
        fetchFromApi();

        return () => {
            cleanEntityOptions();
        }
    }, [watchedEntityType, isMultipleAutocomplete])

    const renderOptionLabel = option => {
        if (isMultipleAutocomplete) return option.value;
        return option.description ?
            truncateString(`${option.value} - ${option.description}`, 150)
            :
            truncateString(`${option.value}`, 150);
    };

    const classes = useStyles();

    const handleValidation = value => {
        const selectedObjects = getValues('entityObjectIds');
        const selectedDescription = getValues('description');
        if ((!value && !selectedObjects) || (selectedObjects.length === 0 && !selectedDescription))
            return 'At least one of Name and Short Description is required';
        return true;
    };

    return (
        <form onSubmit={handleSubmit(props.onSubmit)}>
            <Accordion
                defaultExpanded
                expanded={state.openSearch}
                onChange={() => toggleOpenSearch()}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">
                        <center>Users by SAP Transaction</center>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={12} className={classes.searchFormItems}>
                            <Controller
                                control={control}
                                name="entityName"
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        onChange={(event, item) => {
                                            onChange(item);
                                        }}
                                        value={value}
                                        size="small"
                                        options={SapCriteriaSearchEntities}
                                        getOptionLabel={(option) => option.value || ''}
                                        getOptionSelected={(option, value) =>
                                            value === undefined || value == "" || option.key === value.key
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Criteria"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                required
                                                InputLabelProps={{
                                                    style: { fontSize: 14 }
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        {errors?.entityName &&
                            <Grid item xs={12} className={classes.searchFormItemsError}>
                                <FieldError error={errors?.entityName} customMessage="Entity is required" />
                            </Grid>
                        }
                        <Grid item xs={12} className={classes.searchFormItems}>
                            <Controller
                                control={control}
                                name="entityObjectIds"
                                rules={{
                                    validate: {
                                        required: handleValidation
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        onChange={(event, item) => {
                                            onChange(item);
                                        }}
                                        value={value}
                                        size="small"
                                        options={state.entities}
                                        getOptionLabel={renderOptionLabel}
                                        getOptionSelected={(option, value) =>
                                            value === undefined || value === "" || option.key === value.key
                                        }
                                        loading={isLoadingEntityOptions}
                                        disabled={!watchedEntityType}
                                        multiple={isMultipleAutocomplete}
                                        key={isMultipleAutocomplete}
                                        onInputChange={_.debounce(searchOptions, 600)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Name"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                InputLabelProps={{
                                                    style: { fontSize: 14 }
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        {errors?.entityObjectIds &&
                            <Grid item xs={12} className={classes.searchFormItemsError}>
                                <FieldError error={errors?.entityObjectIds} />
                            </Grid>
                        }
                        <Grid item xs={12} className={classes.searchFormItems}>
                            <Controller
                                control={control}
                                name="description"
                                rules={{
                                    maxLength: 250,
                                    validate: {
                                        required: handleValidation
                                    }
                                }}
                                render={({ field }) =>
                                    <TextField
                                        {...field}
                                        size="small"
                                        label="Short Description"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        autoComplete="off"
                                        InputLabelProps={{
                                            style: { fontSize: 14 }
                                        }}
                                    />}
                            />
                        </Grid>
                        {errors?.description &&
                            <Grid item xs={12} className={classes.searchFormItemsError}>
                                <FieldError error={errors?.description} />
                            </Grid>
                        }
                    </Grid>
                </AccordionDetails>
                <Divider />
                <AccordionActions>
                    <Grid container justifyContent="center">
                        <Button
                            color='primary'
                            size='medium'
                            type='submit'
                            variant='contained'
                            disabled={props.isLoading}
                        >
                            SEARCH
                        </Button>
                    </Grid>
                </AccordionActions>
            </Accordion>
        </form >
    );
}

export default SapCriteriaForm;
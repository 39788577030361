import React, { useContext, useEffect } from "react";
import { Grid, Typography, Card, CardContent, CardHeader, Divider, Chip, Tooltip } from "@material-ui/core";
import { ApplicationContext } from "../../contexts";
import { useParams } from "react-router";
import useStyles from "../../styles/useStyles";
import { ProgressBar } from "../../ui-components";
import { MembersTable } from "../admin/tables/MembersTable";

export const RolePage = () => {
    const {
        loadRole,
        loadRoleMembers,
        cleanRoleMembers,
        cleanRole,
        isLoadingRole,
        state
    } = useContext(ApplicationContext);

    const { applicationId, roleId } = useParams();

    useEffect(() => {
        async function fetchFromApi() {
            let appIdString = (applicationId === undefined || applicationId === null) ? '' : applicationId.toString();
            let roleIdString = (roleId === undefined || roleId === null) ? '' : roleId.toString();
            await loadRole(appIdString, roleIdString);
            await loadRoleMembers(roleIdString);
        }

        fetchFromApi();

        return () => {
            cleanRole();
            cleanRoleMembers();
        }
    }, [])
    const classes = useStyles();

    const { application, role } = state;
    return (
        <div className={classes.mainSectionWrapper}>
            <Grid container spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="flex-start">
                <Grid container item xs={12}>
                    <Grid item xs={12} className={classes.mainHeader}>
                        <Typography className={classes.fontTitleBig}>
                            <center>ROLE DETAILS</center>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                    <ProgressBar loading={isLoadingRole} />
                    <Card>
                        <CardHeader title={role?.displayName} />
                        <CardContent>
                            {
                                role &&
                                <Grid container spacing={3}>
                                    <Divider />
                                    <Grid container item spacing={3} xs={12} md={6}>
                                        <Grid item xs={3}>
                                            <Typography>
                                                Name:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9} >
                                            <Typography>
                                                {role.name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={3} xs={12} md={6}>
                                        <Grid item xs={3}>
                                            <Typography>
                                                Display Name:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography>
                                                {role.displayName}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={3} xs={12} md={6}>
                                        <Grid item xs={3}>
                                            <Typography>
                                                Visibility:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography>
                                                {role.visibility}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={3} xs={12} md={6}>
                                        <Grid item xs={3}>
                                            <Typography>
                                                Type:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography>
                                                {(role.type)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={3} xs={12} md={6}>
                                        <Grid item xs={3}>
                                            <Typography>
                                                Description:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography>
                                                {role.description}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {
                                        role.pbiRole &&
                                        <Grid container item spacing={3} xs={12} md={6}>
                                            <Grid item xs={3}>
                                                <Typography>
                                                    Role:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography>
                                                    {role.pbiRole}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                    {
                                        role.pbiAuthInfoObject &&
                                        <Grid container item spacing={3} xs={12} md={6}>
                                            <Grid item xs={3}>
                                                <Typography>
                                                    Auth Info Object:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography>
                                                    {role.pbiAuthInfoObject}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                    {
                                        role.pbiAuthValue &&
                                        <Grid container item spacing={3} xs={12} md={6}>
                                            <Grid item xs={3}>
                                                <Typography>
                                                    Auth Value:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography>
                                                    {role.pbiAuthValue}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                    <Grid container item spacing={3} xs={12} md={6}>
                                        <Grid item xs={3}>
                                            <Typography>
                                                SoD Activities:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            {role.sodActivityInfo.map(x =>
                                                <Tooltip title={x.description ?? ""}>
                                                    <Chip label={x.value} variant="outlined" />
                                                </Tooltip>
                                            )}
                                        </Grid>
                                    </Grid>

                                    {
                                        role.type === 'Active Directory' ?
                                        <Grid container item spacing={3} xs={12} md={6}>
                                            <Grid item xs={3}>
                                                <Typography>
                                                    AD Description:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography>
                                                    {role.adDescription}
                                                </Typography>
                                            </Grid>
                                        </Grid> :
                                        null
                                    }
                                </Grid>
                            }
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                    {<MembersTable title="Role Members" />}
                </Grid>
            </Grid>
        </div>
    );
};
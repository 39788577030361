import React, { useContext, useState } from 'react';
import { InsightContext } from "./InsightContext";
import { useAggregatedState, searchCategories, DefaultValues } from "../utils";
import { ApiRoutes, ApiClient } from "../utils";
import { useSnackbar } from 'notistack';

export const SearchContext = React.createContext(null);

const { Provider } = SearchContext;
export const SearchProvider = (props) => {
    const { trackError } = useContext(InsightContext)
    const [isSearchingUsers, setIsSearchingUsers] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useAggregatedState({
        searchCriteria: {},
        searchCategory: searchCategories.ActiveDirectory,
        users: [],
        adGroups: [],
        devices: [],
        transactionOptions: [],
        applicationOptions: [],
        roleOptions: [],
        groups: [],
        entities: [],
        openSearch: true,
        firedOnce: false, // if the search has been submitted at least once
        adSearchMemberOf: []
    });

    const [isSubgroupClicked, setIsSubgroupClicked] = React.useState(false);

    const setMemberOfState = (newState) => {
        setState({
            adSearchMemberOf: newState
        });
    };

    const handleDirectTransactionSearch = (searchCriteria) => {
        if (!searchCriteria)
            searchCriteria = searchCategories.ActiveDirectory
        setSearchCategory(searchCriteria);
    };

    const handlGroupClickSearch = async (groupId, groupName) => {
        try {
            setIsSubgroupClicked(true);
            setIsSearchingUsers(true);

            let adSearchMemberOf = [];
            adSearchMemberOf.push({ id: groupId, displayName: groupName });

            setState({
                adSearchMemberOf,
                openSearch: true,
                searchCategory: 'AD' // redirect the user to the AD tab form
            });
            setIsSearchingUsers(false);
            setIsSubgroupClicked(false);
        } catch (e) {
            trackError(e);
            setIsSearchingUsers(false);
        }

    }

    const setSearchCategory = (category) => {

        // always reopen the search card when switching search category in the buttons
        setState({
            openSearch: true,
            firedOnce: false,
            adSearchFilters: null,
            sapSearchFilters: null,
            appAndRolesSearchFilters: null,
            entitySearchFilters: null
        });

        if (Object.values(searchCategories).includes(category))
            setState({ searchCategory: category });
        else
            trackError(`Passed search category does not exist: ${category}`);
    }

    const toggleOpenSearch = () => {

        setState({
            openSearch: !state.openSearch
        });
    }

    const searchUsersByAdCriteria = async (data) => {
        try {
            setIsSearchingUsers(true);

            // map the search to the correct format (just the id)
            let memberOfIds = [];
            if (data.adQuery.memberOf) {
                memberOfIds = data.adQuery.memberOf.map(x => x.id);
                data.adQuery.memberOf = memberOfIds;
            }

            const r = await ApiClient.post(ApiRoutes.Search.SearchByAdCriteria(), data);

            setState({
                users: r.data,
                // to close the accordion
                openSearch: false,
                firedOnce: true,
                adSearchFilters: data.adQuery
            });

            setIsSearchingUsers(false);

        } catch (e) {
            trackError(e);
            enqueueSnackbar(DefaultValues.DefaltErrorMessage);
            setIsSearchingUsers(false);
        }
    }
    const cleanUsers = () => {
        setState({
            users: []
        })
    }

    const [isLoadingGroups, setIsLoadingGroups] = React.useState(false);
    const loadGroupOptions = async (searchText) => {
        try {

            setIsLoadingGroups(true);

            const r = await ApiClient.get(ApiRoutes.Search.SearchGroupByName({ name: searchText }));

            setState({
                groups: r.data
            });

            setIsLoadingGroups(false);

        } catch (e) {
            setIsLoadingGroups(false);
            trackError(e);
        }
    };

    const searchUsersByEntitiesCriteria = async (data) => {
        try {

            setIsSearchingUsers(true);

            const r = await ApiClient.post(ApiRoutes.Search.SearchByEntitiesCriteria(), data);

            setState({
                users: r.data,
                // to close the accordion
                openSearch: false,
                firedOnce: true,
                entitySearchFilters: data.entitiesQuery
            });

            setIsSearchingUsers(false);

        } catch (e) {
            trackError(e);
            enqueueSnackbar(DefaultValues.DefaltErrorMessage);
            setIsSearchingUsers(false);
        }
    }

    const searchUsersBySapCriteria = async (data) => {
        try {
            setIsSearchingUsers(true);

            const r = await ApiClient.post(ApiRoutes.Search.SearchBySapTransactionCriteria(), data);

            setState({
                users: r.data,
                // to close the accordion
                openSearch: false,
                firedOnce: true,
                sapSearchFilters: data.sapQuery
            });

            setIsSearchingUsers(false);

        } catch (e) {
            trackError(e);
            enqueueSnackbar(DefaultValues.DefaltErrorMessage);
            setIsSearchingUsers(false);
        }
    }

    const searchUsersByAppRolesCriteria = async (data) => {
        try {
            setIsSearchingUsers(true);

            const r = await ApiClient.post(ApiRoutes.Search.SearchByAppRolesCriteria(), data);
            setState({
                users: r.data,
                // to close the accordion
                openSearch: false,
                firedOnce: true,
                appAndRolesSearchFilters: data.appAndRolesQuery
            });

            setIsSearchingUsers(false);

        } catch (e) {
            trackError(e);
            enqueueSnackbar(DefaultValues.DefaltErrorMessage);
            setIsSearchingUsers(false);
        }
    }

    const [isSearchingGroups, setIsSearchingGroups] = useState(false);

    const searchGroupsByCriteria = async (data) => {
        try {
            setIsSearchingGroups(true);

            const r = await ApiClient.post(ApiRoutes.Search.SearchGroupsByAdCriteria(), data);

            setState({
                adGroups: r.data,
                openSearch: false,
                firedOnce: true,
                groupsCriteria: data
            });

            setIsSearchingGroups(false);

        } catch (e) {
            trackError(e);
            enqueueSnackbar(DefaultValues.DefaltErrorMessage);
            setIsSearchingGroups(false);
        }
    }

    const cleanAdGroups = () => setState({
        adGroups: []
    });

    const [isSearchingDevices, setIsSearchingDevices] = useState(false);

    const searchDevicesByCriteria = async (data) => {
        try {
            setIsSearchingDevices(true);

            const r = await ApiClient.post(ApiRoutes.Search.SearchDevicesByAdCriteria(), data);

            setState({
                devices: r.data,
                openSearch: false,
                firedOnce: true,
                devicesCriteria: data
            });

            setIsSearchingDevices(false);

        } catch (e) {
            trackError(e);
            enqueueSnackbar(DefaultValues.DefaltErrorMessage);
            setIsSearchingDevices(false);
        }
    }

    const cleanDevices = () => setState({
        devices: []
    });

    const [isLoadingTransactionOptions, setIsLoadingTransactionOptions] = useState(false);
    const loadTransactionOptions = async (codeQuery) => {
        try {
            setIsLoadingTransactionOptions(true);

            const r = await ApiClient.get(ApiRoutes.Search.GetTransactionOptions({
                codeQuery
            }));

            setState({
                transactionOptions: r.data
            });

            setIsLoadingTransactionOptions(false);

        } catch (e) {
            trackError(e);
            setIsLoadingTransactionOptions(false);
        }
    }
    const cleanTransactionOptions = () => {
        setState({
            transactionOptions: []
        })
    }

    const [isLoadingApplicationOptions, setIsLoadingApplicationOptions] = useState(false);
    const loadApplicationOptions = async () => {
        try {
            setIsLoadingApplicationOptions(true);

            const r = await ApiClient.get(ApiRoutes.Search.GetApplicationOptions());

            setState({
                applicationOptions: r.data
            });

            setIsLoadingApplicationOptions(false);

        } catch (e) {
            trackError(e);
            setIsLoadingApplicationOptions(false);
        }
    }
    const cleanApplicationOptions = () => {
        setState({
            applicationOptions: []
        })
    }

    const [isLoadingRoleOptions, setIsLoadingRoleOptions] = useState(false);
    const loadRoleOptions = async (applicationId, searchText) => {
        try {
            setIsLoadingRoleOptions(true);

            const r = await ApiClient.get(ApiRoutes.Search.GetRoleOptions({ applicationId, searchText }));

            setState({
                roleOptions: r.data
            });

            setIsLoadingRoleOptions(false);

        } catch (e) {
            trackError(e);
            setIsLoadingRoleOptions(false);
        }
    }
    const cleanRoleOptions = () => {
        setState({
            roleOptions: []
        })
    }

    const [isLoadingEntityOptions, setIsLoadingEntityOptions] = useState(false);
    const loadEntityOptions = async (entityName, codeQuery) => {
        try {
            setIsLoadingEntityOptions(true);

            const r = await ApiClient.get(ApiRoutes.Search.GetEntityOptions({ entityName, codeQuery }));

            setState({
                entities: r.data
            });

            setIsLoadingEntityOptions(false);

        } catch (e) {
            trackError(e);
            setIsLoadingEntityOptions(false);
        }
    }
    const cleanEntityOptions = () => {
        setState({
            entities: []
        })
    }

    return (
        <Provider value={{
            state,
            setSearchCategory,
            cleanUsers,
            isSearchingUsers,
            searchUsersByAdCriteria,
            searchUsersBySapCriteria,
            searchUsersByEntitiesCriteria,
            searchUsersByAppRolesCriteria,
            isLoadingTransactionOptions,
            loadTransactionOptions,
            cleanTransactionOptions,
            isLoadingApplicationOptions,
            loadApplicationOptions,
            cleanApplicationOptions,
            isLoadingRoleOptions,
            loadRoleOptions,
            cleanRoleOptions,
            loadGroupOptions,
            isLoadingGroups,
            toggleOpenSearch,
            isLoadingEntityOptions,
            loadEntityOptions,
            cleanEntityOptions,
            handlGroupClickSearch,
            isSubgroupClicked,
            setMemberOfState,
            handleDirectTransactionSearch,
            isSearchingGroups,
            searchGroupsByCriteria,
            cleanAdGroups,
            isSearchingDevices,
            searchDevicesByCriteria,
            cleanDevices
        }}>
            {props.children}
        </Provider>
    );
}
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { InsightContext } from '../../../../contexts';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ApiRoutes, ApiClient, DefaultValues } from "../../../../utils";
import { NavRoutes } from "../../../../utils";

const useUniqueCode = () => {
    const { trackError } = useContext(InsightContext);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const [uniqueCodes, setUniqueCodes] = useState([]);
    const [uniqueCodesLoading, setUniqueCodesLoading] = useState(false);
    const getUniqueCodes = async () => {
        setUniqueCodesLoading(true);

        ApiClient.get(ApiRoutes.UniqueCodes.RestResource())
            .then((response) => {
                setUniqueCodes(response.data);
            })
            .catch((reason) => {
                trackError(reason);
                setUniqueCodes([]);
                enqueueSnackbar(DefaultValues.DefaltErrorMessage);
            })
            .finally(() => {
                setUniqueCodesLoading(false);
            });
    };

    const [uniqueCode, setUniqueCode] = useState([]);
    const [uniqueCodeLoading, setUniqueCodeLoading] = useState(false);
    const getUniqueCode = async (id) => {
        setUniqueCodeLoading(true);

        ApiClient.get(ApiRoutes.UniqueCodes.RestResource(id))
            .then((response) => {
                setUniqueCode(response.data);
            })
            .catch((reason) => {
                trackError(reason);
                enqueueSnackbar(DefaultValues.DefaltErrorMessage);
            })
            .finally(() => {
                setUniqueCodeLoading(false);
            });
    };

    const [updateLoading, setUpdateLoading] = useState(false);
    const updateUniqueCode = async (uniqueCode) => {
        setUpdateLoading(true);

        if (uniqueCode.id === undefined || uniqueCode.id === null ||
            uniqueCode.firstName === undefined || uniqueCode.firstName === null ||
            uniqueCode.lastName === undefined || uniqueCode.lastName === null ||
            uniqueCode.cashierKnownAs === undefined || uniqueCode.cashierKnownAs === null ||
            uniqueCode.beanstoreId === undefined || uniqueCode.beanstoreId === null ||
            uniqueCode.cidEmployNumber === undefined || uniqueCode.cidEmployNumber === null ||
            uniqueCode.mail === undefined || uniqueCode.mail === null) {


            enqueueSnackbar("Missing Data");
            setUpdateLoading(false);
        }
        else {
            ApiClient.post(ApiRoutes.UniqueCodes.UpsertUniqueCode(), uniqueCode)
                .then((response) => {
                    enqueueSnackbar("Action completed successfully", { variant: "success" });
                    history.push(NavRoutes.UniqueCodeAdmin);
                })
                .catch((reason) => {
                    trackError(reason);
                    enqueueSnackbar(DefaultValues.DefaltErrorMessage);
                })
                .finally(() => {
                    setUpdateLoading(false);
                });
        }
    };

    const [deleteLoading, setDeleteLoading] = useState(false);
    const deleteUniqueCode = async (id) => {
        setDeleteLoading(true);
        ApiClient.delete(ApiRoutes.UniqueCodes.DeleteBeanstoreUniqueCodes({ id }))
            .then((response) => {
                enqueueSnackbar("Action completed successfully", { variant: "success" });
                history.push(NavRoutes.UniqueCodeAdmin);
            })
            .catch((reason) => {
                trackError(reason);
                enqueueSnackbar(DefaultValues.DefaltErrorMessage);
            })
            .finally(() => {
                setDeleteLoading(false);
            });
    };

    return {
        getUniqueCodes,
        uniqueCodes,
        uniqueCodesLoading,

        getUniqueCode,
        uniqueCode,
        uniqueCodeLoading,

        updateLoading,
        updateUniqueCode,

        deleteLoading,
        deleteUniqueCode
    };
};

export default useUniqueCode;
import { LinearProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#fff",
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export const ProgressBar = ({ loading }) => {
    const classes = useStyles();
    if (loading)
        return <LinearProgress size={24} className={classes.root} />
    return <></>
}

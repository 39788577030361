import {
    Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Stepper, Card, Tooltip, Step, StepLabel, Button, Typography
} from '@material-ui/core';
import useStyles from "../../../../styles/useStyles";
import React, { useContext, useState, useEffect, useMemo } from "react";
import { KeyValueListSoDAnalysis } from '../../../../ui-components';
import { ApplicationContext } from '../../../../contexts';
import Moment from 'moment';
import { Formats } from '../../../../utils'
import { set } from 'lodash';

function renderSoDChips(sodActivity) {
    return sodActivity?.map((x => x.extendedName));
}

function createSoDImpactRows(transaction, description, sodActivity) {
    const soDChips = renderSoDChips(sodActivity);
    return { transaction, description, soDChips };
}

const getSoDImpactData = (items) => {
    if (items !== undefined) {
        return items.selectedTransactions?.map(x =>
            createSoDImpactRows(
                x.transactionName,
                x.transactionShortDescription,
                x.sodActivity));
    }
};

const getLastState = (items) => {

    if (items !== undefined) {
        if (items.statusTypology == 0)
            return [{ title: "No new risk", status: 0 }];
        else if (items.statusTypology == 3)
            return [
                { title: "New risks found", status: 1 },
                { title: "Waiting for manager's feedback", status: 2 },
                { title: "Approved", status: 3 }];
        else if (items.statusTypology == 4)
            return [
                { title: "New risks found", status: 1 },
                { title: "Waiting for manager's feedback", status: 2 },
                { title: "Rejected", status: 4 }];
        else {
            const a = [{ title: "New risks found", status: 1 },
            { title: "Waiting for manager's feedback", status: 2 },
            { title: "Approve/Reject", status: 3 },
            ];
            return a;
        }
    } else
        return [
            { title: "New risks found", status: 1 },
            { title: "Waiting for manager's feedback", status: 2 },
            { title: "Approve/Reject", status: 3 },
        ];
};

const getUserData = (newSoDAnalysis, company) => {
    if (newSoDAnalysis !== undefined) {
        return {
            incident: newSoDAnalysis.incident,
            user: newSoDAnalysis.user?.accountName,
            userCompany: newSoDAnalysis.user?.companyName,
            jobProfile: newSoDAnalysis.user?.jobTitle,
            userInvolved: newSoDAnalysis.usersInvolved,
            tjpInvolved: newSoDAnalysis.tjPs?.map(x =>
                <Tooltip
                    key={x.id}
                    title={x.roleName} >
                    <Typography style={{ fontSize: 14 }} noWrap>{x.roleName}</Typography>
                </Tooltip >),
            riskOwner: company?.financeRiskOwnerDisplayName,
        };
    }
};

const CardRequestDetail = ({ data, updateData, managersEmail, company }) => {
    const {
        downloadProcedure,
    } = useContext(ApplicationContext);
    const dateFormat = Formats.DateFormat;

    const classes = useStyles();
    const soDImpactData = useMemo(() => getSoDImpactData(data), [data]);
    const lastState = useMemo(() => getLastState(data), [data]);
    const downloadFile = (fileId, fileName) => downloadProcedure(fileId, fileName);

    const [activeStep, setActiveStep] = useState(0);
    const [buttonLabels, setButtonLabels] = useState([]);
    const [highPriority, setHighPriority] = useState(false);

    const getSteps = () => {
        return lastState?.map((step) =>
            <Step key={step.title} color="primary">
                <StepLabel>{step.title}</StepLabel>
            </Step>
        );
    }
    useEffect(() => {
        if(data && data?.newRisks){
            data?.newRisks.forEach(element => {
                if(highPriority=== false  && element.criticality!== 'Low')
                setHighPriority(true);
            });
        }

        let target = data?.statusTypology;
        setButtonLabels([
            data?.statusTypology == 1 ? "Send Email to Manager" : "Approve",
            data?.statusTypology == 1 ? "Send Email to Finance Risk Owner" : "Reject"
        ]);
        const index = lastState?.findIndex((step) => step.status === target);
        setActiveStep(index);
    }, [data]);

    const userData = useMemo(() => getUserData(data, company), [data, company, managersEmail]);

    const dataLabels = [
        { property: 'incident', label: 'Incident' },
        { property: 'user', label: 'User' },
        { property: 'userCompany', label: 'User Company' },
        { property: 'userManager', label: 'User\'s Manager' },
        { property: 'jobProfile', label: 'Job Profile' },
        { property: 'userInvolved', label: 'Users involved' },
        { property: 'tjpInvolved', label: 'TJP Involved' },
        { property: 'riskOwner', label: 'Risk Owner' }
    ];

    const onClickDetailsButton = async (number) => {
        if (data !== undefined) {
            // se  ci  sono  nuovi rischi devo controllare  se  c'�  o  meno  l'email associa ta  e  verificare  la criticit�
            switch (number) {
                case 1:// download
                    const fileName = (data.user.displayName ?? "user") + " SoD Analysis Detail " + Moment(data.createDateUtc).format(dateFormat) + ".xlsx";
                    await downloadFile(data.storedFileId, fileName)
                    break;
                case 2: // button email manager/ approve
                    data?.statusTypology == 1 ?
                        await updateData(data?.id, 2, "emailManager")
                        :
                        await updateData(data?.id, 3, "approved")
                    break;
                case 3: // button email finance risk owner
                    data?.statusTypology == 1 ?
                        await updateData(data?.id, 2, "emailRiskOwner")
                        :
                        await updateData(data?.id, 4, "rejected")
                    break;
                default:
                    throw new Error("onClickDetailsButton: case not supported");
            }
        }
    };

    const renderSoDImpact = <Paper style={{ marginBottom: "30px" }}>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Transaction Requested</TableCell>
                        <TableCell>Transaction Description</TableCell>
                        <TableCell>SoD Activity</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {soDImpactData?.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">{row.transaction}</TableCell>
                            <TableCell component="th" scope="row">{row.description}</TableCell>
                            <TableCell component="th" scope="row">{React.Children.toArray(row.soDChips?.map(x =>
                                <Tooltip title={x} >
                                    <Chip
                                        style={{
                                            margin: 2,
                                            maxWidth: 170,
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis"
                                        }}
                                        label={x} variant="outlined" />
                                </Tooltip >
                            ))}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Paper>

    const renderButton = <>
        <Button
            color='primary'
            size='medium'
            type='submit'
            variant='contained'
            onClick={() => onClickDetailsButton(1)}
        >
            Download Details
        </Button>
        {
            (data?.statusTypology === 1  && !highPriority ) || data?.statusTypology === 2 ?
                <Button
                    color='primary'
                    size='medium'
                    type='submit'
                    variant='contained'
                    onClick={() => onClickDetailsButton(2)}
                >
                    {buttonLabels[0]}
                </Button>
                :
                undefined
        }
        {
            (company && company.financeRiskOwnerKey != null) && (
                (data?.statusTypology === 1 && highPriority)|| data?.statusTypology === 2) ?
                <Button
                    color='primary'
                    size='medium'
                    type='submit'
                    variant='contained'
                    onClick={() => onClickDetailsButton(3)}
                >
                    {buttonLabels[1]}
                </Button>
                : undefined
        }
    </>


    return (
        <Card className={classes.padding_3} >
            <Grid container item sm={12} spacing={2}>
                <Grid item sm={12}>
                    <Stepper alternativeLabel activeStep={activeStep}>
                        {getSteps()}
                    </Stepper>
                </Grid>
                <Grid style={{ display: "flex", }}>
                    <Grid item sm={4}>
                        <KeyValueListSoDAnalysis data={userData} labels={dataLabels} />
                    </Grid>
                    <Grid item sm={12}>
                        <Typography variant="h5">SoD Impact</Typography>
                        {renderSoDImpact}
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="space-evenly">
                    {renderButton}
                </Grid>
            </Grid>
        </Card>
    );
}
export default CardRequestDetail;
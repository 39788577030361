import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { SearchContext } from '../../../../contexts';
import { NavRoutes, searchCategories } from '../../../../utils';

import { UserTable } from './tables/UserTable';
import { GroupsTable } from './tables/GroupsTable';
import { DevicesTable } from './tables/DevicesTable';

export const SearchResult = () => {
    const {
        state,
        cleanUsers,
        cleanAdGroups,
        cleanDevices
    } = useContext(SearchContext);

    const {
        users,
        adGroups,
        devices
    } = state;

    useEffect(() => {
        !!users?.length && cleanUsers();
        !!adGroups?.length && cleanAdGroups();
        !!devices?.length && cleanDevices();
    }, [state.searchCategory]);

    const history = useHistory();

    useEffect(() => {
        if (!!users?.length && users.length === 1) {
            history.push(NavRoutes.UserDetails(users[0].id, users[0].accountName));
        }
        if (!!adGroups?.length && adGroups.length === 1) {
            history.push(NavRoutes.GroupDetails(adGroups[0].id, adGroups[0].accountName));
        }
        if (!!devices?.length && devices.length === 1) {
            history.push(NavRoutes.DeviceDetails(devices[0].id, devices[0].accountName));
        }
    }, [users, adGroups, devices]);

    const renderContent = () => {
        switch (state.searchCategory) {
            case searchCategories.ActiveDirectory:
            case searchCategories.Sap:
            case searchCategories.AppAndRoles:
                return (
                    <UserTable title={"Account list"} />
                );

            case searchCategories.ADGroups:
                return (
                    <GroupsTable title={"Active Directory Groups List"} />
                );
            case searchCategories.Devices:
                return (
                    <DevicesTable title={"Devices List"} />
                );

            default:
                break;
        }
    }

    return (
        <React.Fragment>
            {state.firedOnce &&
                renderContent()
            }
        </React.Fragment>
    );
}
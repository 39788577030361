import React, { useContext, useEffect } from 'react';

import {
    Box,
    Divider,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core';

import { ApplicationContext } from '../../../../contexts';
import useStyles from '../../../../styles/useStyles';

import { FileManager, InputFiles, ProgressBar } from '../../../../ui-components';

const useLocalStyles = makeStyles((theme) => ({
    dividerWrapperBig: {
        padding: theme.spacing(3, 0)
    },
    procedureCategoryTitle: {
        flexGrow: 1,
        textAlign: "center",
        fontWeight: "bold"
    }
}));

export const ProcedureList = ({
    editable,
    applicationId,
    horizontal,
    useApplicationContext
}) => {
    const {
        getApplicationFiles,
        downloadProcedure,
        deleteProcedure,
        toggleVisibilityClick,
        isLoadingProcedures,
        isDownloadingProcedure,
        isUploadingProcedures,
        isUpdatingProcedures,
        state: appContextState,
        uploadProcedures
    } = useContext(ApplicationContext);

    const [files, setFiles] = React.useState();

    const downloadFile = (fileId, fileName) => downloadProcedure(fileId, fileName);

    const removeFile = async (fileId, appId) => setFiles(await deleteProcedure(fileId, appId));

    const changeVisibility = async (fileId, value) => setFiles(await toggleVisibilityClick(fileId, value));

    const appendFiles = async (entityId, files, category) => await uploadProcedures(category, entityId, files);

    async function refreshFiles(applicationId) {
        if (useApplicationContext)
            setFiles(appContextState.files)
        else
            setFiles(await getApplicationFiles(applicationId));
    }

    useEffect(() => {
        async function fetchData() {
            if (!isUploadingProcedures) await refreshFiles(applicationId);
        }

        fetchData();

        return () => {
            setFiles(null);
        }
    }, [isUploadingProcedures]);

    const classes = useStyles();
    const localClasses = useLocalStyles();

    const ProceduresCategory = ({ category, title, editable }) => (
        <Box p={2} borderRadius={8} bgcolor="gray.ultralight">
            <Typography
                className={localClasses.procedureCategoryTitle}
                variant="subtitle1"
            >
                {title}
            </Typography>

            <FileManager
                files={files}
                onDelete={removeFile}
                onDownload={downloadFile}
                onVisibilityChange={changeVisibility}
                entityId={applicationId}
                category={category}
                editable={editable}
            />

            {editable && (
                <InputFiles
                    appendFiles={appendFiles}
                    entityId={applicationId}
                    title="Upload files"
                    category={category}
                />
            )}
        </Box>
    );

    const isLoading = isLoadingProcedures || isUpdatingProcedures || isUploadingProcedures || isDownloadingProcedure;

    return horizontal
        ?
        <Grid container item sm={12} className={classes.padding_3} spacing={2}>
            <Grid item sm={12}>
                {isLoading &&
                    <ProgressBar loading={isLoading} />
                }
            </Grid>
            <Grid item xs={4}>
                <ProceduresCategory
                    title="Identity Access Procedures Files"
                    category="Identity"
                    editable={editable}
                />
            </Grid>
            <Grid item xs={4}>
                <ProceduresCategory
                    title="Software Change Management Files"
                    category="SoftwareChangeManagement"
                    editable={editable}
                />
            </Grid>
            <Grid item xs={4}>
                <ProceduresCategory
                    title="Cyber Resiliency Procedures"
                    category="Other"
                    editable={editable}
                />
            </Grid>
        </Grid>
        :
        <Grid container item sm={12} className={classes.padding_3}>
            <Grid item sm={12}>
                {isLoading &&
                    <ProgressBar loading={isLoading} />
                }
            </Grid>
            <Grid item xs={12}>
                <ProceduresCategory
                    title="Identity Access Procedures Files"
                    category="Identity"
                    editable={editable}
                />
            </Grid>
            <Grid xs={12} item className={localClasses.dividerWrapperBig}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <ProceduresCategory
                    title="Software Change Management Files"
                    category="SoftwareChangeManagement"
                    editable={editable}
                />
            </Grid>
            <Grid xs={12} item className={localClasses.dividerWrapperBig}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <ProceduresCategory
                    title="Cyber Resiliency Procedures"
                    category="Other"
                    editable={editable}
                />
            </Grid>
        </Grid>
};

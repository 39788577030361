import React, { useState, useEffect } from "react"
import { Button, ButtonGroup, Grid, Paper, Table, TableHead, TableBody, TableRow, TableCell, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { KeyValueList } from "../../../../../ui-components"
import useStyles from "../../../../../styles/useStyles";
import { usePowerbi } from "./usePowerbi";

export const PowerBITabContent = () => {
    const classes = useStyles();
    const [selectedReport, setSelectedReport] = useState();

    const pbiData = usePowerbi();
    useEffect(() => {
        if (pbiData?.reports.data.length > 0)
            setSelectedReport(pbiData?.reports.data[0]);
    }, [pbiData])

    const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
        marginBottom: 20,
        width: '100%',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'column',
            justifyContent: 'start'
        }
    }));

    const StyledButton = styled(Button)({
        background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
        color: "white",
        padding: "10px 30px",
        margin: "5px",
        borderRadius: '4px !important'
    });

    const StyledGridSmall = styled(Grid)(({ theme }) => ({
        [theme.breakpoints.up('md')]: {
            maxWidth: '10%',
            flexBasis: '10%'
        }
    }));

    return (
        <Grid container spacing={2} justifyContent="center">
            <StyledGridSmall item sm={12}>
                <StyledButtonGroup orientation="vertical" color="primary" variant="text">
                    {pbiData && pbiData.reports && pbiData.reports.data.map(report => (
                        <StyledButton
                            style={{ background: "linear-gradient(45deg, #3366ff 30%, #00cc66 90%)" }}
                            onClick={() => setSelectedReport(report)}
                        >
                            {report.displayName}
                        </StyledButton>
                    ))}
                </StyledButtonGroup>
            </StyledGridSmall>

            <Grid item sm={12} md={9} container spacing={3}>
                <Grid item sm={12}>
                    <Paper className={classes.padding_3}>
                        {selectedReport && (
                            <>
                                <Typography variant="h6" component="div" align="center" style={{ paddingBottom: "10px" }}>
                                    REPORT: {selectedReport.displayName}
                                </Typography>
                                <KeyValueList data={selectedReport} labels={pbiData && pbiData.reports.fields} />
                            </>
                        )}
                    </Paper>
                </Grid>

                {selectedReport && selectedReport.pbiReports.length > 0 && (
                    <Grid item sm={12}>
                        <Table style={{ backgroundColor: "white" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Auth Info Object</TableCell>
                                    <TableCell>Auth Value</TableCell>
                                    <TableCell>Area</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedReport && selectedReport.pbiReports.map((x, index) => (
                                    <TableRow key={index}>
                                        <TableCell scope="row">
                                            <Typography style={{ fontSize: "13px" }}>
                                                {x.role}
                                            </Typography>
                                        </TableCell>
                                        <TableCell scope="row">
                                            <Typography style={{ fontSize: "13px" }}>
                                                {x.authInfoObject}
                                            </Typography>
                                        </TableCell>
                                        <TableCell scope="row">
                                            <Typography style={{ fontSize: "13px" }}>
                                                {x.authValue}
                                            </Typography>
                                        </TableCell>
                                        <TableCell scope="row">
                                            <Typography style={{ fontSize: "13px" }}>
                                                {x.area}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { Button, Grid, Modal, Paper, Typography, makeStyles } from '@material-ui/core';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import GetAppIcon from '@material-ui/icons/GetApp';

import useStyles from '../../styles/useStyles';
import { useDevice } from './hooks/useDevice';
import { excelExportFromPanel } from '../../utils';

import { KeyValueList, Shimmer } from '../../ui-components';

const useLocalStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: "white",
        '&:hover': {
            backgroundColor: theme.palette.gray.light,
        }
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'auto',
        borderRadius: '4px'
    }
}));

const DeviceDetailsPage = (props) => {
    const {
        handleDevice,
        device,
        deviceLoading
    } = useDevice();

    let { id } = useParams();

    useEffect(() => {
        !!id && handleDevice({ id });
    }, [id]);

    const dataLabels = [
        { property: "displayName", label: "Name" },
        { property: "deviceId", label: "Device Id" },
        { property: "id", label: "Object Id" },
        { property: "operatingSystem", label: "OS" },
        { property: "operatingSystemVersion", label: "Version" },
        { property: "accountEnabled", label: "Enabled" },
        { property: "isCompliant", label: "Compliant" },
        { property: "trustType", label: "Type" }
    ];

    const dataLabelsAdditional = [
        { property: "complianceExpirationDateTime", label: "Compliance Expiration" },
        { property: "createdDateTime", label: "Created" },
        { property: "deletedDateTime", label: "Deleted" },
        { property: "deviceCategory", label: "Category" },
        { property: "deviceOwnership", label: "Ownership" },
        { property: "domainName", label: "Domain Name" },
        { property: "enrollmentProfileName", label: "Enrollment Profile Name" },
        { property: "enrollmentType", label: "Enrollment Type" },
        { property: "externalSourceName", label: "External Source Name" },
        { property: "isManaged", label: "Is Managed" },
        { property: "managementType", label: "Management Type" },
        { property: "mdmAppId", label: "MDM" },
        { property: "model", label: "Model" }
    ];

    const classes = useStyles();
    const localClasses = useLocalStyles();

    function getModalStyle() {
        const top = 50;
        const left = 50;
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
            width: `70%`,
            height: `60%`
        };
    }

    const [additionalModalVisible, setAdditionalModalVisible] = useState(false);

    return (
        <div className={classes.mainSectionWrapper}>
            <Grid container justifyContent="center">
                <Grid container spacing={3} justifyContent="center" item xs={12}>
                    <Grid container item xs={12}>
                        <Grid item xs={12} className={classes.mainHeader}>
                            <Typography className={classes.fontTitleBig}>
                                <center>{device?.displayName}</center>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} md={5}>
                        <Paper className={classes.padding_3}>
                            <Grid container item sm={12} spacing={2}>
                                <Grid item sm={12} md={7}>
                                    {deviceLoading
                                        ?
                                        <Shimmer variant="text" />
                                        :
                                        <Typography variant="h6">{'Device Details'}</Typography>
                                    }
                                </Grid>
                                <Grid item sm={6} md={3}>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        disabled={deviceLoading}
                                        startIcon={<OpenInBrowserIcon />}
                                        className={localClasses.button}
                                        onClick={() => setAdditionalModalVisible(true)}
                                    >
                                        <Typography style={{ fontSize: '10px' }}>more details</Typography>
                                    </Button>
                                </Grid>
                                <Grid item sm={6} md={2}>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        className={localClasses.button}
                                        disabled={deviceLoading}
                                        startIcon={<GetAppIcon />}
                                        onClick={() => excelExportFromPanel(
                                            { ...device, ...device?.additionalData },
                                            [...dataLabels, ...dataLabelsAdditional],
                                            'Device Details',
                                            `${device?.displayName} - Device Details`
                                        )}
                                    >
                                        <Typography style={{ fontSize: '10px' }}>export</Typography>
                                    </Button>
                                </Grid>
                                <Grid item sm={12}>
                                    {deviceLoading
                                        ?
                                        <Shimmer variant="rect" height={400} />
                                        :
                                        <React.Fragment>
                                            <KeyValueList data={device} labels={dataLabels} />

                                            <Modal
                                                open={additionalModalVisible}
                                                onClose={() => setAdditionalModalVisible(false)}
                                            >
                                                <div style={getModalStyle()} className={localClasses.paper}>
                                                    <Typography variant="h6">
                                                        Additional information of {device?.displayName}
                                                    </Typography>
                                                    <KeyValueList
                                                        data={device?.additionalData}
                                                        labels={dataLabelsAdditional}
                                                    />
                                                </div>
                                            </Modal>
                                        </React.Fragment>
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                </Grid>
            </Grid>
        </div>
    );
}

export default DeviceDetailsPage;
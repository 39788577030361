import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

// Create a theme instance.
export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#e0d9b8',
            light: '#f2f0e3'
        },
        secondary: {
            main: '#19857b',
        },
        danger: {
            main: '#e91e63',
        },
        error: {
            main: '#e91e63',
        },
        background: {
            default: '#f2f0e3',
        },
        gray: {
            main: 'rgba(0, 0, 0, 0.13)',
            dark: 'rgba(0, 0, 0, 0.30)',
            light: 'rgba(0, 0, 0, 0.05)',
            ultralight: 'rgba(0, 0, 0, 0.03)'
        }
    },
});

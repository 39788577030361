import React, { useContext, useMemo, useState } from "react";
import { ControlledTable } from "../../../../../ui-components/table";
import { FilterList } from "../../../../../ui-components/FilterList";
import { UserContext } from "../../../../../contexts";
import { Chip, Tooltip, FormControlLabel, Switch, Typography, Grid, IconButton } from "@material-ui/core";
import { adaptSortAccessor, NavRoutes } from "../../../../../utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SearchIcon from '@material-ui/icons/Search';

export const TransactionTable = ({ title }) => {

    const {
        state,
        isLoadingSapTransactions,
        loadSapTransactions,
        isLoadingSapFilters
    } = useContext(UserContext);

    const mapFromApiDto = f => ({
        propertyName: f.name,
        propertyDisplayName: f.displayName,
        value: f.value
    });
    const mapToApiDto = f => ({
        name: f.propertyName,
        displayName: f.propertyDisplayName,
        value: f.value
    });

    const data = useMemo(() => state.sapTransactions.items ?? [], [state.sapTransactions.items]);
    const availableFilters = useMemo(() => state.sapFilters.transactions.map(mapFromApiDto) ?? [], [state.sapFilters.transactions]);

    const history = useHistory();

    const columns = useMemo(
        () =>
            [
                {
                    Header: 'Sap Role Id',
                    accessor: 'sapRoleId',
                    show: false
                },
                {
                    Header: 'Technical Job Profile',
                    accessor: 'sapRoleName',
                    sortAccessor: 'SapRole.Name'
                },
                {
                    Header: 'TCode',
                    accessor: 'lowAuthorizationValue',
                    disableSortBy: true
                },
                {
                    Header: <Tooltip placement="top" title="if filled in, all values ​​from TCode to High must be considered">
                        <span>High</span>
                    </Tooltip>,
                    accessor: 'highAuthorizationValue',
                    disableSortBy: true
                },
                {
                    Header: 'Transaction Short Description',
                    accessor: 'tCodeMapDescription',
                    disableSortBy: true
                },
                {
                    Header: 'SoD Activities',
                    accessor: 'tCodeMapSodActivities',
                    disableSortBy: true,
                    Cell: (obj) => obj.value ? obj.value.map((x, i) => {
                        if (x.description)
                            return <Tooltip title={x.description}>
                                <Chip
                                    key={i}
                                    variant="outlined"
                                    size="small"
                                    label={x.code + ' - ' + x.description} />
                            </Tooltip>;
                        return <Chip
                            key={i}
                            variant="outlined"
                            size="small"
                            label={x.code} />
                    })
                        :
                        <></> // must return something
                },
                {
                    Header: 'Usage Count',
                    accessor: 'usageCount',
                    disableSortBy: true
                }
            ],
        []
    );

    const [showAll, setShowAll] = useState(false);
    const [configuredFilters, setConfiguredFilters] = useState([]);

    const Filters = () => <Grid container>
        <Grid item xs={12} md={6}>
            <FormControlLabel
                control={<Switch color="primary" />}
                checked={showAll}
                onChange={(e) => setShowAll(e.target.checked)}
                label={<Typography style={{ fontSize: 14.5 }}>Show all Transactions</Typography>} />
        </Grid>
        <Grid item xs={12} md={6}>
            <FilterList
                onSubmit={data => setConfiguredFilters(data.filters.map(mapToApiDto))}
                properties={availableFilters}
                loading={isLoadingSapFilters || isLoadingSapTransactions} />
        </Grid>
    </Grid>

    const preRowActions = (row, loading, onAction) => <IconButton
        variant="link"
        edge="end"
        aria-label="open"
        onClick={() => {
            const encodedCode = encodeURIComponent(row.original.lowAuthorizationValue);
            history.push(NavRoutes.HomeTransaction(encodedCode, "SAP"));
            onAction();
        }}>
        <Tooltip title="Search Transaction">
            <SearchIcon />
        </Tooltip>
    </IconButton>

    const fetchData = React.useCallback(({ sortBy, pageIndex, pageSize }) => {
        const sortProperty = sortBy[0];
        const sortPropertyName = adaptSortAccessor(columns, sortBy[0]?.id);
        const onlyImportant = !showAll;
        loadSapTransactions(
            state.accountName,
            pageSize, pageIndex,
            sortPropertyName,
            sortProperty?.desc,
            onlyImportant,
            configuredFilters
        );
    }, [showAll, configuredFilters]);

    return (
        <ControlledTable
            title={title}
            columns={columns}
            data={data}
            filters={Filters}
            fetchData={fetchData}
            loading={isLoadingSapTransactions}
            pageCount={(Math.floor(state.sapTransactions.totalItems / state.sapTransactions.pageSize) + 1) ?? 0}
            total={state.sapTransactions.totalItems ?? 0}
            hideSortIcon={true}
            preRowActions={preRowActions}
        />
    )
}
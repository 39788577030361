import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import config from '../config.json';
export const reactPlugin = new ReactPlugin();
const snippet = {
    config: {
        instrumentationKey: config.insights.instrumentationKey,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: null }
        }
    }
}

let appInsights = null;

export const InsightContext = React.createContext(null);

const { Provider } = InsightContext;
export const InsightProvider = (props) => {
    const history = useHistory();

    useEffect(() => {
        if (config.insights.instrumentationKey) {
            snippet.config.extensionConfig.ReactPlugin.history = history;
            appInsights = new ApplicationInsights(snippet);
            appInsights.loadAppInsights();
            appInsights.addTelemetryInitializer((telemetryItem) => {
                telemetryItem.tags['ai.cloud.role'] = config.insights.CloudRoleName;
            });
        }
    }, [])

    const trackEvent = (name, data) => {
        if (appInsights) {
            appInsights.trackEvent({ name }, data);
        }
    }

    const trackError = (error, code) => {
        if (appInsights) {
            appInsights.trackException({
                exception: error,
                properties: code ? {
                    key: "error_code",
                    value: code
                } : null
            });
        }
    }

    return (
        <Provider value={{
            trackEvent,
            trackError
        }}>
            {props.children}
        </Provider>
    );
}
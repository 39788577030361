import React, { useContext, useEffect } from "react";
import { Grid, TextField, Button, Switch } from "@material-ui/core";
import { ApplicationContext } from "../../contexts";
import useStyles from "../../styles/useStyles";
import { KeyValueList, ProgressBar, Shimmer } from "../../ui-components";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Controller, useForm } from 'react-hook-form';
import { overrideJust } from "../../utils";

export const IAMDetailsForm = ({ editable, applicationId }) => {
    const {
        state,
        updateApplication,
        isUpdatingApplication,
        isLoadingApplication
    } = useContext(ApplicationContext);

    const defaultValues = state.application;

    const {
        handleSubmit,
        control,
        watch,
        reset
    } = useForm({ defaultValues });

    useEffect(() => {
        reset(defaultValues)
    }, [reset, defaultValues, isLoadingApplication])

    const onSubmit = async (formData) => {
        const overridingFields = [
            "isPublicationVisible",
            "publication",
            "isUserTypologiesVisible",
            "userTypologies",
            "isMasterDataVisible",
            "masterData",
            "isIdentityProviderVisible",
            "identityProvider",
            "isProfilingVisible",
            "profiling",
            "isVisibilityVisible",
            "visibility",
            "isVisibilityDetailsVisible",
            "visibilityDetails",
            "isKeyValueVisible",
            "keyValue",
            "isProvisioningVisible",
            "provisioning",
            "isProvisioningDetailsVisible",
            "provisioningDetails"
        ];
        const request = overrideJust(overridingFields, formData, defaultValues);
        await updateApplication(request);
    }

    const areThereValues = defaultValues && (
        defaultValues.isPublicationVisible ||
        defaultValues.isUserTypologiesVisible ||
        defaultValues.isMasterDataVisible ||
        defaultValues.isIdentityProviderVisible ||
        defaultValues.isProfilingVisible ||
        defaultValues.isVisibilityVisible ||
        defaultValues.isVisibilityDetailsVisible ||
        defaultValues.isKeyValueVisible ||
        defaultValues.isProvisioningVisible ||
        defaultValues.isProvisioningDetailsVisible
    );

    if (editable) return defaultValues ? renderContent() : <Shimmer variant="text" size={10} />
    return defaultValues && areThereValues && renderContent();

    function renderContent() {
        return <>
            {editable ?
                // admin part
                <form onSubmit={handleSubmit((onSubmit))}>
                    <Grid item sm={12}>
                        <ProgressBar loading={isUpdatingApplication || isLoadingApplication} />
                        <FieldVisibilityControl
                            control={control}
                            fieldName="publication"
                            switchName="isPublicationVisible"
                            fieldLabel="Publication"
                            watch={watch} />
                        <FieldVisibilityControl
                            control={control}
                            fieldName="userTypologies"
                            switchName="isUserTypologiesVisible"
                            fieldLabel="User Typologies"
                            watch={watch} />
                        <FieldVisibilityControl
                            control={control}
                            fieldName="masterData"
                            switchName="isMasterDataVisible"
                            fieldLabel="Master Data"
                            watch={watch} />
                        <FieldVisibilityControl
                            control={control}
                            fieldName="identityProvider"
                            switchName="isIdentityProviderVisible"
                            fieldLabel="Identity Provider"
                            watch={watch} />
                        <FieldVisibilityControl
                            control={control}
                            fieldName="profiling"
                            switchName="isProfilingVisible"
                            fieldLabel="Profiling"
                            watch={watch} />
                        <FieldVisibilityControl
                            control={control}
                            fieldName="visibility"
                            switchName="isVisibilityVisible"
                            fieldLabel="Visibility"
                            watch={watch} />
                        <FieldVisibilityControl
                            control={control}
                            fieldName="visibilityDetails"
                            switchName="isVisibilityDetailsVisible"
                            fieldLabel="Visibility Details"
                            watch={watch} />
                        <FieldVisibilityControl
                            control={control}
                            fieldName="keyValue"
                            switchName="isKeyValueVisible"
                            fieldLabel="Key Value"
                            watch={watch} />
                        <FieldVisibilityControl
                            control={control}
                            fieldName="provisioning"
                            switchName="isProvisioningVisible"
                            fieldLabel="Provisioning"
                            watch={watch} />
                        <FieldVisibilityControl
                            control={control}
                            fieldName="provisioningDetails"
                            switchName="isProvisioningDetailsVisible"
                            fieldLabel="Provisioning Details"
                            watch={watch} />
                        <Grid item sm={12} md={12}>
                            <center>
                                <Button
                                    style={{ marginTop: 20 }}
                                    color='primary'
                                    variant='contained'
                                    type='submit'
                                    disabled={isUpdatingApplication}
                                >
                                    Save
                                </Button>
                            </center>
                        </Grid>
                        <ProgressBar loading={isUpdatingApplication || isLoadingApplication} />
                    </Grid>
                </form>
                :
                <Grid container direction={"column"} spacing={2} sm={12} md={12}>
                    <KeyValueList
                        data={defaultValues}
                        labels={(() => {
                            let rows = [];
                            if (defaultValues.isPublicationVisible)
                                rows.push({ property: "publication", label: "Publication" });
                            if (defaultValues.isUserTypologiesVisible)
                                rows.push({ property: "userTypologies", label: "User Typologies" });
                            if (defaultValues.isMasterDataVisible)
                                rows.push({ property: "masterData", label: "Master Data" });
                            if (defaultValues.isIdentityProviderVisible)
                                rows.push({ property: "identityProvider", label: "Identity Provider" });
                            if (defaultValues.isProfilingVisible)
                                rows.push({ property: "profiling", label: "Profiling" });
                            if (defaultValues.isVisibilityVisible)
                                rows.push({ property: "visibility", label: "Visibility" });
                            if (defaultValues.isVisibilityDetailsVisible)
                                rows.push({ property: "visibilityDetails", label: "Visibility Details" });
                            if (defaultValues.isKeyValueVisible)
                                rows.push({ property: "keyValue", label: "Key Value" });
                            if (defaultValues.isProvisioningVisible)
                                rows.push({ property: "provisioning", label: "Provisioning" });
                            if (defaultValues.isProvisioningDetailsVisible)
                                rows.push({ property: "provisioningDetails", label: "Provisioning Details" });
                            return rows;
                        })()} />
                </Grid>
            }
        </>;
    }
};

const FieldVisibilityControl = ({ control, fieldName, fieldLabel, switchName, watch }) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            {watch(switchName) !== undefined &&
                <Grid className={classes.displayInRow} container item sm={12} md={12} spacing={2}>
                    <Grid item sm={12} md={11}>
                        <Controller
                            name={fieldName}
                            control={control}
                            rules={{ maxLength: 250 }}
                            render={({ field }) => <TextField
                                {...field}
                                variant="outlined"
                                margin="none"
                                size="small"
                                fullWidth
                                label={fieldLabel}
                            />}
                        />
                    </Grid>
                    <Grid item sm={12} md={1}>
                        <Controller
                            name={switchName}
                            control={control}
                            type="checkbox"
                            render={({ field }) => <FormControlLabel
                                label={field.value ? "Visible" : "Hidden"}
                                control={<Switch
                                    {...field}
                                    defaultChecked={field.value}
                                    checked={field.value}
                                    onChange={(_, data) => field.onChange(data)}
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    margin="none"
                                    fullWidth
                                />}
                            />}
                        />
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    )
}
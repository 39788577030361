import React, { useContext } from "react";
import { Switch, Route, useHistory, Redirect } from "react-router-dom";
// Material-UI imports
import Grid from "@material-ui/core/Grid";

// MSAL imports
import { MsalProvider, useIsAuthenticated, useMsal, useAccount } from "@azure/msal-react";

import { PageLayout } from "./ui-components";
import {
    Home,
    ForbiddenPage,
    AdminPage,
    ApplicationPage,
    ApplicationsPage,
    UserDetailsPage,
    RolePage,
    DashboardPage,
    SearchPage,
    DeviceDetailsPage,
    GroupDetailsPage,
    ProceduresPage,
    DownloadPage,
    SodAnalysisListPage,
    UniqueCodeClientAdvisorPage
} from "./pages";
import useStyles from "./styles/useStyles";
import { ApplicationProvider, UserProvider, UserContext, SearchProvider, InsightContext } from "./contexts"
import { ApplicationRoles, configureInterceptors, NavRoutes, CustomNavigationClient } from "./utils";
import ScrollToTop from "./ui-components/ScrollToTop";
import { SodAnalysisPage } from "./pages/admin/sod-analysis/SodAnalysisPage";
import {UniqueCodeUpdatePage } from "./pages/admin/uniqueCodeClientAdvisor/UniqueCodeUpdatePage";

function App({ pca }) {
    // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const history = useHistory();
    const { trackError } = React.useContext(InsightContext);

    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md
    // If you do not want to use a dedicated redirectUri for this purpose, you should instead ensure that your redirectUri is not attempting to call MSAL APIs when rendered inside the hidden iframe used by the silent APIs.
    if (!isInIframe()) {
        configureInterceptors(pca, history, trackError);
    }

    const navigationClient = new CustomNavigationClient(history);
    pca.setNavigationClient(navigationClient);
    const classes = useStyles();

    return (
        <div className={classes.mainContainer}>
            <MsalProvider instance={pca}>
                <UserProvider>
                    <ApplicationProvider>
                        <PageLayout>
                            <Grid container justifyContent="center">
                                <ScrollToTop />
                                <Pages />
                            </Grid>
                        </PageLayout>
                    </ApplicationProvider>
                </UserProvider>
            </MsalProvider>
        </div>
    );
}

export default App;

function isInIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

function Pages() {

    return (
        <Switch>
            <ProtectedRoute path={NavRoutes.UserDetailsTemplate}>
                <UserDetailsPage />
            </ProtectedRoute>
            <ProtectedRoute path={NavRoutes.DeviceDetailsTemplate}>
                <DeviceDetailsPage />
            </ProtectedRoute>
            <ProtectedRoute path={NavRoutes.GroupDetailsTemplate}>
                <GroupDetailsPage />
            </ProtectedRoute>
            <ProtectedRoute path={NavRoutes.RoleDetailsTemplateAdmin}>
                <RolePage editable />
            </ProtectedRoute>
            <ProtectedRoute path={NavRoutes.ApplicationTemplateAdmin}>
                <ApplicationPage editable />
            </ProtectedRoute>
            <ProtectedRoute path={NavRoutes.ApplicationListAdmin}>
                <ApplicationsPage />
            </ProtectedRoute>
            <ProtectedRoute path={NavRoutes.SodAnalysisTemplateAdmin}>
                <SodAnalysisPage />
            </ProtectedRoute>
            <ProtectedRoute path={NavRoutes.SodAnalysisListAdmin}>
                <SearchProvider>
                    <SodAnalysisListPage />
                </SearchProvider>
            </ProtectedRoute>
            <ProtectedRoute path={NavRoutes.UniqueCodeUpdateTemplateAdmin}>
                <SearchProvider>
                    <UniqueCodeUpdatePage/>
                </SearchProvider>
            </ProtectedRoute>
            <ProtectedRoute path={NavRoutes.UniqueCodeAdmin}>
                <SearchProvider>
                    <UniqueCodeClientAdvisorPage />
                </SearchProvider>
            </ProtectedRoute>
            <ProtectedRoute path={NavRoutes.RoleDetailsTemplate}>
                <RolePage />
            </ProtectedRoute>
            <ProtectedRoute path={NavRoutes.ApplicationTemplate}>
                <ApplicationPage editable={false} />
            </ProtectedRoute>
            <ProtectedRoute path={NavRoutes.Admin}>
                <AdminPage />
            </ProtectedRoute>

            <ProtectedRoute path={NavRoutes.Dashboard}>
                <DashboardPage />
            </ProtectedRoute>
            <ProtectedRoute path={NavRoutes.Search}>
                <SearchProvider>
                    <SearchPage />
                </SearchProvider>
            </ProtectedRoute>
            <ProtectedRoute path={NavRoutes.HomeTransactionTemplate}>
                <SearchProvider>
                    <SearchPage />
                </SearchProvider>
            </ProtectedRoute>
            <ProtectedRoute path={NavRoutes.Procedures}>
                <SearchProvider>
                    <ProceduresPage />
                </SearchProvider>
            </ProtectedRoute>
            <ProtectedRoute path={NavRoutes.DownloadTemplate}>
                <DownloadPage />
            </ProtectedRoute>

            <Route exact={true} path={NavRoutes.Home}>
                <SearchProvider>
                    <SearchPage />
                </SearchProvider>
            </Route>

            <Route path={NavRoutes.Forbidden}>
                <ForbiddenPage />
            </Route>
        </Switch>
    )
}

function ProtectedRoute(props) {
    const { inProgress } = useMsal();

    const isAuthenticated = useIsAuthenticated();

    const { state } = useContext(UserContext);
    const { applicationUser } = state;

    const isUserInRole = (role) => applicationUser.userAppSpecificRoles.some(x => x === role);


    const allowRoute = (path) => {
        if (inProgress !== "none" || !applicationUser)
            return true;
        if (!isAuthenticated)
            return false;
        if (applicationUser?.userAppSpecificRoles?.length > 0) {
            if (path.startsWith('/admin')) {
                return isUserInRole(ApplicationRoles.Admin);
            } else {
                return true;
            }
        }
        return false;
    }

    if (allowRoute(props.path)) {
        return <Route {...props} />;
    }

    return <Redirect to={NavRoutes.Forbidden} />;
}
import { useContext, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';

import { SearchContext } from '../../contexts';
import { searchCategories } from '../../utils';
import useStyles from '../../styles/useStyles';

import SearchCategoryButtons from './components/SearchCategoryButtons';
import { SearchCriteria } from './components/search-criteria';
import { SearchResult } from './components/search-result';

const SearchPage = (props) => {
    const {
        setSearchCategory
    } = useContext(SearchContext);

    useEffect(() => {
        setSearchCategory(searchCategories.ActiveDirectory);
    }, []);

    const classes = useStyles();

    return (
        <div className={classes.mainSectionWrapper}>
            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <SearchCategoryButtons />
                    </Grid>
                </Grid>
                <Grid container spacing={1} item xs={12} md={10} className={classes.criteriaContainer}  >
                    <Grid item xs={12}>
                        <SearchCriteria />
                    </Grid>
                    <Grid item xs={12}>
                        <SearchResult />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default SearchPage;
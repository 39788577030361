import React, { useMemo, useContext, useState, useEffect } from "react";
import { useTable, useGlobalFilter, useSortBy, usePagination } from "react-table";
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Switch from '@material-ui/core/Switch';
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableContainer from "@material-ui/core/TableContainer";
import { TablePaginationActions, TableToolbar } from "../../../ui-components/table";
import { ProgressBar } from "../../../ui-components";
import {
    Chip, IconButton, Tooltip, Typography, Grid, Accordion,
    AccordionDetails,
    AccordionSummary,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddBoxIcon from "@material-ui/icons/AddBox";
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { ApplicationContext } from "../../../contexts";
import { RoleFormDialog } from "./";
import { useHistory } from "react-router-dom";
import useStyles from "../../../styles/useStyles";
import { NavRoutes, CustomApplications } from "../../../utils";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


export const RoleTable = ({ editable, title }) => {
    const {
        state,
        isLoadingApplication,
        isRemovingAADRole,
        isAddingAADRole,
        removeAADRole,
        addAADRole,
        isUpdatingRole,
        updateRole,
        isLoadingSodActivities,
        toggleAppRolesTableClick
    } = useContext(ApplicationContext);

    const isLoading = isLoadingApplication
        || isRemovingAADRole
        || isAddingAADRole
        || isUpdatingRole
        || isLoadingSodActivities;
    const applicationId = state.application?.id;
    const fullyEditable = state.application?.owner === 1 ?? false;

    const data = useMemo(() => state.application?.roles ?? [], [state.application]);
    const columns = useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'id',
                // hidden column
            },
            {
                Header: 'Name',
                accessor: 'name'
            },
            {
                Header: 'AD Description',
                accessor: 'roleProviderDescription',
                Cell: ({ value }) =>
                    <Tooltip title={value ?? ""}>
                        <Typography style={{ fontSize: 14 }} noWrap>{value}</Typography>
                    </Tooltip>
            },
            {
                Header: 'Type',
                accessor: 'type',
                Cell: ({ value }) => (value === 0 ? "Custom" : "Active Directory"),
            },
            {
                Header: 'Display Name',
                accessor: 'displayName',
            },
            {
                Header: 'Description',
                accessor: 'description',
                Cell: ({ value }) =>
                    <Tooltip title={value ?? ""}>
                        <Typography style={{ fontSize: 14 }} noWrap>{value}</Typography>
                    </Tooltip>
            },
            {
                Header: 'SoD Activities',
                accessor: 'sodActivitiesInfo',
                Cell: ({ value: sodActivities }) => {
                    if (sodActivities && sodActivities.length > 0) {
                        return sodActivities.map(x =>
                            <Tooltip title={x.description ?? ""}>
                                <Chip label={x.value} variant="outlined" />
                            </Tooltip>
                        );
                    }
                    return "";
                }
            },
            {
                Header: 'Core',
                accessor: 'important',
                Cell: ({ value }) =>
                    <Switch
                        color="primary"
                        checked={value}
                        disabled
                    />
                // hidden column
            },
        ],
        []
    );

    let history = useHistory();
    const classes = useStyles();

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        gotoPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        setHiddenColumns,
        state: {
            pageIndex,
            pageSize,
            globalFilter
        },
    } = useTable(
        { columns, data },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    let hiddencolumns = ["id"];

    // only SAP can see the 'Core' column
    if (state.application?.name !== CustomApplications.Sap)
        hiddencolumns.push("important");


    if (state.application?.owner === 0) {
        hiddencolumns.push("roleProviderDescription");
        hiddencolumns.push("sodActivityId");
    }

    useEffect(() => {
        setHiddenColumns(hiddencolumns)
    }, [columns, state.application]);

    const page = rows.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);

    const totalItems = useMemo(() => rows.length, [rows]);

    const handleChangePage = (event, newPage) => {
        gotoPage(newPage);
    }

    const handleChangeRowsPerPage = event => {
        setPageSize(Number(event.target.value));
    }

    const [selectedRole, setSelectedRole] = useState(null);
    const handleRemoveRole = (roleId) => {
        removeAADRole(applicationId, roleId);
    }
    const handleAddRole = async values => {
        await addAADRole({
            id: values.role.id,
            applicationId: state.application.id,
            displayName: values.displayName,
            pbiRole: values.pbiRole,
            pbiAuthInfoObject: values.pbiAuthInfoObject,
            pbiAuthValue: values.pbiAuthValue,
            description: values.description,
            sodActivities: values.sodActivities.map(x => x.key)
        });
    }
    const handleEditRole = async (values, isPowerBI, previosRole, previosAuthInfoObject, previosAuthValue) => {
        await updateRole({
            id: values.role.id,
            applicationId: state.application.id,
            displayName: values.displayName,
            pbiRole: isPowerBI ? (values.pbiRole ?? previosRole) : "",
            pbiAuthInfoObject: isPowerBI ? (values.pbiAuthInfoObject ?? previosAuthInfoObject) : "",
            pbiAuthValue: isPowerBI ? (values.pbiAuthValue ?? previosAuthValue) : "",
            description: values.description,
            important: values.important,
            sodActivities: values.sodActivities?.map(x => x.key)
        });
    }

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleOpenForView = (role) => {
        editable ?
            history.push(NavRoutes.RoleDetailsAdmin(state.application.id, role.id)) :
            history.push(NavRoutes.RoleDetails(state.application.id, role.id));
    };

    const handleOpenForEdit = (role) => {
        setSelectedRole(role);
        return setDialogOpen(true);
    };
    const handleClose = () => {
        setSelectedRole(null);
        return setDialogOpen(false);
    };

    const handleAppRolesTableClick = () => {
        toggleAppRolesTableClick();
    };

    return (
        <Grid item sm={12} md={12}>
            <Accordion
                expanded={state.openAppRolesTable}
                onChange={handleAppRolesTableClick}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.padding_1} variant="h6">
                        <center>Roles</center>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    style={{ marginTop: -35 }}
                >
                    <RoleFormDialog
                        handleAdd={handleAddRole}
                        handleEdit={handleEditRole}
                        open={dialogOpen}
                        role={selectedRole}
                        handleClose={handleClose}

                    />
                    <TableContainer>
                        <TableToolbar
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            setGlobalFilter={setGlobalFilter}
                            globalFilter={globalFilter}
                            placeholderText="roles..."
                            title={" "}// empty string to avoid breaking layout 
                        >
                            {editable && fullyEditable &&
                                <Tooltip title="Add role to application">
                                    <IconButton
                                        aria-label="add-role"
                                        onClick={() => setDialogOpen(true)}>
                                        <AddBoxIcon />
                                    </IconButton>
                                </Tooltip>}
                        </TableToolbar>
                        <ProgressBar loading={isLoading} />
                        <MaUTable {...getTableProps()}>
                            <TableHead>
                                {headerGroups.map(headerGroup => (
                                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => {
                                            return (
                                                <TableCell {...column.getHeaderProps()}>
                                                    {column.render('Header')}
                                                </TableCell>
                                            );
                                        })}
                                        <TableCell>
                                            {/* empty header cell for actions column */}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableHead>
                            <TableBody {...getTableBodyProps()}>
                                {
                                    page.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <TableRow hover {...row.getRowProps()}>
                                                {
                                                    row.cells.map(cell =>
                                                        <TableCell {...cell.getCellProps()}>
                                                            {cell.render('Cell')}
                                                        </TableCell>
                                                    )
                                                }
                                                <TableCell key="actions">
                                                    {
                                                        editable &&
                                                        <IconButton aria-label="edit" disabled={isLoading} onClick={() => handleOpenForEdit(row.values)}>
                                                            <Tooltip title="Edit role">
                                                                <EditIcon />
                                                            </Tooltip>
                                                        </IconButton>
                                                    }
                                                    {(editable && fullyEditable) &&
                                                        <IconButton aria-label="delete" disabled={isLoading} onClick={() => handleRemoveRole(row.values.id)}>
                                                            <Tooltip title="Remove role from application">
                                                                <DeleteIcon />
                                                            </Tooltip>
                                                        </IconButton>
                                                    }
                                                    <IconButton aria-label="view" disabled={isLoading} onClick={() => handleOpenForView(row.values)}>
                                                        <Tooltip title="Role info">
                                                            <VisibilityIcon />
                                                        </Tooltip>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[
                                            10,
                                            25,
                                            50,
                                            { label: 'All', value: totalItems },
                                        ]}
                                        className={classes.tableFooter}
                                        count={totalItems}
                                        rowsPerPage={pageSize}
                                        page={pageIndex}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </MaUTable>
                    </TableContainer >
                </AccordionDetails>
            </Accordion>
        </Grid>

    )
}
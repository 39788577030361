import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { BlockMenu } from '../../ui-components/BlockMenu';
import { NavRoutes } from "../../utils";
import { ApplicationsPage } from './ApplicationsPage';
import AppsIcon from '@material-ui/icons/Apps';
import AssessmentIcon from '@material-ui/icons/Assessment';
import useStyles from '../../styles/useStyles';

export const AdminPage = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.mainSectionWrapper}>
            <Grid container spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="flex-start">
                <Grid container item xs={12}>
                    <Grid item xs={12} className={classes.mainHeader}>
                        <Typography className={classes.fontTitleBig}>
                            <center>ADMINISTRATION</center>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <BlockMenu menuItems={items} />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

const items = [
    {
        title: "Apps & roles",
        routePath: NavRoutes.ApplicationListAdmin,
        icon: <AppsIcon color="primary" />,
        isProtected: true,
        component: ApplicationsPage,
        isAdmin: true,
        description: "Here you can view and configure applications and their roles."
    },
    {
        title: "Transaction Requested / SoD Analysis",
        routePath: NavRoutes.SodAnalysisListAdmin,
        icon: <AssessmentIcon color="primary" />,
        isProtected: true,
        component: ApplicationsPage,
        isAdmin: true,
        description: "Here you can work on SoD Analyses."
    },
    {
        title: "Client Advisor Unique Code",
        routePath: NavRoutes.UniqueCodeAdmin,
        icon: <AssessmentIcon color="primary" />,
        isProtected: true,
        component: ApplicationsPage,
        isAdmin: true,
        description: "Here you can work on Client Advisor Unique Code."
    }
];
import React, { useContext, useEffect, useMemo, useState } from "react"
import { Button, ButtonGroup, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Paper, Typography } from "@material-ui/core";
import StoreIcon from '@material-ui/icons/Store';
import { styled } from "@material-ui/core/styles";
import GroupIcon from '@material-ui/icons/Group';
import { UserContext } from "../../../../../contexts";
import { Shimmer, KeyValueList, EmptyListItem } from "../../../../../ui-components"
import useStyles from "../../../../../styles/useStyles";
import { useBeanstore } from "./useBeanstore";

import { StatusIndicator } from '../../../../../ui-components';

const useLocalStyles = makeStyles((theme) => ({
    scrollablePaper: {
        maxHeight: 400,
        overflow: 'auto',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(0)
    }
}));

export const BeanstoreTabContent = () => {
    const classes = useStyles();

    const {
        isLoadingBeanstoreData
    } = useContext(UserContext);

    const {
        availableAreas,
        cashiers,
        sellers
    } = useBeanstore();

    const [area, setArea] = useState();

    useEffect(() => {
        const defaultArea = availableAreas?.find(x => !!x.enabled)?.text;
        setArea(defaultArea);
    }, [availableAreas])

    const areaCashiers = useMemo(() => (area && filterByArea(cashiers.data, area)) ?? [], [cashiers, area]);
    const areaSellers = useMemo(() => (area && filterByArea(sellers.data, area)) ?? [], [sellers, area]);

    const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
        marginBottom: 20,
        width: '100%',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'column',
            justifyContent: 'start'
        }
    }));

    const StyledButton = styled(Button)({
        background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
        color: "white",
        padding: "10px 30px",
        margin: "5px",
        borderRadius: '4px !important'
    });

    const StyledGridSmall = styled(Grid)(({ theme }) => ({
        [theme.breakpoints.up('md')]: {
            maxWidth: '10%',
            flexBasis: '10%'
        }
    }));

    return (
        <Grid container spacing={2} justifyContent="center">
            <StyledGridSmall item sm={12}>
                <StyledButtonGroup color="primary" variant="text">
                    {availableAreas.map((area) => (
                        <StyledButton disabled={!area.enabled} onClick={() => setArea(area.text)}>
                            {area.text}
                        </StyledButton>
                    ))}
                </StyledButtonGroup>
            </StyledGridSmall>

            {/* <Divider orientation="vertical" flexItem /> */}

            <Grid item sm={12} md={9} container spacing={3}>
                {areaCashiers.length > 0 && (
                    <Grid item sm={12}>
                        <Typography variant="h5">
                            Cashiers of {area?.toUpperCase()}
                        </Typography>
                    </Grid>
                )}
                {areaCashiers.length > 0 && areaCashiers.map(cashier => {
                    const distinctCashierRoles = [...new Map(cashier.cashierRoles?.map(item => [item["name"], item]))?.values()] ?? [];

                    return (
                        <React.Fragment>
                            <Grid item sm={12} md={6}>
                                <Paper className={classes.padding_3}>
                                    {isLoadingBeanstoreData
                                        ?
                                        <Shimmer variant="text" size={10} />
                                        :
                                        <>
                                            <StatusIndicator
                                                accountEnabled={!cashier.cashierSuspend}
                                                tooltip={cashier.cashierSuspend && 'Account suspended.'}
                                                label={'Cashier'}
                                            />

                                            <KeyValueList data={cashier} labels={cashiers.fields} />
                                        </>
                                    }
                                </Paper>
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Paper>
                                    <Typography className={classes.padding_3} variant="h6">
                                        {distinctCashierRoles.length
                                            ?
                                            <center>Roles ({distinctCashierRoles.length})</center>
                                            :
                                            <center>Roles</center>
                                        }
                                    </Typography>

                                    {isLoadingBeanstoreData
                                        ?
                                        <Shimmer variant="text" size={10} />
                                        :
                                        <CashierRoles data={distinctCashierRoles} emptyText="cashier has no roles" />
                                    }
                                </Paper>
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Paper>
                                    <Typography className={classes.padding_3} variant="h6">
                                        {cashier.cashierRoles.length
                                            ?
                                            <center>Stores ({cashier.cashierRoles.length})</center>
                                            :
                                            <center>Stores</center>
                                        }
                                    </Typography>

                                    {isLoadingBeanstoreData
                                        ?
                                        <Shimmer variant="text" size={10} />
                                        :
                                        <CashierStores data={cashier.cashierRoles} emptyText="cashier has no stores" />
                                    }
                                </Paper>
                            </Grid>
                            <Grid item sm={12}>
                                <Divider />
                            </Grid>
                        </React.Fragment>
                    );
                })}
                {areaSellers.length > 0 && (
                    <Grid item sm={12}>
                        <Typography variant="h5">
                            Salespersons of {area?.toUpperCase()}
                        </Typography>
                    </Grid>
                )}
                {areaSellers.length > 0 && areaSellers.map(seller => (
                    <React.Fragment>
                        <Grid item sm={12} md={6}>
                            <Paper className={classes.padding_3}>
                                {isLoadingBeanstoreData
                                    ?
                                    <Shimmer variant="text" size={10} />
                                    :
                                    <>
                                        <Typography variant="h6" component="div">
                                            Salesperson
                                        </Typography>
                                        <KeyValueList data={seller} labels={sellers?.fields} />
                                    </>
                                }
                            </Paper>
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <Paper>
                                <Typography className={classes.padding_3} variant="h6">
                                    <center>Stores</center>
                                </Typography>

                                {isLoadingBeanstoreData
                                    ?
                                    <Shimmer variant="text" size={10} />
                                    :
                                    <SellerStores seller={seller} emptyText="seller has no stores" />
                                }
                            </Paper>
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </Grid>
    );
}

const filterByArea = (items, area) => items.filter(x => x.area.toLowerCase() === area.toLowerCase());

const CashierRoles = ({ data, emptyText }) => {
    const classes = useStyles();
    const localClasses = useLocalStyles();

    const notEmpty = data && data.length > 0;
    return <div className={localClasses.scrollablePaper}>
        <List
            className={classes.padding_3}
            aria-labelledby="cashier roles"
        >
            {notEmpty > 0 ? data.map((x, i) => {
                return <>
                    <ListItem
                        key={i}
                    >
                        <ListItemIcon
                            key={i}
                        >
                            <GroupIcon />
                        </ListItemIcon>
                        <Grid item xs={12} sm={8}>
                            <ListItemText
                                classes={{ primary: classes.fontDescriptionMedium }}
                                primary={x.name} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <ListItemText
                                classes={{ primary: classes.fontDescriptionMedium }}
                                primary={x.displayName} />
                        </Grid>
                    </ListItem>
                    <Divider />
                </>;
            }) : <EmptyListItem text={emptyText} />
            }
        </List>
    </div>;
}

const CashierStores = ({ data, emptyText }) => {
    const classes = useStyles();
    const localClasses = useLocalStyles();

    const notEmpty = data && data.length > 0;

    return <div className={localClasses.scrollablePaper}>
        <List
            className={classes.padding_3}
            aria-labelledby="cashier stores"
        >
            {notEmpty > 0 ? data.map((x, i) => <>
                <ListItem
                    key={i}
                >
                    <ListItemIcon
                        key={i}
                    >
                        <StoreIcon />
                    </ListItemIcon>
                    <Grid item xs={12} >
                        <ListItemText
                            classes={{ primary: classes.fontDescriptionMedium }}
                            primary={x.store} />
                    </Grid>
                </ListItem>
                <Divider />
            </>) : <EmptyListItem text={emptyText} />
            }
        </List>
    </div>;
}

const SellerStores = ({ seller, emptyText }) => {
    const classes = useStyles();
    const localClasses = useLocalStyles();
    return <div className={localClasses.scrollablePaper}>
        <List
            className={classes.padding_3}
            aria-labelledby="cashier stores"
        >
            {seller ? <ListItem
                key="onlyitem"
                button
            >
                <ListItemIcon
                    key="onlyitem"
                >
                    <StoreIcon />
                </ListItemIcon>
                <Grid item xs={12} sm={4}>
                    <ListItemText primary={seller.salesPersonStoreCode} />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <ListItemText primary={seller.salesPersonStoreDes} />
                </Grid>
            </ListItem> : <EmptyListItem text={emptyText} />}
            <Divider />
        </List>
    </div>;
}
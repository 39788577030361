import React, { useEffect, useContext, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, TextField } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { FieldError } from "../../../ui-components/FieldError";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ApplicationContext } from "../../../contexts";
import { overrideJust } from '../../../utils';

const useStyles = makeStyles((theme) => ({
    container: {
        minWidth: 500
    }
}));

export function ApplicationFormDialog({ open, handleClose, application }) {
    const classes = useStyles();
    const editMode = application !== null;
    const isAADApplication = application?.owner === 1 ?? true;

    const {
        state,
        isLoadingAvailableAppOptions,
        addAADApplication,
        updateApplication
    } = useContext(ApplicationContext);

    const applicationMasterDatumId = application?.applicationMasterDatumId ?? 0;

    const defaultValues = useMemo(() => {
        const formData = {
            id: application?.id ?? 0,
            bringInAADGroups: false,
            applicationMasterDatum: state.availableApplications.find(x => x.key === applicationMasterDatumId),
            displayName: application?.displayName,
            isRunning: application?.isRunning
        };
        return { ...application, ...formData }
    }, [application, state.availableApplications, applicationMasterDatumId]);

    const { handleSubmit, control, reset, setValue, watch, formState: { errors } } = useForm({ defaultValues });

    useEffect(() => {
        if (application || open)
            reset(defaultValues);
    }, [application, open, defaultValues]);

    const handleAdd = async values => {
        await addAADApplication({ ...values });
    }

    const handleEdit = async (values) => {
        const overridingFields = ["isRunning", "displayName"]
        const request = overrideJust(overridingFields, values, application);
        await updateApplication(request);
    }

    const onSubmit = async values => {
        if (!editMode) {
            // we send only id to the api
            values.applicationMasterDatumId = values.applicationMasterDatum.key;
            await handleAdd(values);
            handleClose(true);
        }
        else
            handleEdit(values).then(() => handleClose(true));
    }

    const title = !application || isAADApplication ? "Configure Active Directory application" : "Configure custom application";

    return (
        <Dialog disableBackdropClick open={open} onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <form className={classes.container} onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Grid container>
                        {
                            !editMode &&
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field }) =>
                                        <Autocomplete
                                            {...field}
                                            options={state.availableApplications}
                                            getOptionLabel={option => option?.value ?? ""}
                                            getOptionSelected={(option, value) => option.key === value?.key}
                                            loading={isLoadingAvailableAppOptions}
                                            onChange={(e, option) => setValue('applicationMasterDatum', option)}
                                            renderInput={params =>
                                                <TextField
                                                    {...params}
                                                    autoFocus
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    label="Name *"
                                                />
                                            }
                                        />
                                    }
                                    name="applicationMasterDatum"
                                    rules={{ required: true }}
                                    control={control}
                                />
                                <FieldError error={errors?.applicationMasterDatum} customMessage="Name is required" />
                            </Grid>
                        }

                        {
                            !editMode &&
                            <Grid item xs={12}>
                                <Controller
                                    name="bringInAADGroups"
                                    control={control}
                                    defaultValue={false}
                                    render={({ field }) =>
                                        <FormControlLabel
                                            control={<Switch color="primary" {...field} />}
                                            label="Bring in AD Roles"
                                        />
                                    }
                                />
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <Controller
                                name="displayName"
                                control={control}
                                rules={{ maxLength: 100 }}
                                render={({ field }) =>
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="Display Name"
                                    />
                                }
                            />
                            <FieldError error={errors?.displayName} customMessage="Max length is 100" />
                        </Grid>

                        {
                            watch("isRunning") !== undefined &&
                            <Grid item xs={12}>
                                <Controller
                                    name="isRunning"
                                    control={control}
                                    type="checkbox"
                                    render={({ field }) =>
                                        <FormControlLabel
                                            label={field.value ? "Running" : "Stopped"}
                                            control={
                                                <Switch
                                                    {...field}
                                                    defaultChecked={field.value}
                                                    checked={field.value}
                                                    onChange={(_, data) => field.onChange(data)}
                                                    color="primary"
                                                    variant="outlined"
                                                    size="small"
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            }
                                        />
                                    }
                                />
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        type='submit'
                        color="primary"
                    >
                        Ok
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

import React, { useContext, useEffect, useState } from "react";
import {
    Grid,
    TextField,
    Button,
    Divider,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AccordionActions,
    MenuItem
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useForm, Controller } from 'react-hook-form';
import { FieldError } from "../../../../../ui-components/FieldError";
import { SearchContext } from '../../../../../contexts';
import _ from "lodash";
import useStyles from "../../../../../styles/useStyles";


const AppAndRolesCriteriaForm = (props) => {
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
        control
    } = useForm();
    const watchedApplication = watch("application", false);

    const {
        state,
        loadApplicationOptions,
        cleanApplicationOptions,
        loadRoleOptions,
        isLoadingRoleOptions,
        toggleOpenSearch
    } = useContext(SearchContext);

    const {
        applicationOptions,
        roleOptions
    } = state;


    const handleAccordionClick = () => {
        toggleOpenSearch();
    };

    // loads on mount once
    useEffect(() => {
        loadApplicationOptions();
        return () => {
            cleanApplicationOptions();
        }
    }, [])

    const [adSwitchEnabled, setAdSwitchEnabled] = useState(false);
    const handleOnApplicationChange = async (event, applicationId) => {
        event.persist();

        let selectedApplication = state.applicationOptions.find(x => x.key === applicationId);
        setAdSwitchEnabled(selectedApplication.owner === 0);
        await loadRoleOptions(applicationId);
    }

    const handleOnRoleTextChange = async (e, searchText) => {
        e && e.type !== "click" && await loadRoleOptions(watchedApplication, searchText);
    }
    const classes = useStyles();

    return (
        <form onSubmit={handleSubmit(props.onSubmit)}>
            <Accordion
                defaultExpanded
                expanded={state.openSearch}
                onChange={handleAccordionClick}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">
                        <center>Users by Application</center>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Typography className={classes.fontDescriptionMedium}>
                            <center>Search all Users authorized to an Application</center>
                        </Typography>
                        <Grid item xs={12} className={classes.searchFormItems}>
                            <TextField
                                select
                                size="small"
                                id="application"
                                label="Application *"
                                name="application"
                                variant="outlined"
                                margin="normal"
                                autoFocus
                                fullWidth
                                defaultValue=""
                                InputProps={{
                                    style: { fontSize: 14 }
                                }}
                                InputLabelProps={{
                                    style: { fontSize: 14 }
                                }}
                                {...register('application', { required: true })}
                                onChange={async e => {
                                    await handleOnApplicationChange(e, e.target.value);
                                    return setValue('application', e.target.value, { shouldValidate: true });
                                }}>
                                {applicationOptions.map((option) => (
                                    <MenuItem key={option.key} value={option.key}>
                                        {option.value}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {errors?.application &&
                            <Grid item xs={12} className={classes.searchFormItemsError}>
                                <FieldError error={errors?.application} customMessage="Application is required" />
                            </Grid>
                        }
                        <Grid item xs={12} className={classes.searchFormItems}>
                            <Autocomplete
                                {...register('role', { required: false })}
                                options={roleOptions.map(x => ({ key: x.id, value: x.name }))}
                                size="small"
                                getOptionLabel={option => option.value}
                                loading={isLoadingRoleOptions}
                                onInputChange={_.debounce(handleOnRoleTextChange, 600)}
                                onChange={(e, options) => setValue('role', options)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        autoFocus
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="role"
                                        label="Role"
                                        name="role"
                                        InputLabelProps={{
                                            style: { fontSize: 14 }
                                        }}
                                    />
                                )}
                            />

                        </Grid>
                        {errors?.role &&
                            <Grid item xs={12} className={classes.searchFormItemsError}>
                                <FieldError error={errors?.role} customMessage="Role is required" />
                            </Grid>
                        }
                        <Grid item xs={12} className={classes.searchFormItems}>
                            <TextField
                                size="small"
                                variant="outlined"
                                margin="normal"
                                {...register('firstName')}
                                fullWidth
                                id="firstName"
                                label="First Name"
                                name="firstName"
                                autoComplete="off"
                                InputProps={{
                                    style: { fontSize: 14 }
                                }}
                                InputLabelProps={{
                                    style: { fontSize: 14 }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.searchFormItems}>
                            <TextField
                                size="small"
                                variant="outlined"
                                margin="normal"
                                {...register('lastName')}
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="off"
                                InputProps={{
                                    style: { fontSize: 14 }
                                }}
                                InputLabelProps={{
                                    style: { fontSize: 14 }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.searchFormItems}>
                            <TextField
                                size="small"
                                variant="outlined"
                                margin="normal"
                                {...register('email')}
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                InputProps={{
                                    style: { fontSize: 14 }
                                }}
                                InputLabelProps={{
                                    style: { fontSize: 14 }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ padding: 10 }}>
                            <Controller
                                name="showOnlyAdAccounts"
                                control={control}
                                defaultValue={false}
                                render={({ field }) =>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                color="primary"
                                                disabled={!adSwitchEnabled}
                                                {...field} />}
                                        label={<Typography style={{ fontSize: 14 }}>Show only AD accounts</Typography>}
                                        style={{ fontSize: 14 }}
                                    />}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
                <Divider />
                <AccordionActions>
                    <Grid container justifyContent="center">
                        <Button
                            color='primary'
                            size='medium'
                            type='submit'
                            variant='contained'
                            disabled={props.isLoading}
                        >
                            SEARCH
                        </Button>
                    </Grid>
                </AccordionActions>
            </Accordion>
        </form>

    );
}

export default AppAndRolesCriteriaForm;
import React, { useContext, useMemo } from "react";
import { Chip, Grid, Paper, Typography } from "@material-ui/core";
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import GroupIcon from '@material-ui/icons/Group';
import { SoDActivityIcon, Shimmer } from "../../../../ui-components";
import { UserContext } from "../../../../contexts";
import DeepList from "../../../../ui-components/DeepList";
import { downloadUserSodActivities } from "../../../../utils";

export const SodActivitiesTabContent = () => {
    const {
        state,
        isLoadingUserSodActivities
    } = useContext(UserContext);
    const { sodActivities, user } = state;

    const uniqueSod = [...new Set(sodActivities.map(item => item.sodActivityId))];
    const sodActivityCounter = uniqueSod ? uniqueSod.length : 0;

    const sodActivitiyData = useMemo(() => getData(sodActivities), [sodActivities]);

    return <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
    >
        <Grid item sm={12} md={10}>
            <Paper>
                {isLoadingUserSodActivities ?
                    <Shimmer variant="text" size={12} />
                    :
                    <DeepList
                        title={<><b>SoD Activities</b> of {user.displayName}: {sodActivityCounter}</>}
                        data={sodActivitiyData}
                        download={() => downloadUserSodActivities(getDownloadData(sodActivities), user.accountName)}
                        emptyText="account has no SoD Activities"
                        renderItem={(item) => item.sodActivityDescription ? `${item.sodActivityName} - ${item.sodActivityDescription}` : item.sodActivityName}
                        renderChild={(child) => `Application: ${child.applicationDisplayName}`}
                        renderNiece={(niece) => {
                            const trxCount = niece.transactions?.length;
                            if (trxCount > 0) {
                                return <>
                                    <Typography>Role: '{niece.roleName}' with {trxCount} transactions:
                                        <br />
                                        {niece.transactions.map(t =>
                                            <Chip
                                                style={{ paddingRight: 5 }}
                                                label={t}
                                                variant="outlined"
                                                color="secondary"
                                                size="small" />
                                        )}
                                    </Typography>
                                </>
                            }
                            return <Typography>Role: '{niece.roleName}'</Typography>
                        }}
                    />}
            </Paper>
        </Grid>
    </Grid>;
}

function getDownloadData(sodActivities) {
    return {
        data: sodActivities.map(s => {
            const {
                applicationIcon,
                roleIcon,
                sodActivityIcon,
                ...rest } = s;
            rest.transactions = rest.transactions?.join(',');
            return rest;
        }),
        fields: [
            { property: "applicationDisplayName", label: "Application Display Name" },
            { property: "applicationId", label: "Application Id" },
            { property: "applicationName", label: "Application Name" },
            { property: "roleDescription", label: "Role Description" },
            { property: "roleDisplayName", label: "Role Display Name" },
            { property: "roleId", label: "Role Id" },
            { property: "roleName", label: "Role Name" },
            { property: "sodActivityDescription", label: "Sod Activity Description" },
            { property: "sodActivityId", label: "Sod Activity Id" },
            { property: "sodActivityName", label: "Sod Activity Name" },
            { property: "transactions", label: "Transactions" }
        ]
    }
}

function getData(sodActivities) {
    const structure = [
        {
            key: 'sodActivityId',
            name: 'sodActivityName',
            description: 'sodActivityDescription',
            icon: 'sodActivityIcon',
            data: 'items'
        },
        {
            key: 'applicationId',
            name: 'applicationDisplayName',
            description: 'applicationDescription',
            icon: 'applicationIcon',
            data: 'items'
        },
        {
            key: 'roleId',
            name: 'roleName',
            description: 'roleDescription',
            icon: 'roleIcon',
            transactions: 'transactions'
        }
    ];

    const data = sodActivities
        .map(x => {
            x.sodActivityIcon = SoDActivityIcon;
            x.applicationIcon = DesktopMacIcon;
            x.roleIcon = GroupIcon;
            return x;
        })
        .reduce(function (r, a) {
            var properties = structure.slice(),
                last = properties.pop(),
                lastKey = last.key,
                lastName = last.name,
                lastDesc = last.description,
                lastIcon = last.icon,
                transactions = last.transactions;

            properties.reduce(function (rr, b) {
                var o = {},
                    p = {},
                    key = b.key,
                    name = b.name,
                    desc = b.description,
                    icon = b.icon,
                    keyValue = a[key],
                    nameValue = a[name],
                    descValue = a[desc],
                    iconValue = a[icon],
                    array = b.data;

                if (!(keyValue in rr.obj)) {
                    o["id"] = keyValue;
                    o[name] = nameValue;
                    o[desc] = descValue;
                    o["icon"] = iconValue;
                    o[array] = [];
                    p[array] = o[array];
                    rr.obj[keyValue] = p;
                    rr.array.push(o);
                }
                return { array: rr.obj[keyValue][array], obj: rr.obj[keyValue] };
            }, r).array.push({
                id: a[lastKey],
                [lastName]: a[lastName],
                [lastDesc]: a[lastDesc],
                icon: a[lastIcon],
                transactions: a[transactions]
            });

            return r;
        }, { array: [], obj: {} }).array;

    return data ?? [];
}

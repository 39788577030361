import React, { useContext, useEffect, useMemo, useState, useCallback } from "react";
import {
    Grid, Typography, Box, Button, Modal, makeStyles, Accordion, AccordionDetails, AccordionSummary, Divider, alpha
} from "@material-ui/core";
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { ApplicationContext } from "../../contexts";
import { useParams } from "react-router";
import { RoleTable } from "./tables/RoleTable";
import useStyles from "../../styles/useStyles";
import { KeyValueList, Shimmer, ProgressBar } from "../../ui-components";
import Moment from 'moment';
import { Formats } from '../../utils';
import { ApplicationTabsLookup } from "../user-details/user-contents/applications/applicationTabsLookup";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ApplicationFormDialog } from "./tables";
import { AuthenticationForm } from "./AuthenticationForm";
import { ProcedureList } from '../common/ProcedureList';
import { IAMDetailsForm } from "./IAMDetailsForm";

const dateFormat = Formats.DateFormat;

const useLocalStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'auto',
        borderRadius: '4px'
    },
    appTitle: {
        textTransform: "uppercase",
        fontSize: "1.4rem",
        fontWeight: 500
    },
    button: {
        backgroundColor: "white",
        '&:hover': {
            backgroundColor: theme.palette.gray.light,
        },
    }
}));

function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        width: `70%`,
        height: `60%`
    };
}

export const ApplicationPage = ({ editable }) => {
    const {
        loadApplication,
        loadRunningApplication,
        isLoadingApplication,
        cleanApplication,
        state,
        loadApplicationFiles,
        cleanAppFiles,
        toggleOpenProceduresClick,
        toggleOpenAuthenticationsClick,
        toggleOpenIdentityClick,
        isLoadingProcedures
    } = useContext(ApplicationContext);

    const { id } = useParams();

    // running in admin context or not
    const loadData = editable ? loadApplication : loadRunningApplication;

    const [appDialogOpen, setAppDialogOpen] = useState(false);
    const handleCloseAppDialog = async (refresh) => {
        if (refresh === true) await loadData(id);
        return setAppDialogOpen(false);
    };

    useEffect(() => {
        async function fetchFromApi() {
            await loadData(id);
            if (id > 0)
                await loadApplicationFiles(id);
        }

        fetchFromApi();

        return () => {
            cleanApplication();
            cleanAppFiles();
        }
    }, [])

    const classes = useStyles();
    const localClasses = useLocalStyles();

    const clean = (str) => {
        const dirty = String(str);
        return dirty?.substring(dirty.indexOf("#") + 1, dirty.length).trim();
    };

    const prepareApplicationData = useCallback(
        (app) => {
            if (!app) return;
            const refreshRate = ApplicationTabsLookup
                .find(x => x.app === app.name)
                ?.refreshRate ?? "Synched with Active Directory";
            const lastIngestionUtc = app.lastIngestionUtc ?
                Moment(app.lastIngestionUtc).format(dateFormat) :
                "Synched with Active Directory";
            const { applicationMasterDatum, ...rest } = {
                ...app,
                ...app?.applicationMasterDatum,
                refreshRate,
                createDateUtc: Moment(app.createDateUtc).format(dateFormat),
                updateDateUtc: app.updateDateUtc && Moment(app.updateDateUtc).format(dateFormat),
                lastIngestionUtc: lastIngestionUtc,
                owner: app.owner === 0 ? "Custom" : "Active Directory",
                businessOwner: clean(app.applicationMasterDatum?.businessOwner),
                mainCompany: clean(app.applicationMasterDatum?.mainCompany),
                mainCompanyGeographicArea: clean(app.applicationMasterDatum?.mainCompanyGeographicArea),
                bulgariPortal: clean(app.applicationMasterDatum?.bulgariPortal),
                applicationScope: clean(app.applicationMasterDatum?.applicationScope),
                cloudApplication: clean(app.applicationMasterDatum?.cloudApplication),
                businessOwnerTeam: clean(app.businessOwnerTeam?.businessOwnerTeam)
            };
            return rest;
        },
        [],
    )

    const application = useMemo(() => prepareApplicationData(state.application), [prepareApplicationData, state.application]);
    const [appDockInfoOpen, setAppDockInfoOpen] = useState(false);
    const [appInfoOpen, setAppInfoOpen] = useState(false);
    const [modalStyle] = useState(getModalStyle);

    const handleOpenProceduresClick = () => {
        toggleOpenProceduresClick();
    };

    const handleOpenAuthenticationsClick = () => {
        toggleOpenAuthenticationsClick();
    };

    const handleOpenIdentityClick = () => {
        toggleOpenIdentityClick();
    };

    const showIAMDetails = (
        editable || (
            application?.isPublicationVisible ||
            application?.isUserTypologiesVisible ||
            application?.isMasterDataVisible ||
            application?.isIdentityProviderVisible ||
            application?.isProfilingVisible ||
            application?.isVisibilityVisible ||
            application?.isVisibilityDetailsVisible ||
            application?.isKeyValueVisible ||
            application?.isProvisioningVisible ||
            application?.isProvisioningDetailsVisible
        ));

    const showAuthDetails = editable || application?.authentication;

    return (
        <div className={classes.mainSectionWrapper}>
            <ApplicationFormDialog
                open={appDialogOpen}
                application={state.application}
                handleClose={handleCloseAppDialog}
            />
            <Grid container spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="flex-start">
                <Grid container item xs={12}>
                    <Grid item xs={12} className={classes.mainHeader}>
                        <Typography className={classes.fontTitleBig}>
                            <center>APPLICATION DETAILS</center>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item sm={12} md={8}>
                    <Box className={classes.padding_3}>
                        <Grid container item sm={12}>
                            <Grid container
                                item direction="row" alignItems="center" sm={12}>
                                <Grid item xs={8}>
                                    {isLoadingApplication ?
                                        <Shimmer variant="text" /> :
                                        <Typography className={localClasses.appTitle} variant="h6">{application?.displayName}</Typography>
                                    }
                                </Grid>
                                <Grid item container spacing={1} direction="row" justifyContent="flex-end" xs={4}>
                                    <Grid item >
                                        <Button
                                            label="open"
                                            size="small"
                                            aria-label="open"
                                            className={localClasses.button}
                                            onClick={() => setAppInfoOpen(true)}
                                            variant="outlined"
                                            disabled={isLoadingApplication}
                                            startIcon={<OpenInBrowserIcon />}
                                        >
                                            <Typography style={{ fontSize: '10px' }}>Application information</Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item >
                                        <Button
                                            label="open"
                                            size="small"
                                            aria-label="open"
                                            className={localClasses.button}
                                            onClick={() => setAppDockInfoOpen(true)}
                                            variant="outlined"
                                            disabled={isLoadingApplication || !application?.applicationMasterDatumId}
                                            startIcon={<OpenInBrowserIcon />}
                                        >
                                            <Typography style={{ fontSize: '10px' }}>AppDock details</Typography>
                                        </Button>
                                    </Grid>
                                    {editable &&
                                        <Grid item >
                                            <Button
                                                label="edit"
                                                size="small"
                                                aria-label="edit"
                                                className={localClasses.button}
                                                onClick={() => setAppDialogOpen(true)}
                                                variant="outlined"
                                                disabled={isLoadingApplication}
                                                startIcon={<OpenInBrowserIcon />}
                                            >
                                                <Typography style={{ fontSize: '10px' }}>Edit</Typography>
                                            </Button>
                                        </Grid>}
                                </Grid>
                            </Grid>
                            <Grid item sm={12}>
                                <Modal
                                    open={appDockInfoOpen}
                                    onClose={() => setAppDockInfoOpen(false)}
                                    aria-labelledby="user-ad-properties"
                                >
                                    <div style={modalStyle} className={localClasses.paper}>
                                        <Typography variant="h6">App Dock details for {application?.displayName}</Typography>
                                        <KeyValueList
                                            data={application}
                                            labels={[
                                                // episteme attributes
                                                { property: "isRunning", label: "Running" },
                                                { property: "description", label: "Description" },
                                                { property: "roleCount", label: "Total roles assigned" },
                                                { property: "refreshRate", label: "Data Update Rate" },
                                                { property: "name", label: "App Key" },
                                                { property: "createDateUtc", label: "Created at" },
                                                { property: "updateDateUtc", label: "Modified at" },
                                                { property: "owner", label: "Owner" },
                                                { property: "displayName", label: "Display Name" },
                                                { property: "lastIngestionUtc", label: "Last Data Update" }
                                            ]}
                                        />
                                    </div>
                                </Modal>
                                <Modal
                                    open={appInfoOpen}
                                    onClose={() => setAppInfoOpen(false)}
                                    aria-labelledby="user-ad-properties"
                                >
                                    <div style={modalStyle} className={localClasses.paper}>
                                        <Typography variant="h6">Details of {application?.displayName}</Typography>
                                        <KeyValueList
                                            data={application}
                                            labels={[
                                                // app dock attributes
                                                { property: "applicationType", label: "Application Type" },
                                                { property: "shortDescription", label: "Short Description" },
                                                { property: "longDescription", label: "Long Description" },
                                                { property: "url", label: "Url" },
                                                { property: "itOwnerDisplayName", label: "It Owner Display Name" },
                                                { property: "itOwnerEmail", label: "It Owner Email" },
                                                { property: "businessOwner", label: "Business Owner" },
                                                { property: "bulgariPortal", label: "Bulgari Portal" },
                                                { property: "applicationScope", label: "Application Scope" },
                                                { property: "mainCompany", label: "Main Company" },
                                                { property: "mainCompanyGeographicArea", label: "Main Company GeographicArea" },
                                                { property: "applicationHost", label: "Application Host" },
                                                { property: "cloudApplication", label: "Cloud Application" },
                                                { property: "createdAt", label: "Created At" },
                                                { property: "businessOwnerTeam", label: "Business Owner Team" }
                                            ]}
                                        />
                                    </div>
                                </Modal>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item sm={12} md={8}>
                    <Divider />
                </Grid>
                <Grid item xs={12} md={8}>
                    <RoleTable editable={editable} title="Roles" />
                </Grid>
                <Grid item xs={12} md={8}>
                    <Accordion
                        expanded={state.openProcedures}
                        onChange={handleOpenProceduresClick}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}>
                            <Typography className={classes.padding_1} variant="h6">
                                <center>Application Procedures</center>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ marginTop: -35 }}>
                            {isLoadingProcedures ?
                                <Shimmer variant="text" size={10} /> :
                                <ProcedureList
                                    editable={editable}
                                    useApplicationContext
                                    applicationId={id} />
                            }
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                {showIAMDetails &&
                    <Grid item xs={12} md={8}>
                        <Accordion
                            expanded={state.openIdentity}
                            onChange={handleOpenIdentityClick}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.padding_1} variant="h6">
                                    <center>Identity and Access Management Details of the Application</center>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                style={{ marginTop: -35 }}>
                                <Grid container item sm={12} className={classes.padding_3}>
                                    <Grid item sm={12}>
                                        {isLoadingApplication &&
                                            <ProgressBar loading={isLoadingApplication} />
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <IAMDetailsForm applicationId={id} editable={editable} />
                                    </Grid>
                                    <Grid item sm={12}>
                                        {isLoadingApplication &&
                                            <ProgressBar loading={isLoadingApplication} />
                                        }
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>}

                {showAuthDetails &&
                    <Grid item xs={12} md={8}>
                        <Accordion
                            expanded={state.openAuthentications}
                            onChange={handleOpenAuthenticationsClick}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.padding_1} variant="h6">
                                    <center>User Authorization Requirements</center>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                style={{ marginTop: -35 }}>
                                <Grid container item sm={12} className={classes.padding_3}>
                                    <Grid item sm={12}>
                                        {isLoadingApplication &&
                                            <ProgressBar loading={isLoadingApplication} />
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AuthenticationForm applicationId={id} editable={editable} />
                                    </Grid>
                                    <Grid item sm={12}>
                                        {isLoadingApplication &&
                                            <ProgressBar loading={isLoadingApplication} />
                                        }
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>}
            </Grid>
        </div>
    );
};
import { useState } from 'react';
import _ from 'lodash';
import { ApiRoutes, ApiClient } from "../../../utils";

export const useGroup = () => {
    const [group, setGroup] = useState();
    const [groupLoading, setGroupLoading] = useState(false);

    const handleGroup = async (params) => {
        setGroupLoading(true);

        ApiClient.post(`${ApiRoutes.Search.SearchGroupById()}?id=${params.id}`)
            .then((response) => {
                setGroup(response.data);
            })
            .catch((reason) => {
                setGroup(undefined);
            })
            .finally(() => {
                setGroupLoading(false);
            });
    }

    const [groupMembers, setGroupMembers] = useState([]);
    const [groupMembersLoading, setGroupMembersLoading] = useState(false);

    const handleGroupMembers = async (params) => {
        setGroupMembersLoading(true);

        ApiClient.get(`${ApiRoutes.Admin.GetRoleMembers({ roleId: params.id })}`)
            .then((response) => {
                setGroupMembers(_.filter(response.data, entry => !!entry.accountName));
            })
            .catch((reason) => {
                setGroupMembers([]);
            })
            .finally(() => {
                setGroupMembersLoading(false);
            });
    }
    const [groupChilds, setGroupChilds] = useState([]);
    const [groupChildsLoading, setGroupChildsLoading] = useState(false);

    const handleGroupChilds = async (group) => {
        setGroupChildsLoading(true);

        ApiClient.get(ApiRoutes.Admin.GetGroupChildsAsync({ groupId: group.id }))
            .then((response) => {
                setGroupChilds(_.filter(response.data, entry => !!entry.displayName));
            })
            .catch((reason) => {
                setGroupChilds([]);
            })
            .finally(() => {
                setGroupChildsLoading(false);
            });
    }

    return {
        handleGroup,
        group,
        groupLoading,
        handleGroupMembers,
        groupMembers,
        groupMembersLoading,
        handleGroupChilds,
        groupChilds,
        groupChildsLoading
    }
}
import React, { useContext } from 'react';

import {
    Avatar,
    Badge,
    Typography,
    Tooltip,
    makeStyles
} from '@material-ui/core';

import { UserContext } from '../../contexts';
import { DefaultValues } from '../../utils';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1)
        }
    },
    large: {
        width: theme.spacing(8),
        height: theme.spacing(8)
    },
    badgeSuccess: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        height: 14,
        minWidth: 14,
        borderRadius: '50%'
    },
    badgeError: {
        backgroundColor: '#e91e63',
        color: '#e91e63',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        height: 14,
        minWidth: 14,
        borderRadius: '50%'
    }
}));

export const UserAvatar = () => {
    const {
        state
    } = useContext(UserContext);

    const classes = useStyles();

    const profilePicture = state.user.profilePicture ?? DefaultValues.DefaultProfilePicture;
    const accountEnabled = state.user.accountDisabled === false && state.user.adLock === false;

    return (
        <div className={classes.root}>
            <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                    accountEnabled
                        ?
                        <div className={classes.badgeSuccess} />
                        :
                        <Tooltip
                            title={`Account ${!!state.user.accountDisabled ? 'disabled' : 'locked'}: attribute ${!!state.user.accountDisabled ? 'accountDisabled=true' : 'adLock=true'} ${!!state.user.adExpirationDate && `since  ${state.user.adExpirationDate}`}.`}
                            placement="right"
                        >
                            <div className={classes.badgeError} />
                        </Tooltip>
                }
            >
                <Avatar alt={state.user.displayName} src={profilePicture} className={classes.large} />
            </Badge>

            <Typography variant="h6">{state.user.displayName}</Typography>
        </div>
    );
}
import { Chip, IconButton, makeStyles, Paper, Tooltip, Typography } from "@material-ui/core";
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import React, { useMemo, useContext } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import { TableInfoRow, TablePaginationActions, TableToolbar } from "../../../../ui-components/table";
import { NavRoutes } from "../../../../utils";
import { SodAnalysis } from "../../../../utils/Constants";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { green, red } from '@material-ui/core/colors';
import { ApplicationContext } from '../../../../contexts';
import Moment from 'moment';
import { Formats } from '../../../../utils';
import PropTypes from 'prop-types';

const useLocalStyles = makeStyles((theme) => ({
    actionsWrapper: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(0.5),
        },
        alignItems: 'center',
        flexDirection: 'row'
    },
    searchLegendaTitle: {
        fontSize: 13,
        textAlign: 'center'
    },
    chip: {
        margin: 2,
        maxWidth: 170,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    tableFooter: {
        position: 'relative',
        right: '30%'
    },
    badgeSuccess: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        height: 14,
        minWidth: 14,
        borderRadius: '50%'
    },
    badgeError: {
        backgroundColor: '#e91e63',
        color: '#e91e63',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        height: 14,
        minWidth: 14,
        borderRadius: '50%'
    }
}));


const SodAnalysisTable = ({ title, hook: sodAnalysisHook }) => {

    const {
        sodAnalyses,
        sodAnalysesLoading
    } = sodAnalysisHook;

    const {
        downloadProcedureById
    } = useContext(ApplicationContext);

    const data = useMemo(() => sodAnalyses, [sodAnalyses]);
    const localClasses = useLocalStyles();
    const history = useHistory();

    const goToDetailsPage = (id) => history.push(NavRoutes.SodAnalysisAdmin(id));

    const downloadDetail = async (id) => {
        downloadProcedureById(id);
    }

    const columns = useMemo(
        () => [
            {
                accessor: 'id',
                Cell: linkCell(localClasses, goToDetailsPage)
            },
            {
                Header: 'Analyst',
                accessor: 'analystUser',
                Cell: userCell
            },
            {
                Header: 'Date ',
                accessor: 'createDateUtc',
                Cell: renderDateAsChips(
                    x => x
                )
            },
            {
                Header: 'User',
                accessor: 'user',
                Cell: userCell
            },
            {
                Header: 'Incident',
                accessor: 'incident'
            },
            {
                Header: 'Transaction SoD Relevant',
                accessor: 'transactionSodRelevant',
                Cell: semaphoreCell
            },
            {
                Header: 'New SoD Activity',
                accessor: 'newActivityFromTransaction',
                Cell: semaphoreCell
            },
            {
                Header: 'New Risks From Activities',
                accessor: 'newRiskFromActivities',
                Cell: semaphoreCell
            },
            {
                Header: 'New Risks',
                accessor: 'newRisks',
                Cell: renderAsChips(
                    x => x.description,
                    x => `${x.code} - ${x.description}`,
                    x => x.code)
            },
            {
                Header: 'Requested Transactions',
                accessor: 'selectedTransactions',
                Cell: renderAsChips(
                    x => x.sapCodeDescription,
                    x => `${x.transactionName} - ${x.sapCodeDescription}`,
                    x => x.transactionName)
            },
            {
                Header: 'TJPs',
                accessor: 'tjPs',
                Cell: renderAsChips(
                    x => x.roleName,
                    x => x.roleName,
                    x => x.roleName)
            },
            {
                Header: 'SoD Activities',
                accessor: 'sodActivities',
                Cell: renderAsChips(
                    x => x.description,
                    x => `${x.code} - ${x.description}`,
                    x => x.code)
            },
            {
                Header: 'Users Involved',
                accessor: 'usersInvolved'
            },
            {
                Header: 'Status',
                accessor: 'statusTypology',
                Cell: statusCell
            },
            {
                id: 'download',
                accessor: 'id',
                Cell: downloadCell(downloadDetail, localClasses)
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        gotoPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: {
            pageIndex,
            pageSize,
            globalFilter
        },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                hiddenColumns: []
            }
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const handleChangePage = (event, newPage) => {
        gotoPage(newPage);
    }

    const handleChangeRowsPerPage = event => {
        setPageSize(Number(event.target.value));
    }

    const totalItems = useMemo(() => rows.length, [rows]);

    return (
        <Paper>
            <TableContainer>
                <TableToolbar
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    setGlobalFilter={(aa) => setGlobalFilter(aa)}
                    globalFilter={globalFilter}
                    placeholderText="SoD Analysis..."
                    title={title}
                >
                    {/* <>
                        <Tooltip title="Export">
                            <Button
                                color="primary"
                                onClick={() => downloadSoDAnalysis(rows, columns)}
                                variant="contained"
                                disabled={!rows.length}
                                startIcon={<GetAppIcon />}
                            >Export</Button>
                        </Tooltip>
                    </> */}
                </TableToolbar>
                <MaUTable {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map(headerGroup => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <TableCell {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        <TableInfoRow loading={sodAnalysesLoading} total={data.length} />
                        {rows
                            .slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
                            .map((row, i) => {
                                prepareRow(row)
                                return (
                                    <TableRow hover {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <TableCell style={{ height: 12 }}{...cell.getCellProps()}>
                                                    {cell.render('Cell')}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[
                                    10,
                                    25,
                                    50,
                                    { label: 'All', value: totalItems },
                                ]}
                                className={localClasses.tableFooter}
                                count={totalItems}
                                rowsPerPage={pageSize}
                                page={pageIndex}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </MaUTable>
            </TableContainer>
        </Paper >
    )

    function renderDateAsChips(value) {
        return ({ value: data }) => {
            return <Tooltip title={Date}>
                <Chip className={localClasses.chip} label={Moment(data?.createDateUtc).format(Formats.DateFormat)} variant="outlined" />
            </Tooltip>
        }
    };

    function renderAsChips(tooltipSelector, valueSelector1, valueSelector2, maxNumberOfChips) {
        return ({ value: data }) => {
            if (data && data.length > 0) {
                const maxChips = maxNumberOfChips ?? 2;
                return data.slice(0, maxChips + 1)
                    .map((x, index) => {
                        if (index === maxChips)
                            return <Tooltip title="Open the SoD Activity to view all the items">
                                <Chip className={localClasses.chip} label={`+${data.length - maxChips}`} />
                            </Tooltip>
                        else
                            return tooltipSelector(x)
                                ?
                                <Tooltip title={tooltipSelector(x)}>
                                    <Chip className={localClasses.chip} label={valueSelector1(x)} variant="outlined" />
                                </Tooltip>
                                :
                                <Chip className={localClasses.chip} label={(valueSelector2?.(x)) || valueSelector1(x)} variant="outlined" />;
                    }
                    );
            }
            return "";
        };
    }
}

export default SodAnalysisTable;

const linkCell = (localClasses, goToDetailsPage) => {
    return ({ value: id }) => <div className={localClasses.actionsWrapper}>
        <Tooltip title="Open SoD Analysis">
            <span>
                <IconButton variant="link" aria-label="open" onClick={() => goToDetailsPage(id)}>
                    <VisibilityIcon />
                </IconButton>
            </span>
        </Tooltip>
    </div>;
}

const userCell = ({ value }) =>
    <Typography>
        {value?.displayName}
    </Typography>;

const downloadCell = (downloadDetail, localClasses) => {
    return ({ value: id }) => <div className={localClasses.actionsWrapper}>
        <Tooltip title="Download SoD Analysis">
            <span>
                <IconButton variant="link" aria-label="download" onClick={() => downloadDetail(id)}>
                    <GetAppIcon />
                </IconButton>
            </span>
        </Tooltip>
    </div>;
}

const semaphoreCell = ({ value }) => {
    if (value) {
        return <FiberManualRecordIcon style={{ color: red[500] }} />;
    } else {
        return <FiberManualRecordIcon style={{ color: green[500] }} />;
    }
};

const statusCell = ({ value }) => <Typography>
    {SodAnalysis.StatusTypology.find(x => x.key === value).value}
</Typography>;


SodAnalysisTable.propTypes = {
    title: PropTypes.any,
    hook: PropTypes.any,
};

linkCell.propTypes = {
    value: PropTypes.any,
};

import React, { useContext, useEffect, useMemo } from "react";
import useStyles from "../../styles/useStyles";
import { useLocation } from "react-router";
import { UserContext } from "../../contexts";
import SwipeableViews from 'react-swipeable-views';
import { AppBar, Box, Divider, makeStyles, Tab, Tabs, useTheme } from "@material-ui/core";
import { AADInfoTabContent, ApplicationsTabContent, SodActivitiesTabContent } from "./user-contents";
import { UserAvatar } from "./UserAvatar";

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useLocalStyles = makeStyles((theme) => ({
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
    fontTab: {
        fontSize: 12
    }
}));

export const UserDetailsPage = () => {
    const classes = useStyles();
    const localClasses = useLocalStyles();
    const theme = useTheme();

    const { search } = useLocation();
    const { userId, account, customApplication } = Object.fromEntries(new URLSearchParams(search));

    const {
        initUser,
        loadUser,
        isLoadingUser,
        loadUserMemberships,
        loadUserSodActivities,
        loadSapUser,
        loadBtraceUser,
        loadSalesforceUser,
        loadBeanstoreData,
        cleanup,
        tab,
        setTab,
        loadSapFilters,
        loadUserReports,
        state
    } = useContext(UserContext);

    useEffect(() => {
        initUser(userId, account);
    }, [userId, account])

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };

    const handleChangeIndex = (index) => {
        setTab(index);
    };

    useEffect(() => {
        loadUser(userId, account, customApplication);
        loadUserMemberships(userId, account, customApplication);
        loadUserSodActivities(userId, account, customApplication);
        loadSapUser(account);
        loadSapFilters();
        loadBeanstoreData(account);
        loadBtraceUser(account);
        loadSalesforceUser(account);

        return cleanup;
    }, []);

    const disableTabs = useMemo(() => !state.user?.id, [state.user])
    const disableActiveDirectoryTab = useMemo(() => !state.user?.isOnActiveDirectory, [state.user])
    useEffect(() => {
        if (disableActiveDirectoryTab && !isLoadingUser)
            setTab(1);
        return () => {
            setTab(0);
        }
    }, [disableActiveDirectoryTab, isLoadingUser]);

    useEffect(() => {
        if (state && state.memberships) {
            const powerBIReports = state.memberships.filter(x => x.isPowerBI);

            if (powerBIReports.length > 0) {
                const userReportsIds = powerBIReports.map(x => x.id);
                loadUserReports(userReportsIds);
            }
        }
    }, [state.memberships]);

    return (
        <div className={classes.mainSectionWrapper}>
            <AppBar position="static" color="default">
                <Tabs
                    value={tab}
                    onChange={handleChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab className={localClasses.fontTab} label="AD Account Details" {...a11yProps(0)} disabled={disableTabs || disableActiveDirectoryTab} />
                    <Tab className={localClasses.fontTab} label="Applications" {...a11yProps(1)} disabled={disableTabs} />
                    <Tab className={localClasses.fontTab} label="SoD Activities" {...a11yProps(2)} disabled={disableTabs} />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={tab}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={tab} index={0} dir={theme.direction}>
                    <AADInfoTabContent />
                </TabPanel>
                <TabPanel value={tab} index={1} dir={theme.direction}>
                    <UserAvatar />
                    <ApplicationsTabContent />
                </TabPanel>
                <TabPanel value={tab} index={2} dir={theme.direction}>
                    <UserAvatar />
                    <Divider className={localClasses.divider} />
                    <SodActivitiesTabContent />
                </TabPanel>
            </SwipeableViews>
        </div>
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full - width - tabpanel - ${index} `}
            aria-labelledby={`full - width - tab - ${index} `}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}
import React, { useContext, useMemo } from "react";
import { useTable, useGlobalFilter, useSortBy, usePagination } from "react-table";
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableContainer from "@material-ui/core/TableContainer";
import { TablePaginationActions, TableToolbar } from "../../../../../ui-components/table";
import { SearchContext } from "../../../../../contexts";
import { makeStyles, Paper, Tooltip, Typography, Chip, Button } from "@material-ui/core";
import GroupIcon from '@material-ui/icons/Group';
import { downloadUserSearchResults, NavRoutes, searchCategories, SapCriteriaSearchEntities } from "../../../../../utils";
import PersonIcon from '@material-ui/icons/Person';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import GetAppIcon from '@material-ui/icons/GetApp';

const useLocalStyles = makeStyles((theme) => ({
    displayName: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(0.5),
        },
        alignItems: 'center',
        flexDirection: 'row'
    },
    searchLegendaTitle: {
        fontSize: 13,
        textAlign: 'center'
    },
    chip: {
        padding: 5,
        margin: 3
    },
    tableFooter: {
        position: 'relative',
        right: '30%'
    },
    badgeSuccess: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        height: 14,
        minWidth: 14,
        borderRadius: '50%'
    },
    badgeError: {
        backgroundColor: '#e91e63',
        color: '#e91e63',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        height: 14,
        minWidth: 14,
        borderRadius: '50%'
    }
}));

export const UserTable = ({ title }) => {

    const { state } = useContext(SearchContext);
    const data = useMemo(() => state.users, [state.users]);
    const localClasses = useLocalStyles();

    const columns = useMemo(
        () => [
            {Header: 'hidden', accessor: 'hidden' },
            {
                Header: 'Name',
                accessor: 'displayName',
                Cell: ({ row, value }) => {
                    const { original: rowData } = row;
                    const accountEnabled = rowData.accountDisabled === false && rowData.adLock === false;

                    if (rowData.type === 1) {
                        return (
                            <div className={localClasses.displayName}>
                                <Tooltip title="Group">
                                    <GroupIcon />
                                </Tooltip>

                                <Typography noWrap style={{ color: 'inherit', textDecoration: 'inherit', fontSize: 14 }}>
                                    {value}
                                </Typography>
                            </div>
                        );
                    }

                    if (rowData.isOnActiveDirectory) {
                        return (
                            <div className={localClasses.displayName}>
                                <Link
                                    style={{ color: 'inherit' }}
                                    to={NavRoutes.UserDetails(rowData.id, rowData.accountName)}
                                >
                                    <Tooltip title="Account is on Active Directory">
                                        <PermIdentityIcon />
                                    </Tooltip>
                                </Link>

                                {accountEnabled
                                    ?
                                    <div className={localClasses.badgeSuccess} />
                                    :
                                    <Tooltip
                                        title={`Account ${!!rowData.accountDisabled ? 'disabled' : 'locked'}: attribute ${!!rowData.accountDisabled ? 'accountDisabled=true' : 'adLock=true'} ${!!rowData.adExpirationDate && `since  ${rowData.adExpirationDate}`}.`}
                                        placement="right"
                                    >
                                        <div className={localClasses.badgeError} />
                                    </Tooltip>
                                }

                                <Typography
                                    noWrap
                                    component={Link}
                                    style={{ color: 'inherit', textDecoration: 'inherit', fontSize: 14 }}
                                    to={NavRoutes.UserDetails(row.original.id, row.original.accountName)}
                                >
                                    {value}
                                </Typography>
                            </div>
                        );
                    }

                    return (
                        <div className={localClasses.displayName}>
                            <Link
                                style={{ color: 'inherit' }}
                                to={NavRoutes.UserDetails(rowData.id, rowData.accountName, rowData.customApplication)}
                            >
                                <Tooltip title="Account is not on Active Directory">
                                    <PersonIcon />
                                </Tooltip>
                            </Link>

                            <Typography
                                noWrap
                                component={Link}
                                style={{
                                    color: 'inherit',
                                    textDecoration: 'inherit',
                                    fontSize: 14
                                }}
                                to={NavRoutes.UserDetails(row.original.id, row.original.accountName)}
                            >
                                {value}
                            </Typography>
                        </div>
                    );
                }
            },
            {
                Header: 'Mail',
                accessor: 'mail'
            },
            {
                Header: 'Account',
                accessor: 'accountName'
            },
            {
                Header: 'Company',
                accessor: 'companyName'
            },
            {
                Header: 'Country',
                accessor: 'country'
            },
            {
                Header: 'Department',
                accessor: 'department'
            },
            {
                Header: 'id',
                accessor: 'id'
            },
            {
                Header: 'customApplication',
                accessor: 'customApplication'
            }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        gotoPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: {
            pageIndex,
            pageSize,
            globalFilter
        },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                hiddenColumns: ["id", "customApplication","hidden"]
            }
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const handleChangePage = (event, newPage) => {
        gotoPage(newPage);
    }

    const handleChangeRowsPerPage = event => {
        setPageSize(Number(event.target.value));
    }

    const totalItems = useMemo(() => rows.length, [rows]);

    const {
        appAndRolesSearchFilters,
        sapSearchFilters,
        adSearchMemberOf,
        adSearchFilters,
        searchCategory,
        applicationOptions
    } = state;

    let submittedFilters = [];

    switch (searchCategory) {
        case searchCategories.ActiveDirectory:
            if (adSearchFilters)
                submittedFilters = [
                    { value: adSearchFilters.account, label: "Account" },
                    { value: adSearchFilters.firstName, label: "First Name" },
                    { value: adSearchFilters.lastName, label: "Last Name" },
                    { value: adSearchFilters.email, label: "Email" },
                    { value: adSearchFilters.cid, label: "CID" },
                    { value: adSearchFilters.userId, label: "User ID" },
                    { value: adSearchFilters.company, label: "Company" },
                    { value: adSearchMemberOf.map(x => x.displayName), label: "Member Of" }
                ];
            break;
        case searchCategories.Sap:
            if (sapSearchFilters)
                submittedFilters = [
                    { value: SapCriteriaSearchEntities.find(x => x.key === sapSearchFilters.entityName).value, label: "Criteria" },
                    { value: sapSearchFilters.transactionCodes, label: "Transaction" },
                    { value: sapSearchFilters.entityId, label: "Name" },
                    { value: sapSearchFilters.description, label: "Short Description" }
                ];
            break;
        case searchCategories.AppAndRoles:
            if (appAndRolesSearchFilters)
                submittedFilters = [
                    { value: applicationOptions.filter(x => x.key === appAndRolesSearchFilters.applicationId).map(y => y.value), label: "Application" },
                    { value: appAndRolesSearchFilters.roleName, label: "Role" },
                    { value: appAndRolesSearchFilters.firstName, label: "First Name" },
                    { value: appAndRolesSearchFilters.lastName, label: "Last Name" },
                    { value: appAndRolesSearchFilters.email, label: "Email" },
                    { value: appAndRolesSearchFilters.description, label: "Application" },
                    { value: appAndRolesSearchFilters.showOnlyAdAccounts, label: "Show Only AD Accounts" }
                ];
            break;
        default: break;
    }

    const FilterSummaries = () => {
        return submittedFilters &&
            <FilterSummary filters={submittedFilters} />
    };

    return (
        <Paper>
            <TableContainer>
                <TableToolbar
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    setGlobalFilter={(aa) => setGlobalFilter(aa)}
                    globalFilter={globalFilter}
                    placeholderText="accounts..."
                    title={title}
                >
                    <Button
                        color="primary"
                        onClick={() => downloadUserSearchResults(rows, columns, submittedFilters, searchCategory)}
                        variant="contained"
                        disabled={!rows.length}
                        startIcon={<GetAppIcon />}
                    >Export</Button>
                </TableToolbar>
                <FilterSummaries />
                <MaUTable {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map(headerGroup => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <TableCell {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {rows
                            .slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
                            .map((row, i) => {
                                prepareRow(row)
                                return (
                                    <TableRow hover {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <TableCell style={{ height: 12 }}{...cell.getCellProps()}>
                                                    {cell.render('Cell')}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[
                                    10,
                                    25,
                                    50,
                                    { label: 'All', value: totalItems },
                                ]}
                                className={localClasses.tableFooter}
                                count={totalItems}
                                rowsPerPage={pageSize}
                                page={pageIndex}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </MaUTable>
            </TableContainer>
        </Paper >
    )
}

const FilterSummary = props => {
    const localClasses = useLocalStyles();

    return <Grid container xs={12} spacing={5} >
        <Grid item xs={6} md={2} >
            <Typography className={localClasses.searchLegendaTitle}>
                Submitted filters:
            </Typography>
        </Grid >
        <Grid spacing={2} item xs={6} md={9}>
            {props.filters?.length > 0 && props.filters.filter(x => x.value).map(x => {
                if (Array.isArray(x.value))
                    return x.value.map((item, index) => <Chip className={localClasses.chip} label={x.label + ": " + item} />);
                return <Chip className={localClasses.chip} label={x.label + ": " + x.value} />;
            })}
            {props.children}
        </Grid>
    </Grid>
}
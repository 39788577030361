import * as React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core';
import LoadingButton from './LoadingButton';

export default function DeleteWithConfirm({ handleDelete, loading, onDeleted, ...other }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = async () => {
        await handleDelete()
        handleClose();
        await onDeleted && onDeleted()
    }

    return (
        <>
            <IconButton
                color="danger"
                onClick={() => {
                    handleClickOpen();
                }}
                variant='contained'
                {...other}>
                <DeleteIcon />
            </IconButton >
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm delete"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        The items you selected is about to being deleted, are you sure?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        onClick={handleClose}
                        loading={loading}
                        color='primary'
                    >
                        Cancel
                    </LoadingButton>
                    <LoadingButton
                        onClick={handleConfirm}
                        loading={loading}
                        variant="contained"
                        color='primary'
                    >
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

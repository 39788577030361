import React, { useContext, useMemo } from 'react';

import { ApplicationContext } from '../../../../contexts';

import { ControlledTable } from '../../../../ui-components/table';
import { ProcedureList } from '../ProcedureList/procedure-list';

export const ApplicationTable = (props) => {
    const {
        state,
        isLoadingApplications,
        isAddingAADApplication,
        isRemovingAADApplication,
    } = useContext(ApplicationContext);

    const data = useMemo(() => state.applications.items ?? [], [state.applications.items]);
    const isLoading = isLoadingApplications || isAddingAADApplication || isRemovingAADApplication;

    return (
        <ControlledTable
            title={props.showTitle && "Application List"}
            columns={props.columns}
            data={data}
            fetchData={props.fetchData}
            loading={isLoading}
            pageCount={(Math.floor(state.applications.totalItems / state.applications.pageSize) + 1) ?? 0}
            total={state.applications.totalItems ?? 0}
            filters={props.filters}
            preRowActions={props.preRowActions}
            /**/
            expandable
            renderExpandedRowContent={(row) => (
                <ProcedureList horizontal applicationId={row.values.id} />
            )}
        />
    );
}
import React, { useContext, } from "react"
import { Divider, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Paper, Typography } from "@material-ui/core";
import GroupIcon from '@material-ui/icons/Group';
import { UserContext } from "../../../../../contexts";
import { Shimmer, KeyValueList, EmptyListItem } from "../../../../../ui-components"
import useStyles from "../../../../../styles/useStyles";
import { useSalesforce } from "./useSalesforce";
import { StatusIndicator } from '../../../../../ui-components';

const useLocalStyles = makeStyles((theme) => ({
    scrollablePaper: {
        maxHeight: 400,
        overflow: 'auto',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(0)
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'auto',
        borderRadius: '4px'
    },
}));

export const SalesforceTabContent = () => {
    const classes = useStyles();

    const {
        isloadingSalesforceUser
    } = useContext(UserContext);

    const salesforceData = useSalesforce();
    if (!salesforceData) return <></>

    const { user, profile, techInfo, salesAvisors } = salesforceData;

    return (
        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="flex-start">
            <Grid item sm={12} md={5}>
                <Paper className={classes.padding_3}>
                    {isloadingSalesforceUser
                        ?
                        <Shimmer variant="text" size={10} />
                        :
                        <>
                            <StatusIndicator
                                accountEnabled={user.data.isActive == 'true'}
                                tooltip={'Account Inactive.'}
                                label={'User Information'}
                            />

                            <KeyValueList data={user.data} labels={user.fields} />
                        </>
                    }
                </Paper>
            </Grid>
            <Grid item sm={12} md={5}>
                <Paper className={classes.padding_3}>
                    {isloadingSalesforceUser ? <Shimmer variant="text" size={10} /> : <>
                        <Typography variant="h6" component="div">
                            Profile
                        </Typography>
                        <KeyValueList data={profile.data} labels={profile.fields} />
                    </>}
                </Paper>
            </Grid>
            <Grid item sm={12} md={5}>
                <Paper className={classes.padding_3}>
                    {isloadingSalesforceUser ? <Shimmer variant="text" size={10} /> : <>
                        <Typography variant="h6" component="div">
                            Technical Information
                        </Typography>
                        <KeyValueList data={techInfo.data} labels={techInfo.fields} />
                    </>}
                </Paper>
            </Grid>
            <Grid item sm={1} md={5}></Grid>
            <Grid item sm={10}>
                <Paper className={classes.padding_3}>
                    {isloadingSalesforceUser ?
                        <Shimmer variant="text" size={10} />
                        :
                        <> <Typography variant="h6" component="div">
                            <center>SalesPerson Information</center>
                        </Typography>
                            <SalesAdvisorList
                                data={salesAvisors}
                                emptyText="account has no sales advisors"
                            />
                        </>}
                </Paper>
            </Grid>
        </Grid>
    );
}

const SalesAdvisorList = ({ data, emptyText }) => {
    const classes = useStyles();
    const localClasses = useLocalStyles();

    const notEmpty = data && data.length > 0;

    return <div className={localClasses.scrollablePaper}>
        <List
            className={classes.padding_3}
            aria-labelledby="sales advisor list"
        >
            {notEmpty > 0 ? data.map((x, i) => <>
                <ListItem
                    key={i}
                >
                    <ListItemIcon
                        key={i}
                    >
                        <GroupIcon />
                    </ListItemIcon>
                    <Grid item xs={12} sm={4} >
                        <ListItemText
                            classes={{ primary: classes.fontDescriptionMedium }}
                            primary={`ID: ${x.id}`} />
                        <ListItemText
                            classes={{ primary: classes.fontDescriptionMedium }}
                            primary={`Name: ${x.name}`} />
                    </Grid>
                    <Grid item xs={12} sm={4} >
                        <ListItemText
                            classes={{ primary: classes.fontDescriptionMedium }}
                            primary={`Company: ${x.company}`} />
                        <ListItemText
                            classes={{ primary: classes.fontDescriptionMedium }}
                            primary={`Beanstore ID: ${x.beanstoreId}`} />
                    </Grid>
                    <Grid item xs={12} sm={4} >
                        <ListItemText
                            classes={{ primary: classes.fontDescriptionMedium }}
                            primary={`Shop: ${x.shop}`} />
                        <ListItemText
                            classes={{ primary: classes.fontDescriptionMedium }}
                            primary={`Active: ${x.isActive}`} />
                    </Grid>
                </ListItem>
                <Divider />
            </>) : <EmptyListItem text={emptyText} />
            }
        </List>
    </div>;
}
import { forEach } from 'lodash';
import { utils as excelUtils, writeFile } from 'xlsx';
import { SodAnalysis,UniqueCodeStatus } from "./Constants";


export function downloadUserSearchResults(data, columns, filters, searchCategory) {
    filters.push({ label: "Search Category", value: searchCategory })

    // preparation
    const rows = data.map(r => r.values)
    const headers = columns.map(x => x.Header)

    // generate worksheet and workbook
    const resultsSheet = excelUtils.json_to_sheet(rows);
    const workbook = excelUtils.book_new();
    excelUtils.book_append_sheet(workbook, resultsSheet, "accounts");

    // fix headers
    excelUtils.sheet_add_aoa(resultsSheet, [headers], { origin: "A1" });

    // calculate column width
    const max_width = rows.reduce((w, v) => Math.max(w, v.length), 10);
    resultsSheet["!cols"] = [{ wch: max_width }];

    // append second worksheet for submitted filters
    const filtersSheet = excelUtils.json_to_sheet(filters.map(f => ({
        field: f.label,
        value: JSON.stringify(f.value)
    })));
    excelUtils.book_append_sheet(workbook, filtersSheet, "submitted filters");
    excelUtils.sheet_add_aoa(filtersSheet, [["Searched Value", "Field Name"]], { origin: "A1" });

    // create an XLSX file and try to save it
    writeFile(workbook, `accounts search results ${getFileNameSafeTimestamp()}.xlsx`);
};

export function excelExportFromMaUTable(data, columns, category, fileName, criteria = []) {
    criteria.push({ label: "Search Category", value: category })

    // preparation
    const rows = data.map(r => r.values)
    const headers = columns.map(x => x.Header)

    // generate worksheet and workbook
    const resultsSheet = excelUtils.json_to_sheet(rows);
    const workbook = excelUtils.book_new();
    excelUtils.book_append_sheet(workbook, resultsSheet, category);

    // fix headers
    excelUtils.sheet_add_aoa(resultsSheet, [headers], { origin: "A1" });

    // calculate column width
    const max_width = rows.reduce((w, v) => Math.max(w, v.length), 10);
    resultsSheet["!cols"] = [{ wch: max_width }];

    // append second worksheet for submitted filters
    const filtersSheet = excelUtils.json_to_sheet(criteria.map(f => ({
        field: f.label,
        value: JSON.stringify(f.value)
    })));
    excelUtils.book_append_sheet(workbook, filtersSheet, "submitted filters");
    excelUtils.sheet_add_aoa(filtersSheet, [["Searched Value", "Field Name"]], { origin: "A1" });

    // create an XLSX file and try to save it
    writeFile(workbook, `${fileName} ${getFileNameSafeTimestamp()}.xlsx`);
}

export function excelExportFromPanel(data, fields, category, fileName) {
    // generate worksheet and workbook
    const wb = excelUtils.book_new();

    const rows1 = data;
    const headers1 = fields;
    const sheet1 = excelUtils.json_to_sheet(Object.entries(rows1).map(x => {
        const label = headers1.find(y => y.property === x[0])?.label;
        return label ? [label, x[1]] : undefined;
    }).filter(x => !!x));
    excelUtils.book_append_sheet(wb, sheet1, category);
    excelUtils.sheet_add_aoa(sheet1, [["Property", "Value"]], { origin: "A1" });

    // create an XLSX file and try to save it    
    writeFile(wb, `${fileName} ${getFileNameSafeTimestamp()}.xlsx`);
}

export function downloadUserDetailsAD(data) {
    // generate worksheet and workbook
    const wb = excelUtils.book_new();

    const rows1 = data.accountData.data;
    const headers1 = data.accountData.fields;
    const sheet1 = excelUtils.json_to_sheet(Object.entries(rows1).map(x => {
        const label = headers1.find(y => y.property === x[0])?.label;
        return label ? [label, x[1]] : undefined;
    }).filter(x => !!x));
    excelUtils.book_append_sheet(wb, sheet1, "account");
    excelUtils.sheet_add_aoa(sheet1, [["Property", "Value"]], { origin: "A1" });

    if (data.applicationsData.data && data.applicationsData.data.length > 0) {
        const rows2 = data.applicationsData.data.map(x => {
            const { roles, ...rest } = x;
            return rest;
        });
        const headers2 = data.applicationsData.fields;
        const sheet2 = excelUtils.json_to_sheet(rows2);
        excelUtils.book_append_sheet(wb, sheet2, "applications");
        const sortingArray2 = Object.entries(rows2[0]).map(x => x[0]);
        const sortedHeaders2 = headers2.sort((a, b) => sortingArray2.indexOf(a.property) - sortingArray2.indexOf(b.property));
        excelUtils.sheet_add_aoa(sheet2, [sortedHeaders2.map(x => x.label)], { origin: "A1" });
    }

    if (data.adGroupsData.data && data.adGroupsData.data.length > 0) {
        const rows3 = data.adGroupsData.data.map(x => {
            const { sodActivities, pbiRole, pbiAuthInfoObject, pbiAuthValue,
                ...rest } = x;
            return rest;
        });
        const headers3 = data.adGroupsData.fields;
        const sheet3 = excelUtils.json_to_sheet(rows3);
        excelUtils.book_append_sheet(wb, sheet3, "groups");
        const sortingArray3 = Object.entries(rows3[0]).map(x => x[0]).filter(x =>
            x !== "pbiRole" ||
            x !== "pbiAuthInfoObject" ||
            x !== "pbiAuthValue");
        const sortedHeaders3 = headers3.sort((a, b) => sortingArray3.indexOf(a.property) - sortingArray3.indexOf(b.property));
        excelUtils.sheet_add_aoa(sheet3, [sortedHeaders3.map(x => x.label)], { origin: "A1" });
    }

    // create an XLSX file and try to save it
    writeFile(wb, makeAccountFileName(rows1.accountName, 'AD details'));
}

export async function downloadUserApplicationSap(sap, accountName) {
    const sapData = await sap.loadSapData(accountName);

    // sapUser: results[0],
    // sapAuthorizationObjects: results[1],
    // sapTransactions: results[2],
    // sapRoles: results[3],
    // sapFireFighters: results[4],
    // sapOrganizations: results[5],

    const wb = excelUtils.book_new();

    // sap user
    const rows1 = sapData.sapUser;
    const headers1 = [
        { property: "userType", label: "SAP Account Type" },
        { property: "userGroup", label: "UserGroup" },
        { property: "userLockStatus", label: "Lock Status" },
        { property: "creator", label: "Created by:" },
        { property: "creationDate", label: "Created on:" },
        { property: "lastLogonDate", label: "Last Logon Date" },
        { property: "lastPasswordLogonDate", label: "Last Logon Date by Password:" },
        { property: "roles", label: "Total of Roles" }
    ];
    const sheet1 = excelUtils.json_to_sheet(Object.entries(rows1).map(x => {
        const label = headers1.find(y => y.property === x[0])?.label;
        return label ? [label, x[1]] : undefined;
    }).filter(x => !!x));
    excelUtils.book_append_sheet(wb, sheet1, "SAP User");
    excelUtils.sheet_add_aoa(sheet1, [["Property", "Value"]], { origin: "A1" });

    // sap transactions
    const headers2 = [
        { label: 'Sap Role Id', property: 'sapRoleId' },
        { label: 'Technical Job Profile', property: 'sapRoleName' },
        { label: 'TCode', property: 'lowAuthorizationValue' },
        { label: "High", property: 'highAuthorizationValue' },
        { label: 'Transaction Short Description', property: 'tCodeMapDescription' },
        { label: 'SoD Activities', property: 'tCodeMapSodActivities' },
        { label: 'Usage Count', property: 'usageCount' }
    ];
    addSheetFromDataList(sapData.sapTransactions.items, headers2, wb, "SAP Transactions");

    // sap auth objects
    const headers3 = [
        { label: 'SapRoleId', property: 'sapRoleId', idx: 1 },
        { label: 'Role Name', property: 'sapRoleName', idx: 0 },
        { label: 'Authoritazion Object', property: 'authoritazionObject', idx: 2 },
        { label: 'Authorization Field Name', property: 'authorizationFieldName', idx: 3 },
        { label: 'Value', property: 'lowAuthorizationValue', idx: 4 },
        { label: "High", property: 'highAuthorizationValue', idx: 5 }
    ];
    addSheetFromDataList(sapData.sapAuthorizationObjects.items, headers3, wb, "SAP AuthorizationObjects", true);

    // sap roles
    const headers4 = [
        { label: 'Role Name', property: 'roleName' },
        { label: 'Validy to date', property: 'validityToDate' }
    ];
    addSheetFromDataList(sapData.sapRoles, headers4, wb, "SAP Roles");

    // sap firefighters
    const headers5 = [
        { label: 'Firefight ID', property: 'firefightID' },
        { label: 'Valid to date', property: 'validToDate' }
    ];
    addSheetFromDataList(sapData.sapFireFighters.items, headers5, wb, "SAP FireFighters");

    // sap organizations
    const headers6 = [
        { label: 'Sap Role Id', property: 'sapRoleId' },
        { label: 'Role Name', property: 'sapRoleName' },
        { label: 'Organization Levels', property: 'organizationalLevels' },
        { label: 'Value', property: 'lowAuthorizationValue' },
        { label: "High", property: 'highAuthorizationValue' },
    ];
    addSheetFromDataList(sapData.sapOrganizations.items, headers6, wb, "SAP Organizations");

    writeFile(wb, makeAccountFileName(accountName, 'SAP'));
}

export async function downloadUserApplicationBeanstore(data, accountName) {
    const wb = excelUtils.book_new();

    const rows1 = data.cashiers.data.map(x => {
        const {
            cashierRoles, salesPersonStoreCode, salesPersonStoreDes, userType, ...rest } = x;
        const distinctStores = [...new Set(x.cashierRoles?.map(r => r.store))];
        const distinctRoles = [...new Set(x.cashierRoles?.map(r => r.name))];
        return {
            ...rest,
            stores: distinctStores?.join("; "),
            roles: distinctRoles?.join("; ")
        };
    });
    const fields1 = data.cashiers.fields.concat([
        { property: "id", label: "Id" },
        { property: "area", label: "Area" },
        { property: "cashierKnownAs", label: "Known As" },
        { property: "cashierBlockedUser", label: "Blocked User" },
        { property: "cashierSuspend", label: "Suspend" },
        { property: "stores", label: "Stores" },
        { property: "roles", label: "Roles" }
    ]);
    addSheetFromDataList(rows1, fields1, wb, "Beastore Cashiers");

    const rows2 = data.sellers.data.map(x => {
        const {
            cashierRoles, userType, cashierKnownAs, cashierBlockedUser, cashierSuspend, ...rest } = x;
        const distinctStores = [...new Set(x.cashierRoles?.map(r => r.store))];
        const distinctRoles = [...new Set(x.cashierRoles?.map(r => r.name))];
        return {
            ...rest,
            stores: distinctStores?.join("; "),
            roles: distinctRoles?.join("; ")
        };
    });
    const fields2 = data.sellers.fields.concat([
        { property: "id", label: "Id" },
        { property: "area", label: "Area" },
        { property: "salesPersonStoreDes", label: "SalesPerson Store Description" },
        { property: "salesPersonStoreCode", label: "SalesPerson Store Code" },
        { property: "stores", label: "Stores" },
        { property: "roles", label: "Roles" },
    ]);
    addSheetFromDataList(rows2, fields2, wb, "Beastore Sellers");

    writeFile(wb, makeAccountFileName(accountName, 'Beanstore'));
}

export async function downloadUserApplicationBtrace(data, accountName) {
    const wb = excelUtils.book_new();

    // btrace user
    if (data.user.data) {
        const rows1 = data.user.data;
        const headers1 = data.user.fields;
        const sheet1 = excelUtils.json_to_sheet(Object.entries(rows1).map(x => {
            const label = headers1.find(y => y.property === x[0])?.label;
            return label ? [label, x[1]] : undefined;
        }).filter(x => !!x));
        excelUtils.book_append_sheet(wb, sheet1, "Btrace User");
        excelUtils.sheet_add_aoa(sheet1, [["Property", "Value"]], { origin: "A1" });
    }

    // btrace place
    if (data.place.data) {
        const rows2 = data.place.data;
        const headers2 = data.place.fields;
        const sheet2 = excelUtils.json_to_sheet(Object.entries(rows2).map(x => {
            const label = headers2.find(y => y.property === x[0])?.label;
            return label ? [label, x[1]] : undefined;
        }).filter(x => !!x));
        excelUtils.book_append_sheet(wb, sheet2, "Btrace Place");
        excelUtils.sheet_add_aoa(sheet2, [["Property", "Value"]], { origin: "A1" });
    }

    // btrace roles
    addSheetFromDataList(data.roles.data, ["Role Name"], wb, "Btrace Roles");

    writeFile(wb, makeAccountFileName(accountName, 'Btrace'));
}

export async function downloadUserApplicationPowerbi(data, accountName) {
    const wb = excelUtils.book_new();

    const rows = data.reports.data;
    const headers = data.reports.fields;
    const sheet = excelUtils.json_to_sheet(rows);
    excelUtils.book_append_sheet(wb, sheet, "PowerBI Reports");
    const sortingArray = Object.entries(rows[0]).map(x => x[0]);
    const sortedHeaders = headers.sort((a, b) => sortingArray.indexOf(a.property) - sortingArray.indexOf(b.property));
    excelUtils.sheet_add_aoa(sheet, [sortedHeaders.map(x => x.label)], { origin: "A1" });

    writeFile(wb, makeAccountFileName(accountName, 'PowerBI'));
}

export async function downloadUserApplicationSalesforce(data, accountName) {
    const wb = excelUtils.book_new();

    // SalesForce user
    const rows1 = data.user.data;
    const headers1 = data.user.fields;
    const sheet1 = excelUtils.json_to_sheet(Object.entries(rows1).map(x => {
        const label = headers1.find(y => y.property === x[0])?.label;
        return label ? [label, x[1]] : undefined;
    }).filter(x => !!x));
    excelUtils.book_append_sheet(wb, sheet1, "SalesForce User");
    excelUtils.sheet_add_aoa(sheet1, [["Property", "Value"]], { origin: "A1" });

    // SalesForce place
    const rows2 = data.profile.data;
    const headers2 = data.profile.fields;
    const sheet2 = excelUtils.json_to_sheet(Object.entries(rows2).map(x => {
        const label = headers2.find(y => y.property === x[0])?.label;
        return label ? [label, x[1]] : undefined;
    }).filter(x => !!x));
    excelUtils.book_append_sheet(wb, sheet2, "SalesForce Profile");
    excelUtils.sheet_add_aoa(sheet2, [["Property", "Value"]], { origin: "A1" });

    // SalesForce techinfo
    const rows3 = data.techInfo.data;
    const headers3 = data.techInfo.fields;
    const sheet3 = excelUtils.json_to_sheet(Object.entries(rows3).map(x => {
        const label = headers3.find(y => y.property === x[0])?.label;
        return label ? [label, x[1]] : undefined;
    }).filter(x => !!x));
    excelUtils.book_append_sheet(wb, sheet3, "SalesForce Tech Info");
    excelUtils.sheet_add_aoa(sheet3, [["Property", "Value"]], { origin: "A1" });

    // SalesForce sales persons
    const rows4 = data.salesAvisors;
    const sheet4 = excelUtils.json_to_sheet(rows4);
    excelUtils.book_append_sheet(wb, sheet4, "SalesForce Sales Avisors");
    excelUtils.sheet_add_aoa(sheet3, [], { origin: "A1" });

    writeFile(wb, makeAccountFileName(accountName, 'SalesForce'));
}

export function downloadUserSodActivities(sodActivities, accountName) {
    const wb = excelUtils.book_new();

    const rows = sodActivities.data;
    const headers = sodActivities.fields;
    const sheet = excelUtils.json_to_sheet(rows);
    excelUtils.book_append_sheet(wb, sheet, "SoD Activities");
    const sortingArray = Object.entries(rows[0]).map(x => x[0]);
    const sortedHeaders = headers.sort((a, b) => sortingArray.indexOf(a.property) - sortingArray.indexOf(b.property));
    excelUtils.sheet_add_aoa(sheet, [sortedHeaders.map(x => x.label)], { origin: "A1" });

    writeFile(wb, makeAccountFileName(accountName, 'SoD Activities'));
}

export function downloadSoDAnalysis(data, columns) {

    // preparation
    const rows = data.map(r => r.values)
    const headers = columns.map(x => x.Header)

    // generate worksheet and workbook
    const resultsSheet = excelUtils.json_to_sheet(getSodAnalysis(rows));
    const workbook = excelUtils.book_new();
    excelUtils.book_append_sheet(workbook, resultsSheet, "SoD Analysis");

    // fix headers
    excelUtils.sheet_add_aoa(resultsSheet, [headers], { origin: "A1" });

    // calculate column width
    const max_width = rows.reduce((w, v) => Math.max(w, v.length), 10);
    resultsSheet["!cols"] = [{ wch: max_width }];

    // create an XLSX file and try to save it
    writeFile(workbook, `accounts search results ${getFileNameSafeTimestamp()}.xlsx`);
};

export function downloadUniqueCodes(data, columns) {

    // preparation
    const rows = data.map(r => r.values)
    const headers = columns.map(x => x.Header)

    // generate worksheet and workbook
    const resultsSheet = excelUtils.json_to_sheet(getUniqueCode(rows));
    const workbook = excelUtils.book_new();
    excelUtils.book_append_sheet(workbook, resultsSheet, "Unique Codes Client Advisor");

    // fix headers
    excelUtils.sheet_add_aoa(resultsSheet, [headers], { origin: "A1" });

    // calculate column width
    const max_width = rows.reduce((w, v) => Math.max(w, v.length), 10);
    resultsSheet["!cols"] = [{ wch: max_width }];
 
    // create an XLSX file and try to save it
    writeFile(workbook, `Unique Codes Client Advisor ${getFileNameSafeTimestamp()}.xlsx`);
};

export function downloadGroupMembers(data, columns, groupName) {
    // preparation
    const rows = data.map(r => r.original).map(x => ({
        ObjectId: x.id,
        Account: x.accountName,
        Name: x.firstName + ' ' + x.lastName,
        Email: x.email,
        Enabled: x.enabled ? "false" : "true"
    }));

    // generate worksheet and workbook
    const resultsSheet = excelUtils.json_to_sheet(rows);
    const workbook = excelUtils.book_new();
    excelUtils.book_append_sheet(workbook, resultsSheet, "group members");

    // fix headers
    excelUtils.sheet_add_aoa(resultsSheet, [["Id", "Account", "Name", "Email", "Enabled"]], { origin: "A1" });

    // calculate column width
    const max_width = rows.reduce((w, v) => Math.max(w, v.length), 10);
    resultsSheet["!cols"] = [{ wch: max_width }];

    const fileName = `Group '${groupName}' members - ${getFileNameSafeTimestamp()}.xlsx`;

    writeFile(workbook, fileName);
}

function getFileNameSafeTimestamp() {
    return getFileNameSafeDate(new Date());
}

function getFileNameSafeDate(date) {
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - (offset * 60 * 1000));
    return date.toISOString().split('T')[0];
}

function makeAccountFileName(accountName, scope) {
    return `account '${accountName}' - ${scope} - ${getFileNameSafeTimestamp()}.xlsx`;
}

function addSheetFromDataList(rows, fields, wb, sheetName, customSort = false) {
    if (rows && rows.length > 0) {
        const sheet = excelUtils.json_to_sheet(rows);
        excelUtils.book_append_sheet(wb, sheet, sheetName);
        const sortingArray = Object.entries(rows[0]).map(x => x[0]);
        let sortedHeaders = fields.sort((a, b) => sortingArray.indexOf(a.property) - sortingArray.indexOf(b.property));
        if (customSort == true) {
            sortedHeaders = fields.sort((a, b) => a.idx - b.idx);
        }

        excelUtils.sheet_add_aoa(sheet, [sortedHeaders.map(x => x.label)], { origin: "A1" });
    }
}

function getSodAnalysis(data) {
    const rows = [];
    data.forEach(x => {
        var row = Object.assign({}, x);

        row.requestedTransactions = x.requestedTransactions?.map((y) => {
            if (y.description !== null)
                return y.code + " - " + y.description
            else
                return y.code
        }).join();

        row.userTypology = SodAnalysis.UserTypology?.find(t => t.key === x.userTypology).value;

        row.petitioners = x.petitioners?.map((y) => { return y.accountName }).join();

        row.tjPs = x.tjPs?.map((x) => { return x.description + " - " + x.name }).join();

        row.sodActivitiesAtAnalysisCreation = x.sodActivitiesAtAnalysisCreation?.map((y) => {
            if (y.description !== null)
                return y.code + " - " + y.description
            else
                return y.code
        }).join();

        row.newRisks = x.newRisks?.map((y) => {
            if (y.description !== null)
                return y.code + " - " + y.description
            else
                return y.code
        }).join();

        row.attachments = x.attachments?.length;

        rows.push(row);
    });
    return rows;
}

function getUniqueCode(data) {
    const rows = [];
    data.forEach(x => {
        var row = Object.assign({}, x);
        row.status = UniqueCodeStatus[row.status].value;
        row.updatingUser = row?.updatingUser;
        rows.push(row);
    });
    return rows;
}
import { useMsal } from "@azure/msal-react";
import Button from '@material-ui/core/Button';
import useStyles from "../../styles/useStyles";

export const SignOutButton = () => {
    const { instance } = useMsal();
    const style = useStyles();

    const handleLogout = (logoutType) => {
        if (logoutType === "popup") {
            instance.logoutPopup();
        } else if (logoutType === "redirect") {
            instance.logoutRedirect();
        }
    }

    return (
        <Button
            className={style.fontTitle}
            onClick={() => handleLogout("redirect")} key="logoutRedirect"
            color="inherit"
        >Logout
        </Button>
    )
};
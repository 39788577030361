import React, { useContext, useMemo, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useSnackbar } from 'notistack';
import Moment from 'moment';

import {
    Box,
    Button,
    Divider,
    Grid,
    Paper,
    Typography,
    Tooltip,
    useTheme
} from "@material-ui/core"
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import GroupIcon from '@material-ui/icons/Group';
import GetAppIcon from '@material-ui/icons/GetApp';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import InsertChartOutlinedOutlinedIcon from '@material-ui/icons/InsertChartOutlinedOutlined';

import { UserContext } from '../../../../contexts';
import { CustomApplications, Formats } from '../../../../utils';
import useStyles from '../../../../styles/useStyles';

import 'react-tabs/style/react-tabs.css';

import NestedList from '../../../../ui-components/NestedList';
import { Shimmer } from '../../../../ui-components';
import { ApplicationTabsLookup } from './applicationTabsLookup';

const dateFormat = Formats.DateFormat;
const sapDateFormat = Formats.SapDateFormat;

export const ApplicationsTabContent = () => {
    const {
        state,
        isLoadingUserMemberships,
        applicationTab,
        setApplicationTab
    } = useContext(UserContext);

    const {
        memberships
    } = state;

    const theme = useTheme();
    const classes = useStyles();

    const hasUserApplication = (applicationName) => {
        if (!isLoadingUserMemberships && registeredMemberships.length) {
            let isRegisteredApplication = registeredMemberships.some(x => x.applicationName === applicationName);

            //name not found (it could be Power BI)
            if (!isRegisteredApplication && applicationName === "" && registeredMemberships?.length) {
                registeredMemberships.forEach(application => {
                    if (application?.roles?.some(x => x.isPowerBI))
                        isRegisteredApplication = true;
                });
            }

            return isRegisteredApplication;
        }
    };

    const getDisplayName = item => {
        let nameResult = registeredMemberships
            .find(res => res.applicationName === item.app)?.applicationDisplayName
            ?? item.title;

        //in Constants.js only Power BI application doesn't have a name
        if (!nameResult && item.app === "" && registeredMemberships?.length) {
            registeredMemberships.forEach(application => {
                if (application?.roles?.some(x => x.isPowerBI))
                    nameResult = "Power BI";
            });
        }

        return nameResult;
    }

    const registeredMemberships = useMemo(() => getMemberships(memberships) ?? [], [memberships]);
    let membershipTabsLookup = useMemo(() => ApplicationTabsLookup.filter(x => hasUserApplication(x.app)) ?? [], [registeredMemberships, isLoadingUserMemberships]);
    membershipTabsLookup = addDateToMembership(membershipTabsLookup, registeredMemberships);

    function appToIndex(appTab) {
        const isPowerBIApplication = memberships?.filter(x => x.applicationName === appTab)?.[0]?.isPowerBI;

        //in Constants.js only Power BI application doesn't have a name
        const index = membershipTabsLookup.findIndex(x => x.app === (isPowerBIApplication ? "" : appTab));

        return index < 0 ? 0 : index + 1;
    }

    function indexToApp(ix) {
        if (ix === 0) setApplicationTab(0);
        else setApplicationTab(membershipTabsLookup[ix - 1].app)
    }

    const selectedIndex = appToIndex(applicationTab);

    return (
        <Tabs selectedIndex={selectedIndex} onSelect={index => indexToApp(index)}>
            <TabList>
                <Tab {...a11yProps(0)}>
                    <Typography className={classes.fontTitleMedium}>All Applications</Typography>
                </Tab>

                {membershipTabsLookup.map((item, index) => (
                    <Tab {...a11yProps(index)}>
                        <Typography className={classes.fontTitleMedium}>
                            {getDisplayName(item)}
                        </Typography>
                    </Tab>
                ))}
            </TabList>
            <TabPanel value={selectedIndex} index={0} dir={theme.direction}>
                <Box p={3}>
                    <Grid
                        container
                        spacing={3}
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >
                        <Grid item sm={12} md={10}>
                            <Paper>
                                <Typography className={classes.padding_3} variant="h6">
                                    {registeredMemberships.length
                                        ?
                                        <center>Memberships ({registeredMemberships.length})</center>
                                        :
                                        <center>Memberships</center>
                                    }
                                </Typography>

                                {isLoadingUserMemberships
                                    ?
                                    <Shimmer variant="text" size={10} />
                                    :
                                    <ApplicationList data={registeredMemberships} />
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </TabPanel>

            {membershipTabsLookup.map((item, index) => (
                <TabPanel value={selectedIndex} index={index} dir={theme.direction}>
                    <Box p={3}>
                        <Grid container justifyContent="center">
                            <Grid item sm={12} md={10}>
                                <Paper style={{ padding: 15 }}>
                                    <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                                        <Grid item>
                                            <ApplicationDataDownloadButton
                                                handleDownload={item.downloadCallback}
                                                useData={item.dataHook}
                                            />
                                        </Grid>

                                        {item.applicationLastUpdateDate && (
                                            <Grid item>
                                                <Tooltip title={'User Authorization Last Update: ' + Moment(item.applicationLastUpdateDate).local().format(
                                                    item.app === CustomApplications.Sap
                                                        ?
                                                        sapDateFormat
                                                        :
                                                        dateFormat)
                                                    + ' (Local Time Zone)'}>
                                                    <InfoOutlinedIcon fontSize="small" />
                                                </Tooltip>
                                            </Grid>
                                        )}

                                        {item.applicationLogsLastUpdateDate && (
                                            <Grid item>
                                                <Tooltip title={'Transaction Logs Last Update: ' + Moment(item.applicationLogsLastUpdateDate).local().format(sapDateFormat) + ' (Local Time Zone)'}>
                                                    <InsertChartOutlinedOutlinedIcon fontSize="small" />
                                                </Tooltip>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Paper>

                                <Divider style={{ margin: '15px 0' }} />
                            </Grid>
                        </Grid>

                        <item.component />
                    </Box>
                </TabPanel>
            ))
            }
        </Tabs >
    );
}

const ApplicationList = ({ data }) => {
    return (
        <NestedList
            data={data}
            emptyText="account has no memberships"
            renderItem={(item) => (
                <React.Fragment>
                    <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                        style={{ fontWeight: "bold" }}
                    >
                        {item.applicationDisplayName}
                    </Typography>
                    <Typography style={{ fontWeight: "bold" }} variant="body2">
                        {item.applicationDescription}
                    </Typography>
                </React.Fragment>
            )}
            getItems={(item) => item.roles}
            renderChild={(role) => (
                <React.Fragment>
                    <Typography variant="subtitle2">
                        {role.name}
                    </Typography>
                    <Typography variant="body2">
                        {role.description ?? role.roleProviderDescription}
                    </Typography>
                </React.Fragment>
            )}
        />
    );
}

const ApplicationDataDownloadButton = ({ handleDownload, useData }) => {
    const {
        state
    } = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const { user } = state;
    const [isDownloading, setIsDownloading] = useState();
    const data = useData();

    return <Button
        size="small"
        onClick={async () => {
            setIsDownloading(true);
            enqueueSnackbar("Download pending", { variant: "info" });
            await handleDownload(data, user.accountName, setIsDownloading);
            enqueueSnackbar("Download completed", { variant: "success" });
            setIsDownloading(false);
        }}
        variant="contained"
        disabled={isDownloading}
        startIcon={<GetAppIcon />}
    >Export</Button>;
}

function getMemberships(memberships) {
    let helper = {};

    return memberships
        .filter(x => x.isApplicationRegistered)
        .reduce(function (acc, value) {
            if (acc.length === undefined)
                acc = []; // why acc is not an array???
            const key = value.applicationId;
            value.icon = GroupIcon; // assign child (role) icon
            // Group initialization
            if (!helper[key]) {
                helper[key] = {
                    key: key,
                    applicationName: value.applicationName,
                    applicationDisplayName: value.applicationDisplayName,
                    applicationId: value.applicationId,
                    applicationDescription: value.applicationDescription,
                    applicationLastUpdateDate: value.applicationLastUpdateDate,
                    applicationLogsLastUpdateDate: value.applicationLogsLastUpdateDate,
                    type: value.type,
                    icon: DesktopMacIcon,
                    roles: [value]
                };
                acc.push(helper[key]);
            }
            else {
                // Grouping
                helper[key].roles.push(value);
            }

            return acc;
        }, {}) ?? [];
}

function addDateToMembership(userMemberships, allMemberships) {
    let result = userMemberships.map(obj => ({
        ...obj,
        applicationLastUpdateDate: allMemberships.find(x => x.applicationName === obj.app)?.applicationLastUpdateDate,
        applicationLogsLastUpdateDate: allMemberships.find(x => x.applicationName === obj.app)?.applicationLogsLastUpdateDate

    }));
    return result;
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
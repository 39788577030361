import { InteractionRequiredAuthError } from "@azure/msal-browser";
import axios from "axios";
import { loginRequest } from "../authConfig";
import { NavRoutes } from "./Constants";

// axios instance for making requests 
export const ApiClient = axios.create();

export const configureInterceptors = (msalInstance, history, trackError) => {

    // request interceptor for adding token
    ApiClient.interceptors.request.use(
        async (config) => {
            config.headers["x-api-challenge"] = localStorage.getItem("challenge") || "no-key-found";

            const token = await getToken(msalInstance, trackError);
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    ApiClient.interceptors.response.use(
        (response) => response,
        (error) => {
            console.debug(error);
            if (403 === error.response.status) {
                // history.push(NavRoutes.Forbidden);
            }
            else if (401 === error.response.status) {
                history.push(NavRoutes.Forbidden);
            } else {
                return Promise.reject(error);
            }
        }
    )
}

const getToken = async (msalInstance, trackError) => {
    const currentAccount = msalInstance.getActiveAccount();

    const silentRequest = {
        ...loginRequest,
        account: currentAccount,
        forceRefresh: false
    };

    const redirectRequest = {
        ...loginRequest,
        loginHint: currentAccount.username // For v1 endpoints, use upn from idToken claims.We are using v2.
    };

    const tokenResponse = await msalInstance.acquireTokenSilent(silentRequest).catch(error => {
        console.error('Failed acquireTokenSilent!');
        console.error(error);
        trackError(error, "Failed acquireTokenSilent");

        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return msalInstance.acquireTokenRedirect(redirectRequest);
        }
    });

    return tokenResponse.accessToken;
};

export const ApiRoutes = {
    Procedure: {
        GetProcedures: (params) => compileRoute("/api/Procedure/GetProcedures", params)
    },
    Admin: {
        GetApplications: (params) => compileRoute("/api/Admin/GetApplications", params),
        GetRunningApplications: (params) => compileRoute("/api/Admin/GetRunningApplications", params),
        GetApplication: (params) => compileRoute("/api/Admin/GetApplication", params),
        GetRunningApplication: (params) => compileRoute("/api/Admin/GetRunningApplication", params),
        RemoveAADRole: (params) => compileRoute("/api/Admin/RemoveAADRole", params),
        AddAADRole: () => "/api/Admin/AddAADRole",
        UpdateRole: () => "/api/Admin/UpdateRole",
        AddAADApplication: () => "/api/Admin/AddAADApplication",
        UpdateApplication: () => "/api/Admin/UpdateApplication",
        DeleteAADApplication: (params) => compileRoute("/api/Admin/DeleteAADApplication", params),
        GetRoleInfo: (params) => compileRoute("/api/Admin/GetRole", params),
        GetRoleMembers: (params) => compileRoute("/api/Admin/GetRoleMembers", params),
        GetGroupChildsAsync: (params) => compileRoute("/api/Admin/GetGroupChildsAsync", params)
    },
    Search: {
        SearchByAdCriteria: () => "/api/Search/SearchByAdCriteria",
        SearchByEntitiesCriteria: () => "/api/Search/SearchByEntitiesCriteria",
        SearchBySapTransactionCriteria: () => "/api/Search/SearchBySapTransactionCriteria",
        SearchByAppRolesCriteria: () => "/api/Search/SearchByAppRolesCriteria",
        GetTransactionOptions: (params) => compileRoute("/api/Search/GetTransactionOptions", params),
        GetApplicationOptions: () => "/api/Search/GetApplicationOptions",
        GetRoleOptions: (params) => compileRoute("/api/Search/GetRoleOptions", params),
        SearchGroupByName: (params) => compileRoute("/api/Search/SearchGroups", params),
        GetGroupsOptionsForApplication: (params) => compileRoute("/api/Search/GetGroupsOptionsForApplication", params),
        GetSodActivitiesOptions: (params) => compileRoute("/api/Search/GetSodActivitiesOptions", params),
        GetEntityOptions: (params) => compileRoute("/api/Search/GetEntityOptions", params),
        GetAvailableApplicationOptions: () => "/api/Search/GetAvailableApplicationOptions",
        GetPowerBIExtraFields: () => "/api/Search/GetPowerBIExtraFields",
        GetPowerBIReports: (params) => compileRoute("/api/Search/GetPowerBIReports", params),
        SearchGroupById: () => "/api/Search/SearchGroupById",
        SearchGroupsByAdCriteria: () => "/api/Search/SearchGroupsByAdCriteria",
        SearchDeviceById: () => "/api/Search/SearchDeviceById",
        SearchDevicesByAdCriteria: () => "/api/Search/SearchDevicesByAdCriteria",
        GetUserOptions: (params) => compileRoute("/api/Search/GetUserOptions", params)
    },
    User: {
        GetMe: () => "/api/User/GetMe",
        GetDetails: (params) => compileRoute("/api/User/GetDetails", params),
        GetProfilePicture: (params) => compileRoute("/api/User/GetProfilePicture", params),
        GetRunningMemberships: (params) => compileRoute("/api/User/GetRunningMemberships", params),
        GetSodActivities: (params) => compileRoute("/api/User/GetSodActivities", params),
        GetSapUser: (params) => compileRoute("/api/User/sap/GetUser", params),
        ListSapTransactions: () => "/api/User/sap/ListTransactions",
        ListSapAuthorizationObjects: () => "/api/User/sap/ListAuthorizationObjects",
        ListSapOrganizations: () => "/api/User/sap/ListOrganizations",
        ListSapFireFighters: () => "/api/User/sap/ListFireFighters",
        ListSapRoles: (params) => compileRoute("/api/User/sap/ListRoles", params),
        GetSapPropertyFilters: () => "/api/User/sap/GetPropertyFilters",
        GetBeanstoreUserAccounts: (params) => compileRoute("/api/User/beanstore/GetAccounts", params),
        GetBtraceUser: (params) => compileRoute("/api/User/btrace/GetUser", params),
        GetBtraceUserRoles: (params) => compileRoute("/api/User/btrace/GetUserRoles", params),
        GetSalesforceUser: (params) => compileRoute("/api/User/salesforce/GetUser", params),
    },
    File: {
        UploadApplicationFile: (params) => compileRoute("/api/File/AppendApplicationFile", params), //post
        DownloadFile: (params) => compileRoute("/api/File/GetFile", params), //get  
        GetApplicationFiles: (params) => compileRoute("/api/File/GetApplicationFiles", params), //get
        DeleteApplicationFile: (params) => compileRoute("/api/File/DeleteApplicationFile", params), //delete
        AppendSodAnalysisFiles: (params) => compileRoute("/api/File/AppendSodAnalysisFiles", params), //post
        DeleteSodAnalysisFile: (params) => compileRoute("/api/File/DeleteSodAnalysisFile", params), //delete
        SetVisibility: (params) => compileRoute("/api/File/SetVisibility", params), //put
    },
    Kpi: {
        GetETypeReports: () => "/api/Kpi/GetETypeReports",
    },
    SodAnalysis: {
        RestResource: (id) => id ? `/api/SodAnalysis/${id}` : "/api/SodAnalysis",
        Attachments: (id) => `/api/SodAnalysis/${id}/attachments`,
        GetNewSoDAnalysis: () => "/api/SodAnalysis/GetNewSoDAnalysis",
        UpdateSodAnalysisStatus: () => "/api/SodAnalysis/UpdateSodAnalysisStatus",
        GetManagersEmail: () => "/api/SodAnalysis/GetManagersEmail",
        GetCompany: () => "/api/SodAnalysis/GetCompany",
        GetInvolvedUsers: (params) => compileRoute("/api/SodAnalysis/GetInvolvedUsers", params),
    },
    UniqueCodes: {
        RestResource: (id) => id ? `/api/BeanstoreUniqueCodes/${id}` : "/api/BeanstoreUniqueCodes",
        DeleteBeanstoreUniqueCodes: (params) => compileRoute("/api/BeanstoreUniqueCodes/DeleteBeanstoreUniqueCodes", params),
        UpsertUniqueCode: () => "/api/BeanstoreUniqueCodes/UpsertUniqueCode"
    }
}

const compileRoute = (route, params) => {
    if (params) {
        const props = Object.getOwnPropertyNames(params);
        if (props && props.length > 0) {
                route = `${route}?`
                props.filter(x => params[x] !== null && params[x] !== undefined).forEach(prop => route = `${route}${prop}=${params[prop]}&`);
                route = route.substr(0, route.length - 1);
        }
    }
    return route;
}

import { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Switch,
    Tooltip,
    Typography
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { ApplicationContext } from '../../contexts';
import { NavRoutes } from '../../utils';
import useStyles from '../../styles/useStyles';

import { ProgressBar, SimplifiedFilter } from '../../ui-components';
import { ApplicationTable } from './components/ApplicationTable/application-table';

const ProceduresPage = (props) => {
    const {
        loadRunningApplications
    } = useContext(ApplicationContext);

    const [applicationFilter, setApplicationFilter] = useState();
    const [withAnyProcedures, setWithAnyProcedures] = useState(false);

    const fetchApplicationData = useCallback(({ pageIndex, pageSize }) => {
        loadRunningApplications(pageSize, pageIndex, applicationFilter, withAnyProcedures);
    }, [applicationFilter, withAnyProcedures]);

    const history = useHistory();

    const preRowActions = (row) => (
        <IconButton
            variant="link"
            edge="end"
            onClick={() => history.push(NavRoutes.Application(row.values.id))}
        >
            <Tooltip title="View application details">
                <VisibilityIcon />
            </Tooltip>
        </IconButton>
    );

    const Filters = () => (
        <Grid container justifyContent="space-between">
            <FormControlLabel
                control={
                    <Switch
                        color="primary"
                        checked={withAnyProcedures}
                        onChange={(_, value) => setWithAnyProcedures(value)}
                    />
                }
                label="Applications with procedures only"
            />

            <SimplifiedFilter onSubmit={(value) => setApplicationFilter(value)} />
        </Grid>
    );

    const columns = useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'id',
                show: false
            },
            {
                Header: 'ApplicationMasterDatum',
                accessor: 'applicationMasterDatum',
                show: false
            },
            {
                Header: 'Display Name',
                accessor: 'displayName'
            },
            {
                Header: 'Description',
                accessor: 'description',
                Cell: ({ value }) => (
                    <Tooltip title={value ?? ""}>
                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", width: '12rem' }}>
                            <Typography className={classes.fontDescriptionBig} noWrap>{value}</Typography>
                        </div>
                    </Tooltip>
                )
            },
            {
                Header: 'Roles',
                accessor: 'roleCount',
            }
        ],
        []
    );

    const classes = useStyles();

    return (
        <div className={classes.mainSectionWrapper}>
            <Grid container justifyContent="center" className={classes.criteriaContainer}>
                <Grid item xs={12} md={10} className={classes.mainHeader}>
                    <Typography variant="h5">
                        <center>Application List</center>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                    <ProgressBar loading={false} />
                    <Paper>
                        <ApplicationTable
                            preRowActions={preRowActions}
                            fetchData={fetchApplicationData}
                            filters={Filters}
                            columns={columns}
                        />
                    </Paper>
                    <ProgressBar loading={false} />
                </Grid>
            </Grid>
        </div>
    );
}

export default ProceduresPage;
import React, { useContext, useMemo, useEffect } from "react";
import { useTable, useGlobalFilter, useSortBy, usePagination } from "react-table";
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableContainer from "@material-ui/core/TableContainer";
import { TablePaginationActions, TableToolbar } from "../../../ui-components/table";
import { ApplicationContext } from "../../../contexts";
import { makeStyles, Paper } from "@material-ui/core";
import useStyles from "../../../styles/useStyles";


const useLocalStyles = makeStyles((theme) => ({
    displayName: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
        alignItems: 'center',
        flexDirection: 'row'
    },
    searchLegendaTitle: {
        fontSize: 13,
        textAlign: 'center'
    },
    chip: {
        padding: 5,
    }
}));

export const MembersTable = ({ title }) => {
    const {
        state
    } = useContext(ApplicationContext);

    const data = useMemo(() => state.roleMembers, [state.roleMembers]);
    const localClasses = useLocalStyles();

    const columns = useMemo(
        () => [
            {
                Header: 'Account',
                accessor: 'accountName'
            },
            {
                Header: 'Name',
                accessor: 'firstName'
            },
            {
                Header: 'Surname',
                accessor: 'lastName'
            },
            {
                Header: 'id',
                accessor: 'id'
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        gotoPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: {
            pageIndex,
            pageSize,
            globalFilter },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                hiddenColumns: ["id"]
            }
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const totalItems = useMemo(() => rows.length, [rows]);

    const handleChangePage = (event, newPage) => {
        gotoPage(newPage);
    }

    const handleChangeRowsPerPage = event => {
        setPageSize(Number(event.target.value));
    }
    const classes = useStyles();

    return (
        <Paper>
            <TableContainer>
                <TableToolbar
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    setGlobalFilter={setGlobalFilter}
                    globalFilter={globalFilter}
                    placeholderText="accounts..."
                    title={title}
                />
                <MaUTable {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map(headerGroup => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <TableCell {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {rows
                            .slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
                            .map((row, i) => {
                                prepareRow(row)
                                return (
                                    <TableRow hover {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <TableCell {...cell.getCellProps()}>
                                                    {cell.render('Cell')}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[
                                    10,
                                    25,
                                    50,
                                    { label: 'All', value: totalItems },
                                ]}
                                className={classes.tableFooterMembers}
                                count={totalItems}
                                rowsPerPage={pageSize}
                                page={pageIndex}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </MaUTable>
            </TableContainer>
        </Paper>
    )
}
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Typography, Grid, Tooltip, Paper, IconButton } from '@material-ui/core';
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { useHistory } from "react-router-dom";
import useStyles from '../../styles/useStyles';
import { ApplicationContext } from "../../contexts";
import { ApplicationFormDialog } from './tables/ApplicationFormDialog';
import { SimplifiedFilter } from "../../ui-components/SimplifiedFilter";
import { NavRoutes } from '../../utils';
import { ApplicationTable } from '../common/ApplicationTable';
import DeleteWithConfirm from '../../ui-components/DeleteWithConfim';

export const ApplicationsPage = (props) => {
    const classes = useStyles();

    const {
        state,
        loadApplications,
        isLoadingApplications,
        loadAvailableAppOptions,
        removeAADApplication,
    } = useContext(ApplicationContext);

    useEffect(() => {
        async function fetchFromApi() {
            await loadAvailableAppOptions();
        }

        if (!isLoadingApplications)
            fetchFromApi();
    }, [state.applications?.items?.length])

    const [filter, setFilter] = useState();
    const history = useHistory();

    const [selectedApplication, setSelectedApplication] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const handleClose = () => {
        setSelectedApplication(null);
        return setDialogOpen(false);
    };

    const fetchData = React.useCallback(({ pageIndex, pageSize }) => {
        if (!dialogOpen) // refresh data only if form is closed
            loadApplications(pageSize, pageIndex, filter);
    }, [filter, dialogOpen]);

    const Filters = () => <Grid container direction="row" justifyContent="flex-end">
        <Grid item xs={10} >
            <SimplifiedFilter
                onSubmit={(value) => setFilter(value)}
            />
        </Grid>
        <Grid item xs={2} >
            <Tooltip title="Add application">
                <IconButton
                    aria-label="add-application"
                    onClick={() => setDialogOpen(true)}>
                    <AddBoxIcon />
                </IconButton>
            </Tooltip>
        </Grid>
    </Grid>

    const postRowActions = (row, loading, onAction) => {
        return <>
            <Tooltip title={row.values.owner === 1 ? "Delete application" : "Cannot delete custom application"}>
                <span>
                    <DeleteWithConfirm
                        disabled={loading || (row.values.owner !== 1)}
                        loading={loading}
                        handleDelete={async () => {
                            await removeAADApplication(row.values.id);
                            onAction();
                        }} />
                </span>
            </Tooltip>
            <IconButton variant="link" edge="end" aria-label="open" onClick={() => history.push(NavRoutes.ApplicationAdmin(row.values.id))}>
                <Tooltip title="Open application">
                    <KeyboardArrowRightIcon />
                </Tooltip>
            </IconButton>
        </>
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'id',
                show: false
                // hidden column
            },
            {
                Header: 'ApplicationMasterDatum',
                accessor: 'applicationMasterDatum',
                show: false
                // hidden column
            },
            {
                Header: 'Application Key',
                accessor: 'name'
            },
            {
                Header: 'Display Name',
                accessor: 'displayName'
            },
            {
                Header: 'Description',
                accessor: 'description',
                Cell: ({ value }) =>
                    <Tooltip title={value ?? ""}>
                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", width: '12rem' }}>
                            <Typography className={classes.fontDescriptionBig} noWrap>{value}</Typography>
                        </div>
                    </Tooltip>
            },
            {
                Header: 'Type',
                accessor: 'owner',
                Cell: ({ value }) => (value === 0 ? "Custom" : "Active Directory"),
            },
            {
                Header: 'Roles',
                accessor: 'roleCount',
            }
        ],
        []
    );

    return (
        <div className={classes.mainSectionWrapper}>
            <Grid container spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="flex-start">
                <Grid container item xs={12}>
                    <Grid item xs={12} className={classes.mainHeader}>
                        <Typography className={classes.fontTitleBig}>
                            <center>APPS &amp; ROLES CONFIGURATION</center>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Paper>
                        <ApplicationFormDialog
                            open={dialogOpen}
                            application={selectedApplication}
                            handleClose={handleClose}
                        />
                        <ApplicationTable
                            showTitle
                            columns={columns}
                            postRowActions={postRowActions}
                            fetchData={fetchData}
                            filters={Filters}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
import {
    Grid, Typography, CircularProgress, Accordion, AccordionActions,
    AccordionSummary, AccordionDetails, Button
} from "@material-ui/core";
import React, { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useStyles from "../../../styles/useStyles";
import useUniqueCode from './hooks/useUniqueCode';
import { UniqueCodeStatus } from "./../../../utils/Constants";
import { UserContext } from '../../../contexts';

import Moment from 'moment';
import { Formats } from "../../../utils";
import { TextField } from '@material-ui/core';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { NavRoutes } from "../../../utils";

export const UniqueCodeUpdatePage = (props) => {
    const { id } = useParams();
    const classes = useStyles();

    const uniqueCodeHook = useUniqueCode();
    const history = useHistory();

    const {
        getUniqueCode,
        uniqueCode,
        updateUniqueCode,
        deleteLoading,
        deleteUniqueCode,
        uniqueCodeLoading
    } = uniqueCodeHook;

    useEffect(() => {
        getUniqueCode(id);
    }, []);
    const data = useMemo(() => uniqueCode, [uniqueCode]);
    const { state: userState } = useContext(UserContext);

    const [firstName, setFirstName] = React.useState(data.firstName);
    const [createDateUtc, setCreateDateUtc] = React.useState(data.createDateUtc);
    const [lastName, setLastName] = React.useState(data.lastName);
    const [cashierKnownAs, setCashierKnownAs] = React.useState(data.cashierKnownAs);
    const [beanstoreId, setBeanstoreId] = React.useState(data.beanstoreId);
    const [cidEmployNumber, setCidEmployNumber] = React.useState(data.cidEmployNumber);
    const [mail, setMail] = React.useState(data.mail);

    useEffect(() => {
        setCreateDateUtc(data.createDateUtc);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setCashierKnownAs(data.cashierKnownAs);
        setBeanstoreId(data.beanstoreId);
        setCidEmployNumber(data.cidEmployNumber);
        setMail(data.mail);
    }, [uniqueCode])

    const handleChange = (event) => {
        switch (event.currentTarget.id) {
            case "FirstName":
                setFirstName(event.target.value);
                break;
            case "LastName":
                setLastName(event.target.value);
                break;
            case "CashierKnownAs":
                setCashierKnownAs(event.target.value);
                break;
            case "BeanstoreId":
                setBeanstoreId(event.target.value);
                break;
            case "CIDEmployNumber":
                setCidEmployNumber(event.target.value);
                break;
            case "Mail":
                setMail(event.target.value);
                break;
            default:
                break;
        }
    }
    const goToListPage = () => history.push(NavRoutes.UniqueCodeAdmin);
    const updateRecord = () => {
        updateUniqueCode({
            id,
            firstName,
            lastName,
            createDateUtc,
            cashierKnownAs,
            beanstoreId,
            cidEmployNumber,
            mail,
            updatingUser: userState.applicationUser.displayName
        });
    };


    console.log(UniqueCodeStatus[data.status]);
    return (
        uniqueCodeLoading ?
            <CircularProgress /> :
            <Grid container spacing={1} item xs={12} md={10} className={classes.criteriaContainer}  >
                <Grid item xs={12}>
                    <Accordion
                        defaultExpanded
                        expanded={true}>
                        <AccordionSummary >
                            <Typography variant="h6">
                                <center>Unique Code Client Advisor</center>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container >
                                <Grid item xs={12} className={classes.searchFormItems}>
                                    <TextField
                                        className={classes.fontTitleSmall}
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        // {...register('account', { validate: validateAnyRequired })}
                                        fullWidth
                                        id="FirstName"
                                        label="FirstName"
                                        name="FirstName"
                                        onChange={(event) => { handleChange(event) }}
                                        value={firstName}
                                        autoComplete="off"
                                        InputProps={{
                                            style: { fontSize: 14 }
                                        }}
                                        InputLabelProps={{
                                            style: { fontSize: 14 }
                                        }}
                                        autoFocus
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.searchFormItems}>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        // {...register('firstName', { validate: validateAnyRequired })}
                                        fullWidth
                                        id="LastName"
                                        label="LastName"
                                        name="LastName"
                                        value={lastName}
                                        autoComplete="off"
                                        onChange={(event) => { handleChange(event) }}
                                        InputProps={{
                                            style: { fontSize: 14 }
                                        }}
                                        InputLabelProps={{
                                            style: { fontSize: 14 }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.searchFormItems}>
                                    <TextField
                                        className={classes.fontTitleSmall}
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        // {...register('lastName', { validate: validateAnyRequired })}
                                        fullWidth
                                        id="CashierKnownAs"
                                        label="CashierKnownAs"
                                        onChange={(event) => { handleChange(event) }}
                                        value={cashierKnownAs}
                                        name="cashierKnownAs"
                                        autoComplete="off"
                                        InputProps={{
                                            style: { fontSize: 14 }
                                        }}
                                        InputLabelProps={{
                                            style: { fontSize: 14 }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.searchFormItems}>
                                    <TextField
                                        className={classes.fontTitleSmall}
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        // {...register('email', { validate: validateAnyRequired })}
                                        fullWidth
                                        id="BeanstoreId"
                                        label="BeanstoreId"
                                        onChange={(event) => { handleChange(event) }}
                                        name="BeanstoreId"
                                        value={beanstoreId}
                                        InputProps={{
                                            style: { fontSize: 14 }
                                        }}
                                        InputLabelProps={{
                                            style: { fontSize: 14 }
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} className={classes.searchFormItems}>
                                    <TextField
                                        className={classes.fontTitleSmall}
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        // {...register('email', { validate: validateAnyRequired })}
                                        fullWidth
                                        id="CIDEmployNumber"
                                        onChange={(event) => { handleChange(event) }}
                                        label="CIDEmployNumber"
                                        name="CIDEmployNumber"
                                        value={cidEmployNumber}
                                        InputProps={{
                                            style: { fontSize: 14 }
                                        }}
                                        InputLabelProps={{
                                            style: { fontSize: 14 }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.searchFormItems}>
                                    <TextField
                                        className={classes.fontTitleSmall}
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        // {...register('email', { validate: validateAnyRequired })}
                                        fullWidth
                                        id="Mail"
                                        onChange={(event) => { handleChange(event) }}
                                        label="Mail"
                                        name="Mail"
                                        value={mail}
                                        InputProps={{
                                            style: { fontSize: 14 }
                                        }}
                                        InputLabelProps={{
                                            style: { fontSize: 14 }
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} className={classes.searchFormItems}>
                                    <TextField
                                        className={classes.fontTitleSmall}
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        // {...register('email', { validate: validateAnyRequired })}
                                        fullWidth
                                        id="Status"
                                        disabled
                                        label="Status"
                                        name="Status"
                                        value={(data.status === 0) ?
                                            UniqueCodeStatus[0].value :
                                            (data.status === 1) ?
                                                UniqueCodeStatus[1].value :
                                                (data.status === 2) ?
                                                    UniqueCodeStatus[2].value
                                                    : (data.status === 3) ?
                                                        UniqueCodeStatus[3].value :
                                                        (data.status === 4) ?
                                                            UniqueCodeStatus[4].value :
                                                            (data.status === 5) ?
                                                                UniqueCodeStatus[5].value : <></>
                                        }
                                        InputProps={{
                                            style: { fontSize: 14 }
                                        }}
                                        InputLabelProps={{
                                            style: { fontSize: 14 }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.searchFormItems}>
                                    <TextField
                                        className={classes.fontTitleSmall}
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        // {...register('email', { validate: validateAnyRequired })}
                                        fullWidth
                                        id="Creating Date"
                                        label="Creating Date"
                                        name="Creating Date"
                                        disabled
                                        value={Moment(data.createDateUtc).format(Formats.DateFormat)}
                                        InputProps={{
                                            style: { fontSize: 14 }
                                        }}
                                        InputLabelProps={{
                                            style: { fontSize: 14 }
                                        }}
                                    />
                                </Grid>
                                {data.updatingUser != null ?
                                    <>
                                        <Grid item xs={12} className={classes.searchFormItems}>
                                            <TextField
                                                className={classes.fontTitleSmall}
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                // {...register('email', { validate: validateAnyRequired })}
                                                fullWidth
                                                id="Updating Date"
                                                label="Updating Date"
                                                name="Updating Date"
                                                disabled
                                                value={Moment(data.updateDateUtc).format(Formats.DateFormat)}
                                                InputProps={{
                                                    style: { fontSize: 14 }
                                                }}
                                                InputLabelProps={{
                                                    style: { fontSize: 14 }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className={classes.searchFormItems}>
                                            <TextField
                                                className={classes.fontTitleSmall}
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                // {...register('email', { validate: validateAnyRequired })}
                                                fullWidth
                                                id="UpdatingUser"
                                                label="Updated by"
                                                name="Updated by"
                                                disabled
                                                value={data.updatingUser}
                                                InputProps={{
                                                    style: { fontSize: 14 }
                                                }}
                                                InputLabelProps={{
                                                    style: { fontSize: 14 }
                                                }}
                                            />
                                        </Grid>
                                    </> :
                                    <></>
                                }
                            </Grid>
                        </AccordionDetails>
                        <AccordionActions>

                            <Grid container justifyContent="center">
                                <Button
                                    color='primary'
                                    size='medium'
                                    type='submit'
                                    variant='contained'
                                    onClick={() => goToListPage()}
                                >
                                    RETURN
                                </Button>
                                <>
                                    <Grid item xs={12} style={{ flexBasis: "15%" }}></Grid>
                                    <Button
                                        color='primary'
                                        size='medium'
                                        type='submit'
                                        variant='contained'
                                        onClick={() => deleteUniqueCode(id)}
                                    // disabled={props.isLoading}
                                    >
                                        DELETE
                                    </Button>
                                </>
                                {data.status > 1 ?
                                    <>
                                        <Grid item xs={12} style={{ flexBasis: "15%" }}></Grid>
                                        <Button
                                            color='primary'
                                            size='medium'
                                            type='submit'
                                            variant='contained'
                                            onClick={() => updateRecord()}
                                        // disabled={props.isLoading}
                                        >
                                            UPDATE
                                        </Button>
                                    </> :
                                    <></>
                                }
                            </Grid>
                        </AccordionActions>
                    </Accordion>
                </Grid>
            </Grid>
    );
};
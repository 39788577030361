import React, { useContext, useEffect } from "react";
import { SearchContext } from "../../../../contexts";
import { searchCategories } from "../../../../utils";
import {
    ADCriteriaForm,
    SapCriteriaForm,
    AppAndRolesCriteriaForm,
    GroupCriteriaForm,
    DeviceCriteriaForm
} from "./forms";
import { ProgressBar } from "../../../../ui-components";

const SearchCriteria = () => {
    const {
        state,
        searchUsersByAdCriteria,
        searchUsersBySapCriteria,
        searchUsersByAppRolesCriteria,
        isSearchingUsers,
        isLoadingGroups,
        isLoadingApplicationOptions,
        isLoadingRoleOptions,
        isLoadingTransactionOptions,
        isLoadingEntityOptions,
        handleDirectTransactionSearch,
        isSearchingGroups,
        searchGroupsByCriteria,
        isSearchingDevices,
        searchDevicesByCriteria
    } = useContext(SearchContext);

    useEffect((props) => {
        const searchCriteriaParametrer = "/searchcriteria/";

        // the transaction code could have some '/' inside, so we have to extract the string manually
        const path = window.location.pathname.toLowerCase();
        const searchCriteria = path.substring(path.lastIndexOf(searchCriteriaParametrer) + searchCriteriaParametrer.length).toUpperCase();

        handleDirectTransactionSearch(searchCriteria);
    }, [])

    const isLoadingForAd = isSearchingUsers || isLoadingGroups;
    const handleADCriteriaSubmit = async (values) => {
        await searchUsersByAdCriteria({
            adQuery: values
        });
    }

    const isLoadingForSap = isSearchingUsers || isLoadingTransactionOptions || isLoadingEntityOptions;
    const handleSapCriteriaSubmit = async (values) => {
        const searchByTransactionCodes = values.entityName.key === 0 // transactions
        await searchUsersBySapCriteria({
            sapQuery: {
                description: values.description,
                entityId: searchByTransactionCodes ? null : values.entityObjectIds.key,
                transactionCodes: searchByTransactionCodes ? values.entityObjectIds.map(x => x.value) : null,
                entityName: values.entityName.key
            }
        });
    }

    const isLoadingForAppRoles = isSearchingUsers || isLoadingApplicationOptions || isLoadingRoleOptions;
    const handleAppRolesCriteriaSubmit = async ({ application, role, firstName, lastName, email, showOnlyAdAccounts }) => {
        await searchUsersByAppRolesCriteria({
            appAndRolesQuery: {
                applicationId: application,
                roleId: role && role.key,
                roleName: role && role.value, // this is just to diplay the submitted filters
                firstName: firstName,
                lastName: lastName,
                email: email,
                showOnlyAdAccounts: showOnlyAdAccounts
            }
        });
    }

    const _handleGroupsByCriteria = async (values) => await searchGroupsByCriteria(values);

    const _handleDevicesByCriteria = async (values) => await searchDevicesByCriteria(values);

    const renderContent = () => {
        switch (state.searchCategory) {
            case searchCategories.ActiveDirectory:
                return (
                    <ADCriteriaForm
                        onSubmit={handleADCriteriaSubmit}
                        isLoading={isLoadingForAd}
                    />
                );
            case searchCategories.Sap:
                return (
                    <SapCriteriaForm
                        onSubmit={handleSapCriteriaSubmit}
                        isLoading={isLoadingForSap}
                    />
                );
            case searchCategories.AppAndRoles:
                return (
                    <AppAndRolesCriteriaForm
                        onSubmit={handleAppRolesCriteriaSubmit}
                        isLoading={isLoadingForAppRoles}
                    />
                );
            case searchCategories.ADGroups:
                return (
                    <GroupCriteriaForm
                        onSubmit={_handleGroupsByCriteria}
                        isLoading={isSearchingGroups}
                    />
                );
            case searchCategories.Devices:
                return (
                    <DeviceCriteriaForm
                        onSubmit={_handleDevicesByCriteria}
                        isLoading={isSearchingDevices}
                    />
                );

            default:
                break;
        }
    }

    const isLoading = isLoadingForAd || isLoadingForSap || isLoadingForAppRoles;
    return <>
        <ProgressBar loading={isLoading} />
        {renderContent()}
        <ProgressBar loading={isLoading} />
    </>
}

export default SearchCriteria;
import { useState } from 'react';
import { ApiClient, ApiRoutes } from '../utils';
import { useContext } from 'react';
import { InsightContext } from '../contexts';

/**
 * Can be used to fetch AD users options.
 * @param {*} entityName 
 * @returns 
 */
export const useADUsersOptions = (entityName) => {
    const { trackError } = useContext(InsightContext);

    const [userOptions, setUserOptions] = useState([]);
    const [loadingUserOptions, setLoading] = useState(false);

    const fetchUserOptions = async (query, take) => {
        setLoading(true);

        ApiClient.get(ApiRoutes.Search.GetUserOptions({ query, take }))
            .then((response) => {
                console.dir(response.data)
                setUserOptions(response.data);
            })
            .catch((reason) => {
                trackError(reason);
                setUserOptions([]);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return {
        fetchUserOptions,
        userOptions,
        loadingUserOptions
    }
}
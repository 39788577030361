import { useState } from 'react';

import { ApiRoutes, ApiClient } from "../../../utils";

export const useDashboard = () => {
    const [eTypeReports, setETypeReports] = useState([]);
    const [eTypeReportsLoading, setETypeReportsLoading] = useState(false);

    const handleETypeReports = async () => {
        setETypeReportsLoading(true);

        ApiClient.get(ApiRoutes.Kpi.GetETypeReports())
            .then((response) => {
                console.dir(response.data)
                setETypeReports(response.data);
            })
            .catch((reason) => {
                setETypeReports([]);
            })
            .finally(() => {
                setETypeReportsLoading(false);
            });
    }

    return {
        handleETypeReports,
        eTypeReports,
        eTypeReportsLoading
    }
}
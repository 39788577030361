import React from 'react';

import {
    Typography
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1)
    }
}));

export const FieldError = ({ error, customMessage }) => {
    const classes = useStyles();
    return (
        <>
            {error &&
                <Typography variant="caption" color="error" component="div" gutterBottom className={classes.root}>
                    {customMessage ?? error.message}
                </Typography>
            }
        </>
    );
}

import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import {
    Button,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    TextField,
    Typography,
    Tooltip,
    IconButton,
    ListItemSecondaryAction,
    makeStyles
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

import useStyles from '../../styles/useStyles';
import { NavRoutes, excelExportFromPanel } from '../../utils';
import { useGroup } from './hooks/useGroup';

import { EmptyListItem, KeyValueList, Shimmer } from '../../ui-components';
import { MembersTable } from './components/MembersTable/members-table';
import { GroupChildsTable } from './components/GroupChildsTable/groupchilds-table';

const useLocalStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: "white",
        '&:hover': {
            backgroundColor: theme.palette.gray.light,
        }
    }
}));

const GroupDetailsPage = (props) => {
    const {
        handleGroup,
        group,
        groupLoading,
        handleGroupMembers,
        groupMembers,
        groupMembersLoading,
        handleGroupChilds,
        groupChilds,
        groupChildsLoading
    } = useGroup();

    let { id } = useParams();

    useEffect(() => {
        if (!!id) {
            handleGroup({ id });
            handleGroupMembers({ id });
            handleGroupChilds({ id });
        }
    }, [id]);

    const dataLabels = [
        { property: 'displayName', label: 'Group Name' },
        { property: 'description', label: 'Group Description' },
        { property: 'id', label: 'Object Id' },
        { property: 'createdDateTime', label: 'Created on' },
        { property: 'mailEnabled', label: 'Email Enabled' },
        { property: 'mail', label: 'Email' },
        { property: 'mailNickname', label: 'Email Nickname' },
        { property: 'onPremisesDomainName', label: 'Premise Domain Name' },
        { property: 'onPremisesSamAccountName', label: 'Premise Account' },
        { property: 'onPremisesSyncEnabled', label: 'Premise Sync Enabled' },
        { property: 'onPremisesLastSyncDateTime', label: 'Premise Last Sync' },
        { property: 'securityEnabled', label: 'Security Enabled' },
        { property: 'securityIdentifier', label: 'Security Identifier' }
    ];

    const classes = useStyles();
    const localClasses = useLocalStyles();

    return (
        <div className={classes.mainSectionWrapper}>
            <Grid container justifyContent="center">
                <Grid container spacing={3} justifyContent="center" item xs={12}>
                    <Grid container item xs={12}>
                        <Grid item xs={12} className={classes.mainHeader}>
                            <Typography className={classes.fontTitleBig}>
                                <center>{group?.displayName}</center>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} md={5}>
                        <Paper className={classes.padding_3}>
                            <Grid container item sm={12}>
                                <Grid item sm={6} md={10}>
                                    {groupLoading
                                        ?
                                        <Shimmer variant="text" />
                                        :
                                        <Typography variant="h6">{'Group Details'}</Typography>
                                    }
                                </Grid>
                                <Grid item sm={6} md={2}>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        className={localClasses.button}
                                        disabled={!group || groupLoading}
                                        startIcon={<GetAppIcon />}
                                        onClick={() => excelExportFromPanel(
                                            group,
                                            [...dataLabels],
                                            'Group Details',
                                            `${group?.displayName} - Group Details`
                                        )}
                                    >
                                        <Typography style={{ fontSize: '10px' }}>export</Typography>
                                    </Button>
                                </Grid>
                                <Grid item sm={12}>
                                    {groupLoading
                                        ?
                                        <Shimmer variant="rect" height={400} />
                                        :
                                        <KeyValueList data={group} labels={dataLabels} />
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item sm={12} md={10}>
                        {groupMembersLoading
                            ?
                            <Shimmer variant="rect" height={400} />
                            :
                            <MembersTable members={groupMembers} title={'Members List'} groupName={group?.displayName} />
                        }
                    </Grid>
                    <Grid item sm={12} md={10}>
                        {groupChildsLoading
                            ?
                            <Shimmer variant="rect" height={400} />
                            :
                            <GroupChildsTable groups={groupChilds} title={'Child Groups List'} groupName={group?.displayName} />
                        }
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default GroupDetailsPage;

const UsersList = ({ data, emptyText }) => {
    const classes = useStyles();
    const history = useHistory();
    const notEmpty = data && data.length > 0;

    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <div className={classes.search}>
                        <TextField
                            variant="standard"
                            type="search"
                            placeholder="Search"
                            fullWidth

                        // value={groupFilter}
                        // onChange={(value) => { setGroupFilterItems(value) }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.scrollablePaper}>
                        <List aria-labelledby="group-users">
                            {notEmpty > 0
                                ?
                                data.map((dataItem, dataIndex) =>
                                    <React.Fragment key={dataIndex}>
                                        <ListItem
                                            button
                                            onClick={() => { }}
                                        >
                                            <ListItemIcon>
                                                <PermIdentityIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{ primary: classes.fontTitleMedium }}
                                                primary={`${dataItem.firstName} ${dataItem.lastName}`}
                                            />
                                            <ListItemText
                                                classes={{ primary: classes.fontTitleMedium }}
                                                primary={dataItem.accountName}
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    edge="end"
                                                    aria-label="View User details"
                                                    onClick={() => history.push(NavRoutes.UserDetails(dataItem.id, dataItem.accountName))}
                                                >
                                                    <Tooltip title="View User details">
                                                        <OpenInBrowserIcon />
                                                    </Tooltip>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>

                                        <Divider />
                                    </React.Fragment>
                                )
                                :
                                <EmptyListItem text={emptyText} />
                            }
                        </List>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}
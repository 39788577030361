import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import {   Tooltip, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        fontSize: 15.5
    },
    key: {
        fontWeight: 500,
        fontSize: 7

    },
    value: {
        display: "flex",
        alignItems: "flex-end"
    },
    dividerWrapper: {
        padding: theme.spacing(1, 0)
    },
    fontTitle: {
        fontSize: 13
    }
}));

/**
 * Renders an object like a list of key value. To override the property names 
 * pass in the prop labels as an array of { property:"", label:"" }. 
 * When labels prop is supplied it will dictate the order of the properties in the list.
 */
export const KeyValueListSoDAnalysis = ({
    className,
    data,
    style,
    labels
}) => {
    const classes = useStyles();

    const deleteFromObject = (keyPart, obj) => {
        for (let k in obj) {          // Loop through the object
            if (~k.indexOf(keyPart)) { // If the current key contains the string we're looking for
                delete obj[k];       // Delete obj[key];
            }
        }
    }

    const dataShallowClone = { ...data };

    if (!labels || (labels?.length === 0 ?? true)) throw new Error('Labels array required');

    //clean data
    const toKeep = labels.map(l => l.property);
    const toRemove = Object.keys(dataShallowClone).filter(value => !toKeep.includes(value));
    toRemove.forEach(p => {
        deleteFromObject(p, dataShallowClone);
    });

    const itemsArray = Object.entries(dataShallowClone);
    const getItems = () => {
        if (labels) {
            const sortingArray = labels.map(x => x.property);
            return itemsArray.sort((a, b) => {
                return sortingArray.indexOf(a[0]) - sortingArray.indexOf(b[0]);
            })
        }
        return itemsArray;
    }

    const renderValue = (value) => {
        return value
    }

    return (
        <Grid container className={clsx(className, classes.root)} style={style}>
            {getItems().map(([key, value], index) => {
                const currentItem = labels?.find(x => x.property === key);
                return (
                    <Grid item container key={key} data-testid="keyvaluelist">
                        <Grid item xs={4} sm={5} className={classes.key}>
                            {currentItem?.tooltipText ?
                                <Tooltip title={currentItem?.tooltipText}>
                                    <Typography className={classes.fontTitle}>{currentItem?.label ?? key}</Typography>
                                </Tooltip> :
                                <Typography style={{textAlign: 'right',  margin:"5px"}} className={classes.fontTitle}>{currentItem?.label ?? key}</Typography>
                            }
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item xs={12} sm={5} className={classes.value}>
                            <Typography noWrap variant="caption" style={{ margin:"5px"}}>{renderValue(value)}</Typography>
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
};

import moment from 'moment';
import { useState } from 'react';
import { ApiClient, ApiRoutes, Formats } from '../../../../../utils';

export function useSap() {

    const dateFormat = Formats.DateFormat;

    const loadSapUser = async (accountName) => {
        const r = await ApiClient.get(ApiRoutes.User.GetSapUser({ accountName }));
        return r.data;
    }

    const loadSapAuthorizationObjects = async (accountName) => {
        const data = {
            accountName,
            size: 10000,
            page: 0,
            onlyImportant: false
        };
        const r = await ApiClient.post(ApiRoutes.User.ListSapAuthorizationObjects(), data);
        return r.data;
    }

    const loadSapTransactions = async (accountName) => {
        const data = {
            accountName,
            size: 10000,
            page: 0,
            onlyImportant: false
        };
        const r = await ApiClient.post(ApiRoutes.User.ListSapTransactions(), data);
        return r.data;
    }

    const loadSapRoles = async (accountName) => {
        const r = await ApiClient.get(ApiRoutes.User.ListSapRoles(
            {
                accountName,
                size: 10000,
                page: 0,
                onlyImportant: false
            }));
        return r.data;
    }

    const loadSapFireFighters = async (accountName) => {
        const data = {
            accountName,
            size: 10000,
            page: 0
        };
        const r = await ApiClient.post(ApiRoutes.User.ListSapFireFighters(), data);
        const newItems = r.data.items.map(item => {
            return {
                ...item,
                validToDate: moment(item.validToDate).format(dateFormat)
            };
        });
        return {
            ...r.data,
            items: newItems
        };
    }

    const loadSapOrganizations = async (accountName) => {
        const data = {
            accountName,
            size: 10000,
            page: 0,
            onlyImportant: false
        };
        const r = await ApiClient.post(ApiRoutes.User.ListSapOrganizations(), data);
        return r.data;
    }

    const [isLoadingSapData, setIsLoadingSapData] = useState(false);
    const loadSapData = async (accountName) => {
        try {
            setIsLoadingSapData(true);
            const results = await Promise.all([
                loadSapUser(accountName),
                loadSapAuthorizationObjects(accountName),
                loadSapTransactions(accountName),
                loadSapRoles(accountName),
                loadSapFireFighters(accountName),
                loadSapOrganizations(accountName)
            ]);

            return {
                sapUser: results[0],
                sapAuthorizationObjects: results[1],
                sapTransactions: results[2],
                sapRoles: results[3],
                sapFireFighters: results[4],
                sapOrganizations: results[5],
            }
        } finally {
            setIsLoadingSapData(false);
        }
    }

    return {
        isLoadingSapData,
        loadSapData
    }
}
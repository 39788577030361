import React, { useContext } from "react";
import { ButtonBase, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles";
import { SearchContext } from "../../../contexts";
import { searchCategories } from "../../../utils";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        minWidth: 300,
        width: "100%",
        backgroundColor: 'white',
        fontSize: 2
    },
    fontTitle: {
        fontSize: 2
    },
    button: {
        position: "relative",
        height: 50,
        [theme.breakpoints.down("xs")]: {
            width: "100% !important", // Overrides inline-style
            height: 50
        },
        "&:hover, &$focusVisible": {
            zIndex: 1,
            "& $imageBackdrop": {
                opacity: 0.35,
                backgroundColor: theme.palette.common.black,
            },
            "& $imageMarked": {
                opacity: 0
            },
            "& $imageTitle": {
                color: theme.palette.common.white
            }
        }
    },
    buttonFocus: {
        position: "relative",
        height: 50,
        [theme.breakpoints.down("xs")]: {
            width: "100% !important", // Overrides inline-style
            height: 50
        },
        zIndex: 1,
        "& $imageBackdrop": {
            opacity: 0.45,
            backgroundColor: theme.palette.common.black,
        },
        "& $imageMarked": {
            opacity: 0
        },
        "& $imageTitle": {
            color: theme.palette.common.white
        }
    },
    focusVisible: {},
    imageButton: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.common.black,
        backgroundColor: alpha(theme.palette.common.black, 0.15)
    },
    imageSrc: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: "cover",
        backgroundPosition: "center 40%",
    },
    imageBackdrop: {
        position: "absolute",
        height: '100%',
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.white,
        opacity: 0.5,
        transition: theme.transitions.create("opacity")
    },
    imageTitle: {
        width: '100%',
        height: '70%',
        fontSize: ".85rem",
        fontWeight: "400",
        bottom: 0,
        marginBottom: 0,
        position: 'absolute',
        textTransform: "uppercase"
    },
}));

function CustomButton(props) {
    return (
        <ButtonBase
            focusRipple
            onClick={() => props.setSearchCategory(props.btn.category)}
            className={props.selected ? props.classes.buttonFocus : props.classes.button}
            focusVisibleClassName={props.classes.focusVisible}
            style={{
                width: props.btn.width
            }}>
            <span className={props.classes.imageBackdrop} />
            <span className={props.classes.imageButton}>
                <Typography component="span" variant="h6" color="inherit" className={props.classes.imageTitle}>
                    {props.btn.title}
                </Typography>
            </span>
        </ButtonBase>);
}

const SearchCategoryButtons = () => {
    const classes = useStyles();
    const { setSearchCategory, state } = useContext(SearchContext);

    return (
        <div className={classes.root}>
            {buttons.map(btn => (
                <CustomButton
                    key={btn.title}
                    classes={classes}
                    setSearchCategory={setSearchCategory}
                    selected={btn.category === state.searchCategory}
                    btn={btn} />
            ))}
        </div>
    );
}

export default SearchCategoryButtons;

const buttons = [
    {
        title: "Users",
        category: searchCategories.ActiveDirectory,
        width: "25%"
    },
    {
        title: "Users by SAP Transaction",
        category: searchCategories.Sap,
        width: "25%"
    },
    {
        title: "Users by Application",
        category: searchCategories.AppAndRoles,
        width: "25%"
    },
    {
        title: "AD Groups",
        category: searchCategories.ADGroups,
        width: "25%"
    },
    //{
    //    title: "Devices",
    //    category: searchCategories.Devices,
    //    width: "20%"
    //}
];
import { useState, useEffect, useContext, useMemo } from 'react';
import { UserContext } from '../../../../../contexts';
import Moment from 'moment';
import { Formats } from '../../../../../utils';

export function useBtrace() {
    const [hookState, setHookState] = useState();

    const {
        state
    } = useContext(UserContext);

    const btraceData = useMemo(() => {
        const formattedData = formatData(state.btraceUser);
        return {
            user: {
                data: formattedData,
                fields: [
                    { property: "name", label: "Name" },
                    { property: "surname", label: "Surname" },
                    { property: "username", label: "Username" },
                    { property: "email", label: "Email" },
                    { property: "btraceCreationDate", label: "Creation Date" },
                    { property: "btraceLastModified", label: "Last Modified" },
                    { property: "btraceLastLogin", label: "Last Login" },
                    { property: "locked", label: "Locked" },
                    { property: "admin", label: "Admin" }
                ]
            },
            place: {
                data: formattedData,
                fields: [
                    { property: "placeCode", label: "Code" },
                    { property: "placeDescription", label: "Description" },
                    { property: "placeSyncCode", label: "Sync Code" },
                    { property: "placeAddress", label: "Address" },
                    { property: "placeType", label: "Place Type" },
                    { property: "placeCountryCode", label: "Country Code" },
                    { property: "placeExternalcode", label: "External Code" },
                    { property: "placeHierarchyCode", label: "Hierarchy Code" },
                    { property: "placeHierarchyDescription", label: "Hierarchy Description" }
                ]
            },
            roles: state.btraceUser?.roles ?? []
        };
    }, [state.btraceUser]);

    useEffect(() => {
        setHookState(btraceData)
    }, [btraceData]);

    return hookState;
}

function formatData(user) {
    let tmp = JSON.parse(JSON.stringify(user));
    if (tmp?.btraceCreationDate)
        tmp.btraceCreationDate = Moment(tmp.btraceCreationDate).format(Formats.DateFormat);
    if (tmp?.btraceLastModified)
        tmp.btraceLastModified = Moment(tmp.btraceLastModified).format(Formats.DateFormat);
    if (tmp?.btraceLastLogin)
        tmp.btraceLastLogin = Moment(tmp.btraceLastLogin).format(Formats.DateFormat);
    return tmp;
}

import React from "react";
import { Tooltip, Typography, Button, Toolbar, AppBar } from "@material-ui/core";
import useStyles from "../../styles/useStyles";
import { Link } from "react-router-dom";
import { NavRoutes } from "../../utils";
import { useHistory } from "react-router-dom";

const Footer = () => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <AppBar position="fixed" className={classes.footer}>
            <Toolbar className={classes.footerToolBar}>
                <Typography variant="subtitle2" noWrap className={classes.title}>
                    Chief Information Security Office - {new Date().getFullYear()}
                </Typography>
                <Tooltip title="Procedures" aria-label="Procedures">
                    <Button color="primary" target="_blank" onClick={() => history.push(NavRoutes.Procedures)}>Procedures</Button>
                </Tooltip>
            </Toolbar>
        </AppBar>
    );
};

export default Footer;
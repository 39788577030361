import React, { useEffect } from "react";
import _ from 'lodash';
import { Grid, Paper, Typography } from '@material-ui/core';
import useStyles from '../../../styles/useStyles';
import useSodAnalysis from './hooks/useSodAnalysis';
import SodAnalysisTable from './components/SodAnalysisTable';
import NewSoDAnalysisForm from './components/NewSodAnalysisForm';

const SodAnalysisListPage = () => {

    const sodAnalysisHook = useSodAnalysis();

    const {
        getSodAnalyses,
        newSoDAnalysisCompleted
    } = sodAnalysisHook;

    const classes = useStyles();

    useEffect(() => {
        getSodAnalyses();
    }, [newSoDAnalysisCompleted]);

    return (
        <div className={classes.mainSectionWrapper}>
            <Grid container spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="flex-start">
                <Grid container item xs={12}>
                    <Grid item xs={12} className={classes.mainHeader}>
                        <Typography className={classes.fontTitleBig}>
                            <center>SOD ANALYSIS</center>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} item xs={12} md={10} >
                    <Grid item xs={12}>
                        <Paper>
                            <NewSoDAnalysisForm hook={sodAnalysisHook}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper>
                            <SodAnalysisTable
                                title="SoD Analysis"
                                hook={sodAnalysisHook}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default SodAnalysisListPage;
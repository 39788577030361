import { isNullOrWhiteSpace } from "./StringUtils";

export const searchCategories = {
    ActiveDirectory: "AD",
    Sap: "SAP",
    AppAndProcedures: "APPPROCEDURES",
    AppAndRoles: "APPROLES",
    ADGroups: "ADGROUPS",
    Devices: "DEVICES",
}

export const NavRoutes = {
    UserDetails: (userId, accountName, customApplication) => {
        let route = `/user?userId=${userId}&account=${accountName}`;
        if (!isNullOrWhiteSpace(customApplication)) route += `&customApplication=${encodeURIComponent(customApplication)}`;
        return route;
    },
    UserDetailsTemplate: "/user",
    RoleDetailsAdmin: (applicationId, roleId) => `/admin/application/${applicationId}/role/${roleId}`,
    RoleDetailsTemplateAdmin: "/admin/application/:applicationId/role/:roleId",
    ApplicationAdmin: (id) => `/admin/application/${id}`,
    ApplicationTemplateAdmin: "/admin/application/:id",
    ApplicationListAdmin: "/admin/approle",
    SodAnalysisAdmin: (id) => id ? `/admin/sodanalysis/${id}`: `/admin/sodanalysis/create`,
    UniqueCodeUpdateAdmin: (id) => id ? `/admin/uniqueCode/${id}` : `/admin/uniqueCode/create`,
    UniqueCodeUpdateTemplateAdmin: "/admin/uniqueCode/:id",
    SodAnalysisTemplateAdmin: "/admin/sodanalysis/:id",
    SodAnalysisListAdmin: "/admin/sodanalysis",
    UniqueCodeAdmin: "/admin/uniqueCode",
    Application: (id) => `/application/${id}`,
    ApplicationTemplate: "/application/:id",
    RoleDetails: (applicationId, roleId) => `/application/${applicationId}/role/${roleId}`,
    RoleDetailsTemplate: "/application/:applicationId/role/:roleId",
    Admin: "/admin",
    Dashboard: '/dashboard',
    Search: '/search',
    DeviceDetails: (id) => `/search/device/${id}`,
    DeviceDetailsTemplate: '/search/device/:id',
    GroupDetails: (id) => `/search/group/${id}`,
    GroupDetailsTemplate: '/search/group/:id',
    HomeTransaction: (transaction, search) => `/search/transaction/${transaction}/searchCriteria/${search}`,
    HomeTransactionTemplate: "/search/transaction/:transaction/searchCriteria/:search",
    Procedures: '/procedures',
    Forbidden: "/forbidden",
    Home: "/",
    Download: (id, name) => `/download/${id}/${name}`,
    DownloadTemplate: '/download/:fileId/:fileName'
}

export const ApplicationRoles = {
    Admin: "Admin",
    Operator: "Operator"
}

export const DefaultValues = {
    DefaultProfilePicture: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHdpZHRoPSI2MDAiIGhlaWdodD0iNjAwIiBmaWxsPSJ3aGl0ZSI+PHNjcmlwdCB4bWxucz0iIj48L3NjcmlwdD4KCiAgPHRpdGxlPkFic3RyYWN0IHVzZXIgaWNvbjwvdGl0bGU+CgogIDxkZWZzPgogICAgPGNsaXBQYXRoIGlkPSJjaXJjdWxhci1ib3JkZXIiPgogICAgICA8Y2lyY2xlIGN4PSIzMDAiIGN5PSIzMDAiIHI9IjI1MCI+PC9jaXJjbGU+CiAgICA8L2NsaXBQYXRoPgogIDwvZGVmcz4KCiAgPGNpcmNsZSBjeD0iMzAwIiBjeT0iMzAwIiByPSIyODAiIGZpbGw9IiNjOGM4YzgiPjwvY2lyY2xlPgogIDxjaXJjbGUgY3g9IjMwMCIgY3k9IjIzMCIgcj0iMTAwIj48L2NpcmNsZT4KICA8Y2lyY2xlIGN4PSIzMDAiIGN5PSI1NTAiIHI9IjE5MCIgY2xpcC1wYXRoPSJ1cmwoI2NpcmN1bGFyLWJvcmRlcikiPjwvY2lyY2xlPgo8L3N2Zz4=",
    DefaltErrorMessage: "Something went wrong with the server",
    DefaultSuccessMessage: "Action completed successfully"
}

export const CustomApplications = {
    Sap: "SAP",
    Beanstore: "Beanstore",
    Btrace: "B.Trace",
    PowerBI: "", //there is no specific string because dev, qta and prd are different
    Salesforce: "Salesforce"
}

export const Formats = {
    DateFormat: 'DD-MM-yyyy',
    SapDateFormat: 'DD-MM-yyyy LT',
}

export const SearchEntities = [
    { key: 0, value: "Transactions", multiple: true },
    { key: 1, value: "Companies", multiple: false },
    { key: 2, value: "Areas", multiple: false },
    { key: 3, value: "Processes", multiple: false },
    { key: 4, value: "Sub Processes", multiple: false },
    { key: 5, value: "Compensatory Controls", multiple: false },
    { key: 6, value: "SoD Activities", multiple: false },
    { key: 7, value: "Sensitive Activities", multiple: false },
    { key: 8, value: "SoD Risks", multiple: false }
];

export const SapCriteriaSearchEntities = SearchEntities.filter(x => x.key != 1 && x.key != 7 && x.key != 8);

export const SearchGroupSource = [
    { key: 0, value: "Cloud" },
    { key: 1, value: "On Premise" }
];

export const SearchGroupGroupTypes = [
    { key: 0, value: "Microsoft 365 groups" },
    { key: 1, value: "Security groups" },
    { key: 2, value: "Mail-enabled security groups" },
    { key: 3, value: "Distribution groups" }
];

export const SodAnalysis = Object.freeze({
    UserTypology: [
        { key: 0, value: "WJ user" },
        { key: 1, value: "TJP user" }
    ],
    StatusTypology: [
        { key: 0, value: "No new risk" },
        { key: 1, value: "New" },
        { key: 2, value: "Waiting for manager's feedback" },
        { key: 3, value: "Approved" },
        { key: 4, value: "Rejected" }
    ]
});

export const GroupSources = {
    Cloud: "Cloud",
    OnPremise: "On Premise"
};

export const SearchCompliant=[
    { key: 0, value: "None" },
    { key: 1, value: "Is Compliant" },
    { key: 2, value: "Not Compliant" },
    { key: 3, value: "N/A" }
];

export const SearchEnabled= [
    { key: 0, value: "None" },
    { key: 1, value: "Is Enabled" },
    { key: 2, value: "Not Enabled" }
];

export const UniqueCodeStatus= [
    { key: 0, value: "Sync" },
    { key: 1, value: "Corrected" },
    { key: 2, value: "ADUser Not Found" },
    { key: 3, value: "Too Many ADUser Found" },
    { key: 4, value: "Missing Data" },
    { key: 5, value: "Changed Data" }
];
import React, { useContext, useMemo } from "react"
import {
    Divider, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Paper, Typography, FormControlLabel, Switch, Accordion,
    AccordionDetails,
    AccordionSummary,
} from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { UserContext } from "../../../../../contexts";
import { Shimmer, KeyValueList, EmptyListItem } from "../../../../../ui-components"
import { AuthorizationObjectTable } from "./AuthorizationObjectTable";
import { OrganizationLevelTable } from "./OrganizationLevelTable";
import { TransactionTable } from "./TransactionTable";
import GroupIcon from '@material-ui/icons/Group';
import { FireFighterTable } from "./FireFighterTable";
import useStyles from "../../../../../styles/useStyles";
import Moment from 'moment';
import { Formats } from '../../../../../utils';

import { StatusIndicator } from '../../../../../ui-components';

const dateFormat = Formats.DateFormat;

const useLocalStyles = makeStyles((theme) => ({
    scrollablePaper: {
        maxHeight: 400,
        overflow: 'auto',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(0)
    }
}));

export const SapTabContent = () => {
    const classes = useStyles();

    const {
        state,
        isLoadingSapUser,
        isLoadingSapRoles,
        toggleTransactionTableClick,
        toggleAuthObjectTableClick,
        toggleOrgLvlTableClick,
        toggleFirefightersTableClick
    } = useContext(UserContext);

    const handleTransactionTableClick = () => {
        toggleTransactionTableClick();
    };

    const handleAuthObjectTableClick = () => {
        toggleAuthObjectTableClick();
    };

    const handleOrgLvlTableClick = () => {
        toggleOrgLvlTableClick();
    };

    const handleFirefightersTable = () => {
        toggleFirefightersTableClick();
    };

    let userData = useMemo(() => getUserData(state.sapUser, state.sapRoles), [state.sapUser, state.sapRoles]);

    // map the dates in the correct format
    if (userData && userData['creationDate'] && userData['creationDate']?.length != dateFormat.length) {
        userData['creationDate'] = Moment(userData['creationDate']).format(dateFormat);
    }
    if (userData && userData['lastLogonDate'] && userData['lastLogonDate']?.length != dateFormat.length) {
        userData['lastLogonDate'] = Moment(userData['lastLogonDate']).format(dateFormat);
    }
    if (userData && userData['lastPasswordLogonDate'] && userData['lastPasswordLogonDate']?.length != dateFormat.length) {
        userData['lastPasswordLogonDate'] = Moment(userData['lastPasswordLogonDate']).format(dateFormat);
    }

    return (<Grid container spacing={3} direction="row" justifyContent="center" alignItems="flex-start">
        <Grid item sm={12} md={5}>
            <Paper className={classes.padding_3}>
                {(isLoadingSapUser && isLoadingSapRoles) ? <Shimmer variant="text" size={10} /> : <>
                    <StatusIndicator
                        accountEnabled={userData.userLockStatus === 0}
                        label={'SAP Account Info'}
                    />
                    <KeyValueList data={userData} labels={[
                        { property: "userType", label: "SAP Account Type" },
                        { property: "userGroup", label: "UserGroup" },
                        { property: "userLockStatus", label: "Lock Status" },
                        { property: "creator", label: "Created by:" },
                        { property: "creationDate", label: "Created on:" },
                        { property: "lastLogonDate", label: "Last Logon Date" },
                        { property: "lastPasswordLogonDate", label: "Last Logon Date by Password:" },
                        { property: "roles", label: "Total of Roles" }
                    ]} />
                </>}
            </Paper>
        </Grid>
        <Grid item sm={12} md={5}>
            <Paper className={classes.padding_2}>
                <SapRoles classes={classes} />
            </Paper>
        </Grid>
        <Grid item sm={12} md={10}>
            <Accordion
                defaultExpanded
                expanded={state.openTransactionTable}
                onChange={handleTransactionTableClick}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.padding_1} variant="h6">
                        <center>Transactions</center>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TransactionTable />
                </AccordionDetails>
            </Accordion>
        </Grid>
        <Grid item sm={12} md={10}>
            <Accordion
                expanded={state.openAuthObjectTable}
                onChange={handleAuthObjectTableClick}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.padding_1} variant="h6">
                        <center>Authorization Objects</center>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <AuthorizationObjectTable />
                </AccordionDetails>
            </Accordion>
        </Grid>
        <Grid item sm={12} md={10}>
            <Accordion
                expanded={state.openOrgLvlTable}
                onChange={handleOrgLvlTableClick}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.padding_1} variant="h6">
                        <center>Organization Levels</center>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <OrganizationLevelTable />
                </AccordionDetails>
            </Accordion>
        </Grid>
        <Grid item sm={12} md={5}>
            <Accordion
                defaultExpanded
                expanded={state.openFirefightersTable}
                onChange={handleFirefightersTable}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.padding_1} variant="h6">
                        <center>Firefighters</center>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FireFighterTable />
                </AccordionDetails>
            </Accordion>
        </Grid>
        <Grid item sm={1} md={5}></Grid>
    </Grid>);
}

const SapRoleList = ({ data, emptyText }) => {
    const classes = useStyles();
    const localClasses = useLocalStyles();

    const notEmpty = data && data.length > 0;

    return <div className={localClasses.scrollablePaper}>
        <List
            className={classes.padding_3}
            aria-labelledby="sap roles"
        >
            {notEmpty > 0 ? data.map((x, i) => <>
                <ListItem
                    key={i}
                >
                    <ListItemIcon
                        key={i}
                    >
                        <GroupIcon />
                    </ListItemIcon>
                    <Grid item xs={12} sm={9} >
                        <ListItemText
                            classes={{ primary: classes.fontDescriptionMedium }}
                            primary={x.roleName} />
                    </Grid>
                    <Grid item xs={12} sm={3} >
                        <ListItemText
                            classes={{ primary: classes.fontDescriptionMedium }}
                            primary={Moment(x.validityToDate).format(dateFormat)} />
                    </Grid>
                </ListItem>
                <Divider />
            </>) : <EmptyListItem text={emptyText} />
            }
        </List>
    </div>;
}

const SapRoles = ({ classes }) => {
    const {
        state,
        isLoadingSapRoles,
        loadSapRoles
    } = useContext(UserContext);

    const [showAll, setShowAll] = React.useState(false);

    const fetchData = React.useCallback(() => {
        const onlyImportant = !showAll;
        loadSapRoles(
            state.accountName,
            25, 0,
            onlyImportant);
    }, [showAll]);

    React.useEffect(() => {
        fetchData();
    }, [fetchData])

    return <>
        <Typography className={classes.padding_3} variant="h6">
            {state.sapRoles.length ?
                <center>Roles ({state.sapRoles.length})</center>
                :
                <center>Roles</center>}
            <FormControlLabel
                control={<Switch color="primary" />}
                checked={showAll}
                onChange={(e) => setShowAll(e.target.checked)}
                label={<Typography style={{ fontSize: 14.5 }}>Show all Roles</Typography>} />
        </Typography>

        {isLoadingSapRoles ?
            <Shimmer variant="text" size={10} /> :
            <SapRoleList data={state.sapRoles} emptyText="account has no sap roles" />}
    </>;
}

function getUserData(sapUserData, sapRoles) {
    sapUserData.roles = sapRoles.length;
    return sapUserData;
}

import React, { useEffect } from 'react'
import { useTable, usePagination, useSortBy } from 'react-table'
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableContainer from "@material-ui/core/TableContainer";
import { TablePaginationActions } from './TablePaginationActions'
import { ProgressBar } from "../ProgressBar";
import { TableToolbar } from './TableToolbar'
import { IconButton, TableSortLabel, Typography } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowDown';
import { TableInfoRow } from './TableInfoRow'

/********************************* IMPORTANTE *************************************
 Questo componente è generico, per questo motivo evitare di incapsulare qui dentro logica specifica di una o più utilizzi concreti. 
 Questo componente non deve essere a coscienza di particolari come le applicazioni o le transazioni, il suo compito è solamente mostrare dei dati in una tabella
 ed esporre funzionalità configurabili per uniformare la code base.
***********************************************************************************/

// Let's add a fetchData method to our Table component that will be used to fetch
// new data when pagination state changes
// We can also add a loading state to let our table know it's loading new data
export function ControlledTable({
    title,
    columns,
    data,
    filters,
    fetchData,
    loading,
    pageCount: controlledPageCount,
    total,
    emptyText,
    preRowActions,
    postRowActions,
    expandable,
    renderExpandedRowContent,
    styledCell
}) {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        gotoPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        // Get the state from the instance
        state: {
            pageIndex,
            pageSize,
            globalFilter,
            sortBy
        },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                hiddenColumns: columns
                    .filter(col => col.show === false)
                    .map(col => col.accessor)
            },
            manualPagination: true, // Tell the usePagination
            // hook that we'll handle our own data fetching
            // This means we'll also have to provide our own
            // pageCount.
            manualSortBy: true,
            autoResetPage: false,
            autoResetSortBy: false,
            pageCount: controlledPageCount,
        },
        useSortBy,
        usePagination
    )

    const StyledTableCell = React.memo((props) => {
        if (styledCell) {
            return styledCell(props)
        }
        return (
            <TableCell {...props} />
        )
    });

    // Listen for changes in pagination and use the state to fetch our new data
    useEffect(() => {
        fetchData({ sortBy, pageIndex, pageSize });
    }, [sortBy, fetchData, pageIndex, pageSize])

    // callback passed as argument to preRowActions and postRowActions,
    // when called at the end of actions executions, this ensures that data are updated automatically
    const onAction = () => fetchData({ sortBy, pageIndex, pageSize });

    // Render the UI for your table
    return (
        <TableContainer>
            <TableToolbar
                preGlobalFilteredRows={preGlobalFilteredRows}
                setGlobalFilter={setGlobalFilter}
                globalFilter={globalFilter}
                filters={filters}
                title={title}
            />
            <MaUTable {...getTableProps()}>
                <TableHead>
                    {headerGroups.map(headerGroup => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {expandable &&
                                <StyledTableCell>
                                    {/* empty cell for actions */}
                                </StyledTableCell>
                            }
                            {preRowActions &&
                                <StyledTableCell>
                                    {/* empty cell for actions */}
                                </StyledTableCell>
                            }
                            {headerGroup.headers.map(column => (
                                <StyledTableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <TableSortLabel
                                        active={column.isSorted}
                                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                                        hideSortIcon={true}
                                    >
                                    </TableSortLabel>
                                </StyledTableCell>
                            ))}
                            {postRowActions &&
                                <StyledTableCell>
                                    {/* empty cell for actions */}
                                </StyledTableCell>
                            }
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    <TableInfoRow loading={loading} total={data.length} />
                    {page.map((row, i) => {
                        prepareRow(row);
                        return <RenderRow
                            row={row}
                            preRowActions={preRowActions}
                            loading={loading}
                            onAction={onAction}
                            expandable={expandable}
                            renderExpandedRowContent={renderExpandedRowContent}
                            postRowActions={postRowActions}
                            Cell={StyledTableCell}
                        />;
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[
                                10,
                                25,
                                50,
                                { label: 'All', value: total },
                            ]}
                            count={total}
                            rowsPerPage={pageSize}
                            page={pageIndex}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={(event, newPage) => {
                                gotoPage(newPage)
                            }}
                            onChangeRowsPerPage={e => {
                                setPageSize(Number(e.target.value))
                            }}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </MaUTable >
        </TableContainer >
    )
}

function RenderRow({ row, preRowActions, loading, onAction, expandable, renderExpandedRowContent, postRowActions, Cell }) {
    const [open, setOpen] = React.useState(false);

    return <>
        <TableRow
            onClick={expandable ? () => setOpen(!open) : undefined}
            hover {...row.getRowProps()}>
            {expandable && <Cell>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </Cell>}
            {preRowActions &&
                <Cell key="actions">
                    {preRowActions(row, loading, onAction)}
                </Cell>}
            {row.cells.map(cell => {
                return (
                    <Cell
                        style={{ fontSize: 13 }}
                        {...cell.getCellProps()}>
                        {cell.render('Cell')}
                    </Cell>
                )
            })}
            {postRowActions &&
                <Cell key="actions">
                    {postRowActions(row, loading, onAction)}
                </Cell>}
        </TableRow>
        {expandable &&
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            {renderExpandedRowContent(row)}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>}
    </>
}
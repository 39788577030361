export const overrideJust = (overridingFields, newData, oldData) => {
    const toRemove = Object.keys(newData)
        .filter(x => !overridingFields.includes(x));

    toRemove.forEach(variableKey => {
        if (newData.hasOwnProperty(variableKey)) {
            delete newData[variableKey];
        }
    });

    return { ...oldData, ...newData };
}
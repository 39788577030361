import React, { useContext, useState } from "react"
import { Button, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Paper, Typography } from "@material-ui/core";
import GroupIcon from '@material-ui/icons/Group';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import Modal from '@material-ui/core/Modal';
import { UserContext } from "../../../../../contexts";
import { Shimmer, KeyValueList, EmptyListItem } from "../../../../../ui-components"
import useStyles from "../../../../../styles/useStyles";
import { useBtrace } from "./useBtrace";
import { StatusIndicator } from '../../../../../ui-components';

const useLocalStyles = makeStyles((theme) => ({
    scrollablePaper: {
        maxHeight: 400,
        overflow: 'auto',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(0)
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'auto',
        borderRadius: '4px'
    },
}));

export const BtraceTabContent = () => {
    const classes = useStyles();
    const localClasses = useLocalStyles();

    const [modalBtraceOpen, setModalBtraceOpen] = useState(false);
    const [modalStyle] = useState(getModalStyle);

    const {
        isLoadingBtraceUser
    } = useContext(UserContext);

    const btraceData = useBtrace();
    if (!btraceData) return <></>

    const { user, place } = btraceData;
    const placeAttributes = user?.data?.placeAttributes;
    const userDataModal = placeAttributes ? JSON.parse(placeAttributes) : {};

    return (
        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="flex-start">
            <Grid item sm={12} md={5}>
                <Paper className={classes.padding_3}>
                    {isLoadingBtraceUser
                        ?
                        <Shimmer variant="text" size={10} />
                        :
                        <>
                            <StatusIndicator
                                accountEnabled={!user.data.locked}
                                tooltip={'Account locked.'}
                                label={'Btrace Info'}
                            />

                            <KeyValueList data={user.data} labels={user.fields} />
                        </>
                    }
                </Paper>
            </Grid>
            <Grid item sm={12} md={5}>
                <Paper className={classes.padding_3}>
                    <BtraceRoles classes={classes} />
                </Paper>
            </Grid>
            <Grid item sm={12} md={5}>
                <Paper className={classes.padding_3}>
                    {isLoadingBtraceUser ? <Shimmer variant="text" size={10} /> : <>
                        <Grid container
                            item direction="row" alignItems="center" sm={12}>
                            <Grid item md={6}>
                                <Typography variant="h6" component="div">
                                    Place
                                </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Button
                                    label="open"
                                    size="small"
                                    aria-label="open"
                                    onClick={() => setModalBtraceOpen(true)}
                                    variant="outlined"
                                    startIcon={<OpenInBrowserIcon />}
                                >
                                    <Typography style={{ fontSize: '10px' }}>attributes</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                        <KeyValueList data={place.data} labels={place.fields} />
                        <Modal
                            open={modalBtraceOpen}
                            onClose={() => setModalBtraceOpen(false)}
                            aria-labelledby="user-ad-properties"
                        >
                            <div style={modalStyle} className={localClasses.paper}>
                                <Typography variant="h6">Place Attributes of user '{place.data.name} {user.data.surname}'</Typography>
                                <KeyValueList
                                    data={userDataModal}
                                    labels={getAttributesLabels(userDataModal)} //labels are dynamically generated
                                />
                            </div>
                        </Modal>
                    </>}
                </Paper>
            </Grid>
            <Grid item sm={1} md={5}></Grid>
        </Grid>
    );
}

const BtraceRoles = ({ classes }) => {
    const {
        isLoadingBtraceUser
    } = useContext(UserContext);

    const btraceData = useBtrace();
    const roles = btraceData?.roles;

    return <>
        <Typography className={classes.padding_3} variant="h6">
            {roles?.length ?
                <center>Roles ({roles.length})</center>
                :
                <center>Roles</center>}
        </Typography>

        {isLoadingBtraceUser ?
            <Shimmer variant="text" size={10} /> :
            <BtraceRoleList data={roles} emptyText="account has no btrace roles" />}
    </>;
}

const BtraceRoleList = ({ data, emptyText }) => {
    const classes = useStyles();
    const localClasses = useLocalStyles();

    const notEmpty = data && data.length > 0;

    return <div className={localClasses.scrollablePaper}>
        <List
            className={classes.padding_3}
            aria-labelledby="btrace roles"
        >
            {notEmpty > 0 ? data.map((x, i) => <>
                <ListItem
                    key={i}
                >
                    <ListItemIcon
                        key={i}
                    >
                        <GroupIcon />
                    </ListItemIcon>
                    <Grid item xs={12} sm={9} >
                        <ListItemText
                            classes={{ primary: classes.fontDescriptionMedium }}
                            primary={x.name} />
                    </Grid>
                    <Grid item xs={12} sm={3} >
                        <ListItemText
                            classes={{ primary: classes.fontDescriptionMedium }}
                            primary={x.description} />
                    </Grid>
                </ListItem>
                <Divider />
            </>) : <EmptyListItem text={emptyText} />
            }
        </List>
    </div>;
}

// dynamically read what properties needs to be displayed in the modal
// because the object could contains different properties for each user
function getAttributesLabels(attributes) {
    let returnObj = [];
    let propNames = [];

    if (attributes) {
        propNames = Object.getOwnPropertyNames(attributes);
    }
    propNames.forEach(item => {
        returnObj.push({ property: item, label: item });
    });
    return returnObj;
}

function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        width: `70%`,
        height: `60%`
    };
}

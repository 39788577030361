import React, { useEffect, useMemo } from "react";
import _ from 'lodash';
import { Grid, Paper, Typography } from '@material-ui/core';
import useStyles from '../../../styles/useStyles';
import useUniqueCode from './hooks/useUniqueCode';
import UniqueCodeTable from './components/UniqueCodeTable';

const UniqueCodeClientAdvisorPage = () => {

    const uniqueCodeHook = useUniqueCode();

    const {
        getUniqueCodes,
        uniqueCodes
    } = uniqueCodeHook;

    const classes = useStyles();

// #todo  va sistemato lo useeffect 
    useEffect(() => {
         getUniqueCodes();
    }, []);

    return (
        <div className={classes.mainSectionWrapper}>
            <Grid container spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="flex-start">
                <Grid container item xs={12}>
                    <Grid item xs={12} className={classes.mainHeader}>
                        <Typography className={classes.fontTitleBig}>
                            <center>Unique Code Client Advisor</center>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} item xs={12} md={10} >
                    {/* <Grid item xs={12}>
                        <Paper>
                            <NewSoDAnalysisForm hook={sodAnalysisHook}
                            />
                        </Paper>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Paper>
                            <UniqueCodeTable
                                title="Unique Code Client Advisor"
                                hook={uniqueCodeHook}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default UniqueCodeClientAdvisorPage;
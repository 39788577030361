import React, { useContext, useMemo } from 'react';
import { ControlledTable } from "../../ui-components/table";
import { ApplicationContext } from "../../contexts";

export const ApplicationTable = (props) => {

    const {
        state,
        isLoadingApplications,
        isAddingAADApplication,
        isRemovingAADApplication,
    } = useContext(ApplicationContext);

    const data = useMemo(() => state.applications.items ?? [], [state.applications.items]);
    const isLoading = isLoadingApplications || isAddingAADApplication || isRemovingAADApplication;

    return <ControlledTable
        title={props.showTitle && "Application List"}
        columns={props.columns}
        data={data}
        fetchData={props.fetchData}
        loading={isLoading}
        pageCount={(Math.floor(state.applications.totalItems / state.applications.pageSize) + 1) ?? 0}
        total={state.applications.totalItems ?? 0}
        filters={props.filters}
        postRowActions={props.postRowActions}
    />
}
import { useState } from 'react';

import { ApiRoutes, ApiClient } from "../../../utils";

export const useDevice = () => {
    const [device, setDevice] = useState();
    const [deviceLoading, setDeviceLoading] = useState(false);

    const handleDevice = async (params) => {
        setDeviceLoading(true);

        ApiClient.post(`${ApiRoutes.Search.SearchDeviceById()}?id=${params.id}`)
            .then((response) => {
                setDevice(response.data);
            })
            .catch((reason) => {
                setDevice(undefined);
            })
            .finally(() => {
                setDeviceLoading(false);
            });
    }

    return {
        handleDevice,
        device,
        deviceLoading
    }
}
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { InsightContext } from '../../../../contexts';
import { ApiRoutes, ApiClient, DefaultValues } from "../../../../utils";

const useSodAnalysis = () => {
    const { trackError } = useContext(InsightContext);
    const { enqueueSnackbar } = useSnackbar();

    const [sodAnalyses, setSodAnalyses] = useState([]);
    const [sodAnalysesLoading, setSodAnalysesLoading] = useState(false);
    const getSodAnalyses = async () => {
        setSodAnalysesLoading(true);

        ApiClient.get(ApiRoutes.SodAnalysis.RestResource())
            .then((response) => {
                setSodAnalyses(response.data);
            })
            .catch((reason) => {
                trackError(reason);
                setSodAnalyses([]);
                enqueueSnackbar(DefaultValues.DefaltErrorMessage);
            })
            .finally(() => {
                setSodAnalysesLoading(false);
            });

    };

    const [sodAnalysis, setSodAnalysis] = useState();
    const [sodAnalysisLoading, setSodAnalysisLoading] = useState(false);
    const getSodAnalysis = async (id) => {
        setSodAnalysisLoading(true);

        ApiClient.get(ApiRoutes.SodAnalysis.RestResource(id))
            .then((response) => {
                getUsersInvolved(response.data.tjPs, response.data.tjPs?.map(x => x.roleName));
                setSodAnalysis(response.data);
                getCompany(response.data.user.companyName);
                getManagersEmail(response.data.user.id);
            })
            .catch((reason) => {
                trackError(reason);
                setSodAnalysis();
                enqueueSnackbar(DefaultValues.DefaltErrorMessage);
            })
            .finally(() => {
                setSodAnalysisLoading(false);
            });
    };
    const cleanSodAnalysis = () => setSodAnalysis();

    const [deleting, setDeleting] = useState(false);
    const deleteSodAnalysis = async (id) => {
        setDeleting(true);

        ApiClient.delete(ApiRoutes.SodAnalysis.RestResource(id))
            .then((response) => {
                console.dir(response.data);
                enqueueSnackbar("SoD Analysis deleted", { variant: "success" });
            })
            .catch((reason) => {
                trackError(reason);
                enqueueSnackbar(DefaultValues.DefaltErrorMessage);
            })
            .finally(() => {
                setDeleting(false);
            });
    };

    const [appendingAttachments, setAppendingAttachments] = useState(false);
    const appendAttachments = async (id, files) => {
        setAppendingAttachments(true);

        const data = new FormData();
        files.forEach(file => {
            data.append("files", file);
        });

        ApiClient.post(ApiRoutes.File.AppendSodAnalysisFiles({ sodAnalysisId: id }), data)
            .then((response) => {
                console.dir(response.data);
                enqueueSnackbar("SoD Analysis attachment uploaded", { variant: "success" });
                return response.data;
            })
            .catch((reason) => {
                trackError(reason);
                enqueueSnackbar(DefaultValues.DefaltErrorMessage);
            })
            .finally(() => {
                setAppendingAttachments(false);
            });
    };

    const [deletingAttachment, setDeletingAttachment] = useState(false);
    const deleteAttachment = async (id, fileId) => {
        setDeletingAttachment(true);

        ApiClient.delete(ApiRoutes.File.DeleteSodAnalysisFile({ sodAnalysisId: id, fileId: fileId }))
            .then((response) => {
                console.dir(response.data);
                enqueueSnackbar("SoD Analysis attachment deleted", { variant: "success" });
            })
            .catch((reason) => {
                trackError(reason);
                enqueueSnackbar(DefaultValues.DefaltErrorMessage);
            })
            .finally(() => {
                setDeletingAttachment(false);
            });
    };

    const [attachments, setAttachments] = useState([]);
    const [loadingAttachments, setLoadingAttachments] = useState(false);
    const getSodAnalysisAttachments = async (id) => {
        setLoadingAttachments(true);

        ApiClient.get(ApiRoutes.SodAnalysis.Attachments(id))
            .then((response) => {
                setAttachments(response.data);
            })
            .catch((reason) => {
                trackError(reason);
                setAttachments([]);
                enqueueSnackbar(DefaultValues.DefaltErrorMessage);
            })
            .finally(() => {
                setLoadingAttachments(false);
            });
    };

    const [newSoDAnalysis, setNewSoDAnalysis] = useState();
    const [loadingNewSoDAnalysis, setLoadingNewSoDAnalysis] = useState(false);
    const [newSoDAnalysisCompleted, setNewSoDAnalysisCompleted] = useState(false);
    const getNewSoDAnalysis = async (newSoDAnalysisInput) => {
        setLoadingNewSoDAnalysis(true);

        if (newSoDAnalysisInput.Owner === undefined || newSoDAnalysisInput.Owner === null
            || newSoDAnalysisInput.Transactions === undefined || newSoDAnalysisInput.Transactions?.length === 0
            || newSoDAnalysisInput.IncidentNumber === '' || newSoDAnalysisInput.IncidentNumber === undefined) {
            enqueueSnackbar("Missing Data");
            setLoadingNewSoDAnalysis(false);
        }
        else {
            enqueueSnackbar("SoD Analysis Started", { variant: "success" });
            ApiClient.post(ApiRoutes.SodAnalysis.GetNewSoDAnalysis(), newSoDAnalysisInput)
                .then((response) => {
                    setNewSoDAnalysis(response.data);
                    if (response.data) {
                        getSodAnalyses();
                        enqueueSnackbar("SoD Analysis Completed", { variant: "success" });
                        setNewSoDAnalysisCompleted(true);
                        return true;
                    }
                })
                .catch((reason) => {
                    trackError(reason);
                    enqueueSnackbar(DefaultValues.DefaltErrorMessage);
                })
                .finally(() => {
                    setNewSoDAnalysisCompleted(true);
                    setLoadingNewSoDAnalysis(false);
                });
        }
    };

    const [loadingUpdateAnalysisState, setLoadingUpdateAnalysisState] = useState(false);
    const updateAnalysisState = async (id, status, action) => {
        setLoadingUpdateAnalysisState(true);
        const data = {
            Id: id,
            Status: status,
            Action: action
        };
        ApiClient.post(ApiRoutes.SodAnalysis.UpdateSodAnalysisStatus(), data)
            .then((response) => {
                if (response.data) {
                    setSodAnalysis(response.data);
                    let txt = "Sending Email..";
                    switch (status) {
                        case 3: txt = "SoD Analysis approved!"; break;
                        case 4: txt = "SoD Analysis rejected!"; break;
                    }
                    enqueueSnackbar(txt, { variant: "success" });
                    setLoadingUpdateAnalysisState(true);
                    return true;
                }
            })
            .catch((reason) => {
                trackError(reason);
                enqueueSnackbar(DefaultValues.DefaltErrorMessage);
            })
            .finally(() => {
                setNewSoDAnalysisCompleted(true);
                setLoadingUpdateAnalysisState(false);
            });
    };

    const [managersEmail, setManagersEmail] = useState();
    const getManagersEmail = async (userId) => {
        const data = { IdString: userId }
        ApiClient.post(ApiRoutes.SodAnalysis.GetManagersEmail(), data)
            .then((response) => {
                setManagersEmail(response.data.mail);
                return true;
            })
            .catch((reason) => {
                trackError(reason);
                enqueueSnackbar(DefaultValues.DefaltErrorMessage);
            })
            .finally(() => {
            });

    };

    const [company, setCompany] = useState();
    const getCompany = async (id) => {
        const data = { IdString: id }
        ApiClient.post(ApiRoutes.SodAnalysis.GetCompany(), data)
            .then((response) => {
                setCompany(response.data);
                return true;
            })
            .catch((reason) => {
                trackError(reason);
                enqueueSnackbar(DefaultValues.DefaltErrorMessage);
            })
            .finally(() => {
            });
    };

    const [usersInvolved, setUsersInvolved] = useState([]);
    const [loadingUsersInvolved,setloadingUsersInvolved ] = useState(false);
    const [updatePage, setUpdatePage] = useState(false);
    const getUsersInvolved = async (tjPs, role) => {
        try {
            setloadingUsersInvolved(true);
            const data = role.map(x => x.replace('&', '%26'));
            data.forEach(async element => {
                const r = await ApiClient.get(ApiRoutes.SodAnalysis.GetInvolvedUsers({ roleName: element }));
                const items = usersInvolved;
                const dataFronSodAnalysis = tjPs?.find(x => x.roleName === element.replace('%26','&'));
                r.data?.items.map(x => {
                    const newItem = {
                        ...x,
                        roleName: dataFronSodAnalysis.roleName,
                        isActive: dataFronSodAnalysis.isActive,
                        validityFromDate: dataFronSodAnalysis.validityFromDate,
                        validityToDate: dataFronSodAnalysis.validityToDate
                    }
                    items?.push(newItem)
                }
                );
                setUsersInvolved(items);
                setUpdatePage(!updatePage);
            });

        } catch (e) {
            setloadingUsersInvolved(false);
            trackError(e);
        }
    }

    return {
        getSodAnalyses,
        sodAnalyses,
        sodAnalysesLoading,

        getSodAnalysis,
        cleanSodAnalysis,
        sodAnalysis,
        sodAnalysisLoading,

        deleteSodAnalysis,
        deleting,

        appendAttachments,
        attachments,
        appendingAttachments,

        deleteAttachment,
        deletingAttachment,

        getSodAnalysisAttachments,
        loadingAttachments,

        newSoDAnalysis,
        getNewSoDAnalysis,
        loadingNewSoDAnalysis,
        loadingUpdateAnalysisState,

        newSoDAnalysisCompleted,
        setNewSoDAnalysisCompleted,

        updateAnalysisState,
        getManagersEmail,
        getCompany,
        company,
        managersEmail,
        getUsersInvolved,
        usersInvolved,
        updatePage ,
        loadingUsersInvolved
    };
};

export default useSodAnalysis;
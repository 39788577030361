import _ from "lodash";
import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
    Accordion, AccordionActions, AccordionDetails,
    AccordionSummary, Button,
    Divider,
    FormControlLabel,
    Grid,
    Switch,
    TextField,
    Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SearchContext } from '../../../../../contexts';
import {
    SearchCompliant, SearchEnabled,
} from "../../../../../utils";
import { useADUsersOptions } from '../../../../../hooks';
import useStyles from "../../../../../styles/useStyles";

const DeviceCriteriaForm = (props) => {
    const {
        handleSubmit,
        getValues,
        formState: { errors },
        control
    } = useForm();

    const {
        state,
        toggleOpenSearch
    } = useContext(SearchContext);

    const {
        fetchUserOptions,
        userOptions,
        loadingUserOptions
    } = useADUsersOptions();

    const classes = useStyles();

    const searchOptions = async (e, searchText) => {
        e && e.type !== "click" && await fetchUserOptions(searchText, 25);
    }

    const renderOptionLabel = option => `${option?.displayName} - ${option?.samAccountName}`;

    const _handleSubmit = (formValues) => {
        const isCompliant = formValues.isCompliant?.key == 0 ?
            undefined : formValues.isCompliant?.key;

        const isEnabled = formValues.isEnabled?.key == 1 ?
            true : formValues.IsEnabled?.key === 2 ?
                false : undefined;

        props.onSubmit({
            displayName: formValues.name,
            operatingSystem: formValues.operatingSystem,
            ids: formValues.owner ? [formValues.owner.id] : [],
            owner: formValues.owner && formValues.owner.samAccountName,
            accountEnabled: isEnabled,
            isCompliant: isCompliant
        });
    }

    return (
        <form onSubmit={handleSubmit(_handleSubmit)}>
            <Accordion
                defaultExpanded
                expanded={state.openSearch}
                onChange={() => toggleOpenSearch()}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">
                        <center>Devices</center>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={12} className={classes.searchFormItems}>
                            <Controller
                                control={control}
                                name="name"
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        size="small"
                                        label="Name"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        autoComplete="off"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.searchFormItems}>
                            <Controller
                                control={control}
                                name="operatingSystem"
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        size="small"
                                        label="Operating System"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        autoComplete="off"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.searchFormItems}>
                            <Controller
                                control={control}
                                name="owner"
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        onChange={(event, item) => {
                                            onChange(item);
                                        }}
                                        value={value}
                                        size="small"
                                        options={userOptions}
                                        getOptionLabel={renderOptionLabel}
                                        getOptionSelected={(option, value) =>
                                            value === undefined || value === "" || option.key === value.key
                                        }
                                        loading={loadingUserOptions}
                                        onInputChange={_.debounce(searchOptions, 600)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Owner"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                InputLabelProps={{
                                                    style: { fontSize: 14 }
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.searchFormItems}>
                            <Controller
                                control={control}
                                name="isEnabled"
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        onChange={(ev, item) => onChange(item)}
                                        value={value}
                                        size="small"
                                        options={SearchEnabled}
                                        getOptionLabel={(option) => option.value || ''}
                                        getOptionSelected={(option, value) =>
                                            value === undefined || value === "" || option.key === value.key
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Is Enabled"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                InputLabelProps={{
                                                    style: { fontSize: 14 }
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.searchFormItems}>
                            <Controller
                                control={control}
                                name="isCompliant"
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        onChange={(ev, item) => onChange(item)}
                                        value={value}
                                        size="small"
                                        options={SearchCompliant}
                                        getOptionLabel={(option) => option.value || ''}
                                        getOptionSelected={(option, value) =>
                                            value === undefined || value === "" || option.key === value.key
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Is Compliant"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                InputLabelProps={{ style: { fontSize: 14 } }}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
                <Divider />
                <AccordionActions>
                    <Grid container justifyContent="center">
                        <Button
                            color='primary'
                            size='medium'
                            type='submit'
                            variant='contained'
                            disabled={props.isLoading}
                        >
                            SEARCH
                        </Button>
                    </Grid>
                </AccordionActions>
            </Accordion>
        </form>
    );
}

export default DeviceCriteriaForm;
import {
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Card, Grid, Typography
} from '@material-ui/core';
import useStyles from "../../../../styles/useStyles";
import { StatusIndicator } from "../../../../ui-components";
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useMemo } from "react";

function createUsersInvolved(userName, roleName, isActive, validityFromDate, validityToDate) {
    return { userName, roleName, isActive, validityFromDate, validityToDate };
}

const getUsers = (usersInvolved) => {
    if (usersInvolved !== undefined && usersInvolved?.length !== 0) {
        return usersInvolved?.map(y =>
            createUsersInvolved(
                y.displayName,
                y.roleName,
                y.isActive,
                y.validityFromDate,
                y.validityToDate));
    }
};

const CardUsersInvolvedDetail = ({ loadingUsersInvolved, updatePage, usersInvolved }) => {
    const classes = useStyles();
    const users = useMemo(() => getUsers(usersInvolved), [updatePage, usersInvolved]);

    const renderUsersInvolved = () => {
        if (users === undefined) {
            return (
                <center><CircularProgress className={classes.circularProgress} /></center>);
        } else if (users !== undefined && users.length !== 0) {
            return (
                <Card className={classes.padding_3} >
                    <Grid  >
                        <Typography variant="h5">Users Involved</Typography>
                        <Paper>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>User</TableCell>
                                            <TableCell>Role</TableCell>
                                            <TableCell>Is Active</TableCell>
                                            <TableCell>Validity From Date</TableCell>
                                            <TableCell>Validity To Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {users?.map((row) => (
                                            <TableRow key={row.name}>
                                                <TableCell component="th" scope="row">{row.userName}</TableCell>
                                                <TableCell component="th" scope="row">{row.roleName}</TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <StatusIndicator accountEnabled={!row.isActive} />
                                                    </div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">{row.validityFromDate}</TableCell>
                                                <TableCell component="th" scope="row">{row.validityToDate}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Card>);
        }
        else
            return (<></>);
    };

    return (renderUsersInvolved());
}
export default CardUsersInvolvedDetail;
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Button, Divider, Typography } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';

const styles = theme => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper
    },
    deep: {
        paddingLeft: theme.spacing.unit * 4
    },
    deeper: {
        paddingLeft: theme.spacing.unit * 8
    },
    childItemRoot: {
        backgroundColor: theme.palette.gray.light,
    },
    nieceItemRoot: {
        backgroundColor: theme.palette.gray.main,
    },
    scrollablePaperBig: {
        maxHeight: 800,
        overflow: 'auto',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(0)
    },
    title: {
        padding: theme.spacing(3)
    }
});

class DeepList extends React.Component {
    state = { open: {} };

    handleClick = (key, layer, ancestor) => () => {
        this.setState({ [String(key).concat(layer).concat(ancestor)]: !this.state[String(key).concat(layer).concat(ancestor)] });
    };

    render() {
        const {
            data,
            classes,
            title,
            renderItem,
            renderChild,
            renderNiece,
            emptyText,
            download
        } = this.props;
        const notEmpty = data && data.length > 0;

        const getItems = (item) => item?.items ?? [];

        return (
            <div className={classes.root}>
                <Typography className={classes.title} variant="h6">
                    <center>{title}</center>
                    {data && data.length > 0 &&
                        <Button
                            size="small"
                            color="primary"
                            onClick={(data) => download(data)}
                            variant="contained"
                            startIcon={<GetAppIcon />}
                        >
                            <Typography style={{ fontSize: '10px' }}>export</Typography>
                        </Button>}
                </Typography>
                <List
                    component="nav"
                    className={classes.scrollablePaperBig}
                    aria-labelledby={title}
                >
                    {notEmpty ? data.map(item => {
                        const itemKey = item.id;
                        const itemOpen = this.state[String(itemKey).concat(0).concat(0)] || false;
                        const childItems = getItems(item);

                        return (
                            <div key={itemKey}>
                                <ListItem
                                    key={itemKey}
                                    button
                                    // selected={itemOpen}
                                    onClick={this.handleClick(itemKey, 0, 0)}>
                                    {item.icon &&
                                        <ListItemIcon>
                                            <item.icon />
                                        </ListItemIcon>}
                                    <ListItemText primary={renderItem ? renderItem(item) : item.label} />
                                    {childItems.length > 0 && itemOpen ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Divider />
                                {(childItems.length > 0) &&
                                    <Collapse in={itemOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {childItems.map((child) => {
                                                const childKey = child.id;
                                                const childOpen = this.state[String(childKey).concat(1).concat(itemKey)] || false;
                                                const nieceItems = getItems(child);

                                                return (
                                                    <>
                                                        <ListItem
                                                            key={childKey}
                                                            button
                                                            // selected={childOpen}
                                                            classes={{ root: classes.childItemRoot }}
                                                            className={classes.deep}
                                                            onClick={this.handleClick(childKey, 1, itemKey)}>
                                                            {child.icon &&
                                                                <ListItemIcon>
                                                                    <child.icon />
                                                                </ListItemIcon>}
                                                            <ListItemText primary={renderChild ? renderChild(child) : child.label} />
                                                            {nieceItems.length > 0 && childOpen ? <ExpandLess /> : <ExpandMore />}
                                                        </ListItem>
                                                        <Divider />
                                                        {(nieceItems.length > 0) &&
                                                            <Collapse in={childOpen} timeout="auto" unmountOnExit>
                                                                <List component="div" disablePadding>
                                                                    {nieceItems.map((niece) => {
                                                                        const nieceKey = child.id;

                                                                        return (
                                                                            <>
                                                                                <ListItem
                                                                                    key={nieceKey}
                                                                                    classes={{ root: classes.nieceItemRoot }}
                                                                                    className={classes.deeper}>
                                                                                    {niece.icon &&
                                                                                        <ListItemIcon>
                                                                                            <niece.icon />
                                                                                        </ListItemIcon>}
                                                                                    <ListItemText primary={renderNiece ? renderNiece(niece) : niece.label} />
                                                                                </ListItem>
                                                                                <Divider />
                                                                            </>
                                                                        );
                                                                    })}
                                                                </List>
                                                            </Collapse>}
                                                    </>
                                                );
                                            })}
                                        </List>
                                    </Collapse>}
                            </div>
                        );
                    }) : <ListItem>
                        <ListItemText key="empty-item" secondary={<>
                            <Typography style={{ fontWeight: 'bold', padding: '30px' }} variant="body2">
                                <center>{emptyText ?? "no data"}</center>
                            </Typography></>} />
                    </ListItem>}
                </List>
            </div>
        );
    }
}

DeepList.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DeepList);

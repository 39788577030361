import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import {
    Button,
    Chip,
    Grid, makeStyles, Paper,
    TableBody,
    TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip,
    Typography
} from '@material-ui/core';
import MaUTable from '@material-ui/core/Table';
import GetAppIcon from '@material-ui/icons/GetApp';
import GroupIcon from '@material-ui/icons/Group';

import { excelExportFromMaUTable, GroupSources, NavRoutes, truncateString } from "../../../../utils";

import { TablePaginationActions, TableToolbar } from '../../../../ui-components/table';

const useLocalStyles = makeStyles((theme) => ({
    displayName: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
        alignItems: 'center',
        flexDirection: 'row'
    },
    searchLegendaTitle: {
        fontSize: 13,
        textAlign: 'center'
    },
    chip: {
        padding: 5,
        margin: 3
    },
    tableFooter: {
        position: 'relative',
        right: '30%'
    }
}));

export const GroupChildsTable = (props) => {

    const data = useMemo(() => props.groups, [props.groups]);

    const localClasses = useLocalStyles();

    const columns = useMemo(
        () => [
            {
                Header: 'Group Name',
                accessor: 'displayName',
                Cell: ({ row, value }) => {
                    const { original: rowData } = row;

                    return (
                        <div className={localClasses.displayName}>
                            <Link
                                to={NavRoutes.GroupDetails(rowData.id)}
                                style={{ color: 'inherit' }}
                            >
                                <GroupIcon />
                            </Link>

                            <Typography
                                noWrap
                                component={Link}
                                to={NavRoutes.GroupDetails(rowData.id)}
                                style={{
                                    color: 'inherit',
                                    textDecoration: 'inherit',
                                    fontSize: 14
                                }}
                            >
                                {value}
                            </Typography>
                        </div>
                    );
                }
            },
            {
                Header: 'Source',
                accessor: 'source',
                Cell: ({ row, value }) => (
                    <Tooltip title={value || ''}>
                        <Typography style={{ fontSize: 14 }} noWrap>
                            {(row.values.onPremisesDomainName === undefined || row.values.onPremisesDomainName === null || row.values.onPremisesDomainName === '' ||
                                 row.values.onPremisesDomainName.toLowerCase() !== "gold.bulgari.group") ?
                                'Cloud' : 'On Premise'}
                        </Typography>
                    </Tooltip>
                )
            },
            { Header: 'Account', accessor: 'onPremisesSamAccountName' },
            {
                Header: 'Created at',
                accessor: 'createdDateTime',
                Cell: ({ value }) => (
                    <Tooltip title={value || ''}>
                        <Typography style={{ fontSize: 14 }} noWrap>
                            {new Date(value).toLocaleDateString()}
                        </Typography>
                    </Tooltip>
                )
            },
            { Header: 'Email nickname', accessor: 'mailNickname' },
            {
                Header: 'Group Description',
                accessor: 'description',
                Cell: ({ value }) => (
                    <Tooltip title={value || ''}>
                        <Typography style={{ fontSize: 14 }} noWrap>
                            {truncateString(value || '', 25)}
                        </Typography>
                    </Tooltip>
                )
            },
            { Header: 'id', accessor: 'id' },
            { Header: 'onPremisesDomainName', accessor: 'onPremisesDomainName'}
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        gotoPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: {
            pageIndex,
            pageSize,
            globalFilter
        },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                hiddenColumns: ["id","onPremisesDomainName"]
            }
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const handleChangePage = (event, newPage) => {
        gotoPage(newPage);
    }

    const handleChangeRowsPerPage = event => {
        setPageSize(Number(event.target.value));
    }

    const totalItems = useMemo(() => rows.length, [rows]);

    const handleExcelExportFromMaUTable = (data, columns, category, fileName) => {

        data.forEach(g => {
            if (g.values.onPremisesDomainName === undefined || g.values.onPremisesDomainName === null || g.values.onPremisesDomainName === '' ||
                g.values.onPremisesDomainName.toLowerCase() !== "gold.bulgari.group")
                g.values.source = GroupSources.Cloud;
            else
                g.values.source = GroupSources.OnPremise;
        });

        excelExportFromMaUTable(data, columns, category, fileName);
    }

    return (
        <Paper>
            <TableContainer>
                <TableToolbar
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    setGlobalFilter={(aa) => setGlobalFilter(aa)}
                    globalFilter={globalFilter}
                    placeholderText="groups..."
                    title={props.title}
                >
                    <Button
                        color="primary"
                        onClick={() => {
                            const fileName = `Group '${props.groupName}' children -`;
                            handleExcelExportFromMaUTable(rows, columns, 'Groups', fileName)
                        }}
                        variant="contained"
                        disabled={!rows.length}
                        startIcon={<GetAppIcon />}
                    >
                        Export
                    </Button>
                </TableToolbar>

                <MaUTable {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map(headerGroup => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <TableCell {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {rows.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize).map((row, i) => {
                            prepareRow(row);

                            return (
                                <TableRow hover {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <TableCell style={{ height: 12 }}{...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[
                                    10,
                                    25,
                                    50,
                                    { label: 'All', value: totalItems },
                                ]}
                                className={localClasses.tableFooter}
                                count={totalItems}
                                rowsPerPage={pageSize}
                                page={pageIndex}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </MaUTable>
            </TableContainer>
        </Paper>
    );
}

const FilterSummary = props => {
    const localClasses = useLocalStyles();

    return (
        <Grid container xs={12} spacing={5} >
            <Grid item xs={6} md={2} >
                <Typography className={localClasses.searchLegendaTitle}>
                    Submitted filters:
                </Typography>
            </Grid >
            <Grid spacing={2} item xs={6} md={9}>
                {props.filters?.length > 0 && props.filters.filter(x => x.value).map(x => {
                    if (Array.isArray(x.value))
                        return x.value.map((item, index) => <Chip className={localClasses.chip} label={x.label + ": " + item} />);
                    return <Chip className={localClasses.chip} label={x.label + ": " + x.value} />;
                })}
                {props.children}
            </Grid>
        </Grid>
    );
}
import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, withStyles } from '@material-ui/core';
import { Check } from '@material-ui/icons';

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
});

const LoadingButton2 = (props) => {
    const { classes, loading, done, title, ...other } = props;

    if (done) {
        return (
            <Button className={classes.button} {...other} disabled>
                <Check />
            </Button>
        );
    }
    else if (loading) {
        return (
            <Button className={classes.button} {...other}>
                <>
                    {title}
                    <CircularProgress style={{ marginLeft: 5 }} size="1.4em" color="black" />
                </>
            </Button>
        );
    } else {
        return (
            <Button className={classes.button} {...other}>
                {title}
            </Button>
        );
    }
}

LoadingButton2.defaultProps = {
    loading: false,
    done: false,
};

LoadingButton2.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    done: PropTypes.bool,
};

export default withStyles(styles)(LoadingButton2);
import React from 'react'
import { GlobalFilter } from './'
import { lighten, makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import { Typography } from '@material-ui/core'

const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 75%',
    },
}))

export const TableToolbar = props => {
    const classes = useToolbarStyles()

    const {
        preGlobalFilteredRows,
        setGlobalFilter,
        globalFilter,
        placeholderText,
        filters: CustomFilters,
        title,
        children
    } = props;

    if (title || children || preGlobalFilteredRows || globalFilter || setGlobalFilter || CustomFilters)
        return (
            <Toolbar className={classes.root}>
                {title &&
                    <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                        {title}
                    </Typography>}
                {preGlobalFilteredRows &&
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        placeholderText={placeholderText}
                    />}
                {CustomFilters &&
                    <CustomFilters />}
                {children}
            </Toolbar>
        )
    return <></>
}
import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, CircularProgress } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing.unit,
    }
}));

const LoadingButton = (props) => {
    const { loading, children, ...other } = props;
    const classes = useStyles();

    if (loading) {
        return (
            <Button className={classes.root} {...other}>
                <CircularProgress />
            </Button>
        );
    } else {
        return (
            <Button className={classes.root} {...other} />
        );
    }
}

LoadingButton.defaultProps = {
    loading: false,
};

LoadingButton.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool,
};

export default LoadingButton;
import React from "react";
import SearchIcon from '@material-ui/icons/Search';
import { Controller, useForm } from "react-hook-form";
import { Button, Grid, TextField } from "@material-ui/core";

// Form with an editable list of filterproperty object.
// Next to each input are buttons for insert and remove.
// If the list is empty, there is a button to add an item.
export const SimplifiedFilter = ({ onSubmit }) => {
    const {
        control,
        handleSubmit,
    } = useForm();

    return (
        <form onSubmit={handleSubmit(data => onSubmit(data.searchFilter))}>
            <Grid container spacing={1} direction="row" justifyContent="flex-end" style={{ paddingTop: '9px' }}>
                <Grid item xs={7}>
                    <Controller
                        render={({ field }) =>
                            <TextField
                                {...field}
                                inputProps={{
                                    style: { height: '10px' }
                                }}
                                variant="outlined"
                                size="small"
                            />}
                        name={`searchFilter`}
                        control={control}
                    />
                </Grid>
                <Grid item xs={5}>
                    <Button
                        size="small"
                        variant="outlined"
                        type="submit"
                        startIcon={<SearchIcon />}
                    >search</Button>
                </Grid>
            </Grid>
        </form>
    );
};

import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    Button,
    Modal,
    Switch,
    Divider,
    makeStyles,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    TextField,
    Typography,
    Tooltip,
    IconButton,
    ListItemSecondaryAction
} from "@material-ui/core";
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import GroupIcon from '@material-ui/icons/Group';
import GetAppIcon from '@material-ui/icons/GetApp';
import { UserContext } from '../../../../contexts';
import { NavRoutes, downloadUserDetailsAD } from '../../../../utils';
import { ApplicationTabsLookup } from '../applications/applicationTabsLookup';
import useStyles from '../../../../styles/useStyles';
import { KeyValueList, Shimmer, EmptyListItem } from '../../../../ui-components';
import { UserAvatar } from '../../UserAvatar';
import { StatusIndicator } from '../../../../ui-components';

const useLocalStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'auto',
        borderRadius: '4px'
    },
}));

export const AADInfoTabContent = () => {
    const classes = useStyles();
    const localClasses = useLocalStyles();
    const {
        state,
        isLoadingUser,
        isLoadingUserMemberships,
    } = useContext(UserContext);
    const { user, memberships, aadGroups } = state;

    const allAadGroup = useMemo(() => GetAADGroups(aadGroups), [aadGroups]);

    const applicationData = useMemo(() => getApplicationData(memberships), [memberships]);

    const {
        profilePicture,
        ...userProperties
    } = user;

    const [modalOpen, setModalOpen] = useState(false);
    const [modalStyle] = useState(getModalStyle);

    const userLabels1 = [
        { property: "firstName", label: "First Name" },
        { property: "lastName", label: "Last Name" },
        { property: "accountName", label: "Account Name" },
        { property: "employeeId", label: "CID (or User ID)" },
        { property: "eType", label: "Account Type", tooltipText: "Defines if STORE or OFFICE User" },
        { property: "mail", label: "Mail" },
        { property: "companyName", label: "Company" },
        { property: "department", label: "Department" },
        { property: "jobTitle", label: "Job Title" },
        { property: "country", label: "Country/Region" },
        { property: "city", label: "City" },
        { property: "accountDisabled", label: "Account Disabled" },
        { property: "adExpirationDate", label: "AD Expiration Date" },
        { property: "adLock", label: "AD User Locked" },
        { property: "adPasswordNeverExpires", label: "AD Password Never Expires" }
    ];

    const userLabels2 = [
        {
            property: "id",
            label: "AD Account ID"
        },
        {
            property: "canonicalName",
            label: "OU"
        },
        {
            property: "adCreationDate",
            label: "AD Creation Date"
        },
        {
            property: "createdDateTime",
            label: "AzureAD Account Created on"
        },
        {
            property: "manager",
            label: "Hierarchical Manager"
        },
        {
            property: "beanstoreId",
            label: "Beanstore ID"
        },
        {
            property: "otherCompanies",
            label: "Other Companies"
        },
        {
            property: "otherStore",
            label: "Other Store"
        },
        {
            property: "lastPositionChangeDate",
            label: "Last Position Change Date"
        },
        {
            property: "passwordNeverExpires",
            label: "Password never expires"
        },
        {
            property: "workplaceCode",
            label: "Workplace Code"
        },
        {
            property: "workplaceName",
            label: "Workplace Name"
        },
        {
            property: "workplaceAddress",
            label: "Workplace Address"
        },
        {
            property: "companyCode",
            label: "Company Code"
        },
        {
            property: "idStore",
            label: "ID Store"
        },
        {
            property: "positionStartDate",
            label: "Position Start Date"
        },
        {
            property: "costCenter",
            label: "Cost Center"
        },
        {
            property: "companyStartDate",
            label: "Company Start Date"
        },
        {
            property: "function",
            label: "SAP HR Function"
        },
        {
            property: "subFunction",
            label: "SAP HR SubFunction"
        },
        {
            property: "adLastLoginDate",
            label: "AD Last Login Date"
        }
    ]

    const downloadData = {
        accountData: {
            data: userProperties,
            fields: [...userLabels1, ...userLabels2]
        },
        applicationsData: {
            data: applicationData,
            fields: [
                {
                    property: "applicationId",
                    label: "Id"
                },
                {
                    property: "applicationName",
                    label: "Name"
                },
                {
                    property: "applicationDisplayName",
                    label: "Display Name"
                },
                {
                    property: "applicationDescription",
                    label: "Description"
                }
            ]
        },
        adGroupsData: {
            data: allAadGroup,
            fields: [
                {
                    property: "objectId",
                    label: "AD ObjectId"
                },
                {
                    property: "aadName",
                    label: "AD Name"
                },
                {
                    property: "name",
                    label: "Internal Name"
                },
                {
                    property: "description",
                    label: "Description"
                },
                {
                    property: "aadDescription",
                    label: "AD Description"
                },
                {
                    property: "isAzureGroup",
                    label: "Is Azure Group"
                }
            ]
        }
    }

    return (
        <Grid container spacing={3} justifyContent="center">
            <Grid item sm={12} md={5}>
                <Paper className={classes.padding_3}>
                    <Grid container item sm={12}>
                        <Grid container item direction="row" alignItems="center" sm={12}>
                            <Grid item sm={12} md={7}>
                                {isLoadingUser
                                    ?
                                    <Shimmer variant="circle" width={8 * 8} height={8 * 8} />
                                    :
                                    <UserAvatar />
                                }
                            </Grid>
                            <Grid item sm={6} md={3}>
                                {isLoadingUser
                                    ?
                                    <Shimmer variant="text" />
                                    :
                                    <Button
                                        label="open"
                                        size="small"
                                        aria-label="open"
                                        onClick={() => setModalOpen(true)}
                                        variant="outlined"
                                        startIcon={<OpenInBrowserIcon />}
                                    >
                                        <Typography style={{ fontSize: '10px' }}>more details</Typography>
                                    </Button>
                                }
                            </Grid>
                            <Grid item sm={6} md={2}>
                                {isLoadingUser ?
                                    <Shimmer variant="text" /> :
                                    <Button
                                        size="small"
                                        color="primary"
                                        onClick={() => downloadUserDetailsAD(downloadData)}
                                        variant="contained"
                                        startIcon={<GetAppIcon />}
                                    >
                                        <Typography style={{ fontSize: '10px' }}>export</Typography>
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                        <Grid item sm={12}>
                            {isLoadingUser ?
                                <>
                                    <Shimmer variant="rect" height={400} />
                                </> :
                                <>
                                    <KeyValueList
                                        data={userProperties}
                                        labels={userLabels1}
                                    />
                                    <Modal
                                        open={modalOpen}
                                        onClose={() => setModalOpen(false)}
                                        aria-labelledby="user-ad-properties"
                                    >
                                        <div style={modalStyle} className={localClasses.paper}>
                                            <Typography variant="h6">
                                                Additional information of {user.displayName}
                                            </Typography>
                                            <KeyValueList
                                                data={userProperties}
                                                labels={userLabels2}
                                            />
                                        </div>
                                    </Modal>
                                </>}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item sm={12} md={5}>
                <Paper>
                    <Typography className={classes.padding_3} variant="h6">
                        {applicationData.length ?
                            <center>Applications ({applicationData.length})</center>
                            :
                            <center>Applications</center>}
                    </Typography>
                    {isLoadingUserMemberships ?
                        <Shimmer variant="text" size={10} />
                        :
                        <ApplicationList
                            data={applicationData}
                            emptyText="account has no applications"
                        />}
                </Paper>
            </Grid>
            <Grid item sm={12} md={10}>
                <Paper>
                    <Typography className={classes.padding_3} variant="h6">
                        {allAadGroup.length ?
                            <center>Direct AD Groups ({allAadGroup.length})</center>
                            :
                            <center>Direct AD Groups</center>
                        }
                    </Typography>
                    {isLoadingUser ?
                        <Shimmer variant="text" size={10} />
                        :
                        <AADGroupList
                            data={allAadGroup}
                            emptyText="account has AD groups assigned"
                        />}
                </Paper>
            </Grid>
        </Grid>
    );
}

const ApplicationList = ({ data, emptyText }) => {
    const classes = useStyles();
    const notEmpty = data && data.length > 0;
    const history = useHistory();

    const {
        setTab,
        setApplicationTab
    } = useContext(UserContext);

    const goToApplicationTab = (appName) => {
        //index 1 is the application tabs' father
        setTab(1);

        setApplicationTab(ApplicationTabsLookup.find(x => x.app === appName)?.app ?? appName);
    };

    return <div className={classes.scrollablePaper}>
        <List
            aria-labelledby="applications"
            className={classes.padding_3}
        >
            {notEmpty > 0
                ?
                data.map(x => (
                    <>
                        <ListItem
                            key={x.applicationId}
                            button
                            onClick={() => goToApplicationTab(x.applicationName)}
                        >
                            <ListItemIcon>
                                <DesktopMacIcon />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ primary: classes.fontTitleMedium }}
                                style={{ maxWidth: 250 }}
                                primary={x.applicationDisplayName}
                            />
                            <StatusIndicator
                                accountEnabled={x.isActive}
                                variant="body1"
                                label={x.isActive ? 'Active' : 'Inactive'}
                            />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="open" onClick={() => history.push(NavRoutes.Application(x.applicationId))}>
                                    <Tooltip title="View Application details">
                                        <OpenInBrowserIcon />
                                    </Tooltip>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                    </>
                ))
                :
                <EmptyListItem text={emptyText} />
            }
        </List>
    </div>;
}

const AADGroupList = ({ data, emptyText }) => {
    const {
        state
    } = useContext(UserContext);

    const history = useHistory();

    const [groupFilter, setGroupFilter] = useState(null);
    const [azureADGroupsCount, setAzureADGroupsCount] = useState(0);
    const [showAzureADGroups, setShowAzureADGroups] = useState(false);


    const filterGroups = () => {
        var filter = groupFilter;
        var showAd = showAzureADGroups;
        let items = state.aadGroups;

        setAzureADGroupsCount(items.filter(x => x.isAzureGroup).length);

        if (filter && items?.length) {
            let filteredItems = items.filter(x => x.aadDescription?.toLowerCase()?.includes(filter) || x.aadName?.toLowerCase()?.includes(filter));
            // undefined ===  false
            if (showAd === undefined || showAd === false) {
                filteredItems = filteredItems.filter(x => x.isAzureGroup === false);
            }

            return filteredItems;
        }
        if (showAd === undefined || showAd === false)
            return items?.filter(x => x.isAzureGroup === false);

        return items;
    }

    const aadGroupItems = useMemo(() => filterGroups(), [groupFilter, showAzureADGroups]);

    const classes = useStyles();

    const setGroupFilterItems = (filter) => {
        if (filter && filter?.target?.value) {
            filter = filter.target.value.toLowerCase();
            setGroupFilter(filter);
        }
        else {
            setGroupFilter(null);
        }
    }

    const handleAdGroupSwitch = (event) => {
        if (event.target.checked !== undefined) {
            setShowAzureADGroups(event.target.checked);
        }
    }

    const notEmpty = data && data.length > 0;

    return (
        <div>
            <Grid container spacing={5}>
                <Grid item xs={6}>
                    <div className={classes.search}>
                        <TextField
                            variant="standard"
                            type="search"
                            value={groupFilter}
                            onChange={(value) => { setGroupFilterItems(value) }}
                            placeholder="Search"
                            fullWidth
                        />
                    </div>
                </Grid>
                {azureADGroupsCount > 0 &&
                    <Grid item xs={6}>
                        <div className={classes.displayInRow}>
                            <Switch
                                color="primary"
                                checked={showAzureADGroups}
                                onChange={handleAdGroupSwitch}
                            />
                            <Typography style={{ fontSize: 12 }}>Show Azure AD groups ({azureADGroupsCount})</Typography>
                        </div>
                    </Grid>}
            </Grid>
            <div className={classes.scrollablePaper}>
                <List
                    className={classes.padding_3}
                    aria-labelledby="aad-groups">
                    {notEmpty ? aadGroupItems.map(x => <>
                        <ListItem 
                            key={x.objectId}
                            button
                            onClick={() => history.push(NavRoutes.GroupDetails(x.objectId))}
                            >
                            <ListItemIcon>
                                {x.isAzureGroup ?
                                    <Tooltip title="Azure Active Directory">
                                        <GroupIcon />
                                    </Tooltip>
                                    :
                                    <Tooltip title="Active Directory">
                                        <PeopleAltOutlinedIcon />
                                    </Tooltip>
                                }
                            </ListItemIcon>
                            <ListItemText
                                classes={{ primary: classes.fontTitleMedium }}
                                primary={x.aadName}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            className={classes.fontDescription}
                                        >
                                            {x.aadDescription}
                                        </Typography>
                                    </React.Fragment>} />
                        </ListItem>
                        <Divider />
                    </>
                    ) : <EmptyListItem text={emptyText} />}
                </List>
            </div>
        </div>);
}

function getApplicationData(memberships) {
    let helper = {};

    return memberships.reduce(function (acc, value) {
        if (acc.length === undefined)
            acc = []; // why acc is not an array???
        const key = value.applicationId;
        // Group initialization
        if (!helper[key]) {
            helper[key] = {
                applicationName: value.applicationName,
                applicationDisplayName: value.applicationDisplayName,
                applicationId: value.applicationId,
                applicationDescription: value.applicationDescription,
                isActive: value.isActive,
                roles: []
            };
            acc.push(helper[key]);
        }
        else {
            // Grouping
            helper[key].roles.push(value);
        }

        return acc;
    }, {}) ?? [];
}

function GetAADGroups(aadGroupsFiltered) {
    return aadGroupsFiltered?.sort((a, b) => a.aadName.localeCompare(b.aadName)) ?? [];
}

function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        width: `70%`,
        height: `60%`
    };
}
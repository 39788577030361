import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, TextField } from '@material-ui/core';
import { ApplicationContext } from "../../../contexts";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Controller, useForm } from 'react-hook-form';
import { FieldError } from "../../../ui-components/FieldError";
import _ from "lodash";
import { truncateString, CustomApplications } from '../../../utils';

const useStyles = makeStyles(theme => ({
    container: {
        minWidth: 500
    },
    circularProgress: {
        margin: "15px 0px",
        marginLeft: "50%",
        transform: "translateX(-50%)",
        animation: "none"
    },
    loaderText: {
        color: theme.palette.primary.main,
        marginLeft: "50%",
        transform: "translateX(-50%)",
        fontSize: "18px",
        //textShadow: "-0.1px 0 black, 0 0.1px black, 0.1px 0 black, 0 -0.1px black"
    }
}));

export function RoleFormDialog({ open, handleClose, handleAdd, handleEdit, role }) {
    const classes = useStyles();
    const {
        state,
        isLoadingGroups,
        loadGroupOptions,
        loadSodActivitiesOptions,
        loadPowerBIExtraFields
    } = useContext(ApplicationContext);

    const {
        application,
        powerBIExtraFields
    } = state;

    const applicationName = state.application?.name;
    const editMode = role !== null;
    const isAADRole = state.application?.owner === 1;
    const [searchTextRole, setSearchTextRole] = useState();
    const [searchTextSod, setSearchTextSod] = useState();
    const [isPowerBI, setIsPowerBI] = useState(false);
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState([]);
    const [authInfoObjects, setAuthInfoObject] = useState([]);
    const [authValues, setAuthValue] = useState([]);

    const defaultValues = {
        role: defaultRole(),
        displayName: role?.displayName ?? "",
        description: role?.description ?? "",
        important: role?.important ?? false,
        // eslint-disable-next-line eqeqeq
        sodActivities: role?.sodActivitiesInfo ?? []
    };

    const {
        handleSubmit,
        setValue,
        control,
        reset,
        formState: { errors }
    } = useForm({ defaultValues });

    useEffect(() => {
        if (isAADRole && open)
            loadGroupOptions(state.application.id, searchTextRole);
    }, [searchTextRole, open, isAADRole]);

    // load options for select dropdown, except sap
    useEffect(() => {
        if (applicationName !== CustomApplications.Sap)
            loadSodActivitiesOptions(searchTextSod);
    }, [searchTextSod]);

    useEffect(() => {
        if (role || open)
            reset(defaultValues);
    }, [role, open]);

    useEffect(() => {
        if (application){
            setIsPowerBI(application.isPowerBIApplication);

            if (application.isPowerBIApplication)
                loadExtraData();
        }
    }, [application]);

    const loadExtraData = async () => {
        setLoading(true);
        await loadPowerBIExtraFields();
        setLoading(false);
    }

    useEffect(() => {
        if (powerBIExtraFields && powerBIExtraFields.length > 0){
            const cleanedRoles = cleanOptions("role");
            const cleanedAuthInfoObjects = cleanOptions("authInfoObject");
            const cleanedAuthValues = cleanOptions("authValue");

            setRoles(cleanedRoles);
            setAuthInfoObject(cleanedAuthInfoObjects);
            setAuthValue(cleanedAuthValues);
        }
        else {
            setRoles([]);
            setAuthInfoObject([]);
            setAuthValue([]);
        }
    }, [powerBIExtraFields]);

    const cleanOptions = propertyName => {
        const wantedProperty = powerBIExtraFields.map(x => x[propertyName]);
        return wantedProperty
            .filter((item, pos) => item && wantedProperty.indexOf(item) === pos) //not null value and distinct
            .sort()
            .map(x => ({ value: x, label: x })); //MenuItem's wanted format
    }

    const handleSearchRole = async (event, text) => {
        if (event && event.type !== "click")
            setSearchTextRole(text);
    }

    const handleSearchSodActivity = async (event, text) => {
        if (event && event.type !== "click")
            setSearchTextSod(text);
    }

    const onSubmit = values => {
        if (!editMode)
            handleAdd(values);
        else {
            const currentRole = application.roles.filter(x => x.id == role.id)[0];
            handleEdit(values, isPowerBI, currentRole.pbiRole, currentRole.pbiAuthInfoObject, currentRole.pbiAuthValue);
        }

        handleClose();
    }

    return (
        <Dialog disableBackdropClick open={open} onClose={handleClose}>
            <DialogTitle>Configure the application role</DialogTitle>
            <form className={classes.container} onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Grid container>
                        {
                            isAADRole &&
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field }) =>
                                        <Autocomplete
                                            {...field}
                                            disabled={editMode}
                                            options={state.groups}
                                            getOptionLabel={option => option?.displayName ?? ""}
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            loading={isLoadingGroups}
                                            onInputChange={_.debounce(handleSearchRole, 600)}
                                            onChange={(e, option) => setValue('role', option)}
                                            renderInput={params =>
                                                <TextField
                                                    {...params}
                                                    autoFocus
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    label="AD Group *"
                                                />
                                            }
                                        />
                                    }
                                    name="role"
                                    rules={{ required: true }}
                                    control={control}
                                />
                                <FieldError error={errors?.role} customMessage="AD Group is required" />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Controller
                                name="displayName"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) =>
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="Display Name *"
                                    />
                                }
                            />
                            <FieldError error={errors?.displayName} customMessage="Display Name is required" />
                        </Grid>

                        {
                            isPowerBI &&
                            (
                                loading ?
                                <>
                                    <CircularProgress className={classes.circularProgress} />
                                    <div className={classes.loaderText}>Retrieving PowerBI data...</div>
                                </> :
                                powerBIExtraFields &&
                                <>
                                    <TextField
                                        defaultValue={role && application.roles.filter(x => x.id == role.id)[0].pbiRole}
                                        select
                                        required
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="Role"
                                        name="pbiRole"
                                        onChange={(e, option) => setValue('pbiRole', option.props.value)}
                                    >
                                        {
                                            roles.map(option =>
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            )
                                        }
                                    </TextField>

                                    <TextField
                                        defaultValue={role && application.roles.filter(x => x.id == role.id)[0].pbiAuthInfoObject}
                                        select
                                        required
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="AuthInfoObject"
                                        name="pbiAuthInfoObject"
                                        onChange={(e, option) => setValue('pbiAuthInfoObject', option.props.value)}
                                    >
                                        {
                                            authInfoObjects.map(option =>
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            )
                                        }
                                    </TextField>

                                    <TextField
                                        defaultValue={role && application.roles.filter(x => x.id == role.id)[0].pbiAuthValue}
                                        select
                                        required
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="AuthValue"
                                        name="pbiAuthValue"
                                        onChange={(e, option) => setValue('pbiAuthValue', option.props.value)}
                                    >
                                        {
                                            authValues.map(option =>
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            )
                                        }
                                    </TextField>
                                </>
                            )
                        }

                        <Grid item xs={12}>
                            <Controller
                                name="description"
                                control={control}
                                render={({ field }) =>
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="Description"
                                    />
                                }
                            />
                        </Grid>

                        {
                            applicationName === CustomApplications.Sap &&
                            <Grid item xs={12}>
                                <FormControlLabel
                                    label={"CORE"}
                                    control={
                                        <Controller
                                            name={"important"}
                                            control={control}
                                            render={({ field }) =>
                                                <Switch
                                                    color="primary"
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={event => field.onChange(event.target.checked)}
                                                />
                                            }
                                        />
                                    }
                                />
                            </Grid>
                        }

                        {
                            applicationName !== CustomApplications.Sap &&
                            <Grid item xs={12}>
                                <Controller
                                    name="sodActivities"
                                    control={control}
                                    render={({ field }) =>
                                        <Autocomplete
                                            {...field}
                                            multiple
                                            options={state.sodActivities}
                                            getOptionLabel={option => (option?.value && truncateString(`${option.value} - ${option.description}`, 60)) ?? ""}
                                            getOptionSelected={(option, value) => option.key === value?.key}
                                            onChange={(e, options) => {
                                                setSearchTextSod(null);
                                                return setValue('sodActivities', options);
                                            }}
                                            onInputChange={_.debounce(handleSearchSodActivity, 600)}
                                            renderInput={params =>
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    label="SoD Activities"
                                                />
                                            }
                                        />
                                    }
                                />
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        type='submit'
                        color="primary"
                    >
                        Ok
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );

    function defaultRole() {
        if (!role)
            return null;

        return {
            id: role?.id,
            displayName: role?.name,
            pbiRole: role?.pbiRole,
            pbiAuthInfoObject: role?.pbiAuthInfoObject,
            pbiAuthValue: role?.pbiAuthValue
        };
    }
}

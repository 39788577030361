import { useEffect } from 'react';
import _ from 'lodash';

import { Grid } from '@material-ui/core';

import useStyles from '../../styles/useStyles';
import { useDashboard } from './hooks/useDashboard';

import PieChart from './components/PieChart/pie-chart';

const DashboardPage = (props) => {
    const {
        handleETypeReports,
        eTypeReports,
        eTypeReportsLoading
    } = useDashboard();

    const _handleEmployeeDataset = () => {
        let dataset = _.filter(eTypeReports, entry => entry?.eType === 'OFFICE' || entry.eType === 'STORE');

        return _.map(dataset, entry => ({
            label: entry.eType,
            count: Math.round(entry.count || 0)
        }));
    }

    const _handleExternalDataset = () => {
        let dataset = _.filter(eTypeReports, entry => entry?.eType?.startsWith('EXT-'));

        return _.map(dataset, entry => ({
            label: entry.eType,
            count: Math.round(entry.count || 0)
        }));
    }

    const _handleTechnicalDataset = () => {
        let dataset = _.filter(eTypeReports, entry => entry?.eType?.startsWith('TEC-'));

        return _.map(dataset, entry => ({
            label: entry.eType,
            count: Math.round(entry.count || 0)
        }));
    }

    const classes = useStyles();

    useEffect(() => {
        handleETypeReports();
    }, []);

    return (
        <div className={classes.mainSectionWrapper}>
            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container spacing={5} item xs={12} md={10} className={classes.criteriaContainer}>
                    <Grid item xs={12} md={4}>
                        <PieChart
                            heading={'Employee AD Account'}
                            dataLoading={eTypeReportsLoading}
                            dataSource={_handleEmployeeDataset()}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <PieChart
                            heading={'WJ & External Users'}
                            dataLoading={eTypeReportsLoading}
                            dataSource={_handleExternalDataset()}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <PieChart
                            heading={'Technical Account'}
                            dataLoading={eTypeReportsLoading}
                            dataSource={_handleTechnicalDataset()}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default DashboardPage;
import React, { useContext, useState } from 'react';
import { InsightContext } from "./InsightContext";
import { DefaultValues, isNullOrWhiteSpace, useAggregatedState } from "../utils";
import { Formats, ApiRoutes, ApiClient } from "../utils";
import { useSnackbar } from 'notistack';
import Moment from 'moment';

export const ApplicationContext = React.createContext(null);

const { Provider } = ApplicationContext;
export const ApplicationProvider = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const { trackError } = useContext(InsightContext);

    const [state, setState] = useAggregatedState({
        procedures: [],
        applications: [],
        application: null,
        groups: [],
        sodActivities: [],
        availableApplications: [],
        role: null,
        roleMembers: [],
        files: [],
        powerBIExtraFields: null
    });

    const [isLoadingIdentity, setIsLoadingIdentity] = useState(false);

    const toggleOpenIdentityClick = () => {
        setState({
            openIdentityClick: !state.openIdentityClick
        });
    }

    const toggleAppRolesTableClick = () => {
        setState({
            openAppRolesTable: !state.openAppRolesTable
        });
    }

    const toggleOpenProceduresClick = () => {
        setState({
            openProceduresClick: !state.openProceduresClick
        });
    }

    const toggleOpenAuthenticationsClick = () => {
        setState({
            openAuthenticationsClick: !state.openAuthenticationsClick
        });
    }

    const [isLoadingProcedures, setIsLoadingProcedures] = useState(false);
    const loadProcedures = async (pageSize, pageIndex) => {
        await executeOperation(async () => {
            const r = await ApiClient.get(ApiRoutes.Procedure.GetProcedures({ size: pageSize, page: pageIndex }));
            setState({
                procedures: r.data
            });
        }, setIsLoadingProcedures);
    }
    const cleanProcedures = () => {
        setState({
            procedures: []
        })
    }

    const [isLoadingApplications, setIsLoadingApplications] = useState(false);
    const loadApplications = async (pageSize, pageIndex, filter) => {
        await executeOperation(async () => {
            const r = await ApiClient.get(ApiRoutes.Admin.GetApplications({ size: pageSize, page: pageIndex, filter }));
            setState({
                applications: r.data
            });
        }, setIsLoadingApplications);
    }
    const loadRunningApplications = async (pageSize, pageIndex, filter, withAnyProcedures = false) => {
        await executeOperation(async () => {
            const r = await ApiClient.get(ApiRoutes.Admin.GetRunningApplications({ size: pageSize, page: pageIndex, filter, withAnyProcedures }));
            setState({
                applications: r.data
            });
        }, setIsLoadingApplications);
    }
    const cleanApplications = () => {
        setState({
            applications: []
        })
    }

    const [isLoadingApplication, setIsLoadingApplication] = useState(false);
    const loadApplication = async (id) => {
        await executeOperation(async () => {
            const r = await ApiClient.get(ApiRoutes.Admin.GetApplication({ id }));
            setState({
                application: r.data
            });
        }, setIsLoadingApplication);
    }
    const loadRunningApplication = async (id) => {
        await executeOperation(async () => {
            const r = await ApiClient.get(ApiRoutes.Admin.GetRunningApplication({ id }));
            setState({
                application: r.data
            });
        }, setIsLoadingApplication);
    }
    const cleanApplication = () => {
        setState({
            application: null
        })
    }

    const loadApplicationFiles = async (appId) => {
        await executeOperation(async () => {
            const r = await ApiClient.get(ApiRoutes.File.GetApplicationFiles({ appId }));
            setState({
                files: r.data
            });
        }, setIsLoadingProcedures);
    }
    const cleanAppFiles = () => {
        setState({
            files: []
        })
    }

    const getApplicationFiles = async (appId) => {
        try {
            setIsLoadingProcedures(true);

            const r = await ApiClient.get(ApiRoutes.File.GetApplicationFiles({ appId }));
            setIsLoadingProcedures(false);

            return r.data;

        } catch (e) {
            trackError(e);
            setIsLoadingProcedures(false);
        }
    }

    const [isLoadingRole, setIsLoadingRole] = useState(false);
    const loadRole = async (applicationId, roleId) => {
        await executeOperation(async () => {
            const r = await ApiClient.get(ApiRoutes.Admin.GetRoleInfo({ roleId, applicationId }));
            setState({
                role: r.data
            });
        }, setIsLoadingRole);
    }
    const cleanRole = () => {
        setState({
            role: null
        })
    }

    const [isLoadingRoleMembers, setIsLoadingRoleMembers] = useState(false);
    const loadRoleMembers = async (roleId) => {
        await executeOperation(async () => {
            const r = await ApiClient.get(ApiRoutes.Admin.GetRoleMembers({ roleId }));
            setState({
                roleMembers: r.data
            });
        }, setIsLoadingRoleMembers);
    }
    const cleanRoleMembers = () => {
        setState({
            roleMembers: []
        })
    }

    const [isRemovingAADRole, setIsRemovingAADRole] = useState(false);
    const removeAADRole = async (applicationId, roleId) => {
        await executeOperation(async () => {
            const r = await ApiClient.put(ApiRoutes.Admin.RemoveAADRole({ applicationId, roleId }));
            setState({
                application: r.data
            });
        }, setIsRemovingAADRole, "Role removed");
    }

    const [isAddingAADRole, setIsAddingAADRole] = useState(false);
    const addAADRole = async (data) => {
        await executeOperation(async () => {
            const r = await ApiClient.put(ApiRoutes.Admin.AddAADRole(), data);
            setState({
                application: r.data
            });
        }, setIsAddingAADRole, "Role added");
    }

    const [isUpdatingRole, setIsUpdatingRole] = useState(false);
    const updateRole = async (data) => {
        await executeOperation(async () => {
            const r = await ApiClient.put(ApiRoutes.Admin.UpdateRole(), data);
            setState({
                application: r.data
            });
        }, setIsUpdatingRole, "Role updated");
    }

    const [isLoadingGroups, setIsLoadingGroups] = useState(false);
    const loadGroupOptions = async (applicationId, searchText) => {
        await executeOperation(async () => {
            const r = await ApiClient.get(ApiRoutes.Search.GetGroupsOptionsForApplication({ applicationId, searchText }));
            setState({
                groups: r.data
            });
        }, setIsLoadingGroups);
    };

    const [isLoadingSodActivities, setIsLoadingSodActivities] = useState(false);
    const loadSodActivitiesOptions = async (searchText) => {
        await executeOperation(async () => {
            const r = await ApiClient.get(ApiRoutes.Search.GetEntityOptions({ entityName: 6, codeQuery: searchText }));
            setState({
                sodActivities: r.data
            });
        }, setIsLoadingSodActivities);
    };
    const cleanSodActivitiesOption = () => {
        setState({
            sodActivities: []
        })
    };

    const [isAddingAADApplication, setIsAddingAADApplication] = useState(false);
    const addAADApplication = async (data) => {
        await executeOperation(async () => {
            await ApiClient.post(ApiRoutes.Admin.AddAADApplication(), data);

        }, setIsAddingAADApplication, "Application created");
    }

    const [isUpdatingApplication, setIsUpdatingApplication] = useState(false);
    const updateApplication = async (application) => {
        await executeOperation(async () => {
            const r = await ApiClient.put(ApiRoutes.Admin.UpdateApplication(), application);
            setState({ application: r.data });
        }, setIsUpdatingApplication, "Application updated");
    }

    const [isRemovingAADApplication, setIsRemovingAADApplication] = useState(false);
    const removeAADApplication = async (applicationId) => {
        await executeOperation(async () => {
            await ApiClient.delete(ApiRoutes.Admin.DeleteAADApplication({ id: applicationId }));
        }, setIsRemovingAADApplication, "Application removed");
    }

    const [isLoadingAvailableAppOptions, setIsLoadingAvailableAppOptions] = useState(false);
    const loadAvailableAppOptions = async () => {
        await executeOperation(async () => {
            const r = await ApiClient.get(ApiRoutes.Search.GetAvailableApplicationOptions());
            setState({
                availableApplications: r.data
            });
        }, setIsLoadingAvailableAppOptions);
    };

    const [isUploadingProcedures, setIsUploadingProcedures] = useState(false);
    const uploadProcedures = async (category, appId, files) => {
        await executeOperation(async () => {
            for (const data of files) {
                const file = new FormData();
                file.append("file", data);
                await ApiClient.post(ApiRoutes.File.UploadApplicationFile({ category, appId }), file);
            }

            await loadApplicationFiles(appId);
        }, setIsUploadingProcedures, files.length > 1 ? "Files uploaded" : "File uploaded");
    }

    const [isDownloadingProcedure, setIsDownloadingProcedure] = useState(false);
    const downloadProcedure = async (fileId, fileName, target = '_blank') => {
        try {
            setIsDownloadingProcedure(true);

            const config = { responseType: 'blob' };
            ApiClient.get(ApiRoutes.File.DownloadFile({ fileId }), config)
                .then(response => {
                    const extension = fileName.split('.').pop();
                    let new_blob;
                    if (extension.toLowerCase() === "pdf") {
                        new_blob = new Blob([response.data], { type: 'application/pdf' });
                    }
                    else {
                        new_blob = new Blob([response.data]);
                    }

                    const url = URL.createObjectURL(new_blob);
                    const link = document.createElement('a');
                    link.href = url;

                    if (extension.toLowerCase() === 'pdf') {
                        link.setAttribute('target', target);
                    }
                    else {
                        link.setAttribute('download', fileName);
                    }
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    setIsDownloadingProcedure(false);

                    enqueueSnackbar("File downloaded", { variant: "success" });
                });
        } catch (e) {
            trackError(e);
            setIsDownloadingProcedure(false);
        }
    }

    const downloadProcedureById = async (id, target = '_blank') => {
        try {
            setIsDownloadingProcedure(true);
            ApiClient.get(ApiRoutes.SodAnalysis.RestResource(id))
                .then((response) => {

                    const dateFormat = Formats.DateFormat;
                    const fileName = (response.data.user.displayName ?? "user") + " SoD Analysis Detail " + Moment(response.data.createDateUtc).format(dateFormat) + ".xlsx";
                    const fileId = response.data.storedFileId;
                    const config = { responseType: 'blob' };

                    ApiClient.get(ApiRoutes.File.DownloadFile({ fileId }), config)
                        .then(response2 => {
                            const extension = fileName.split('.').pop();
                            let new_blob;
                            if (extension.toLowerCase() === "pdf") {
                                new_blob = new Blob([response2.data], { type: 'application/pdf' });
                            }
                            else {
                                new_blob = new Blob([response2.data]);
                            }

                            const url = URL.createObjectURL(new_blob);
                            const link = document.createElement('a');
                            link.href = url;

                            if (extension.toLowerCase() === 'pdf') {
                                link.setAttribute('target', target);
                            }
                            else {
                                link.setAttribute('download', fileName);
                            }
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                            setIsDownloadingProcedure(false);

                            enqueueSnackbar("File downloaded", { variant: "success" });
                        });

                })
                .catch((reason) => {
                    trackError(reason);
                    enqueueSnackbar(DefaultValues.DefaltErrorMessage);
                })

        } catch (e) {
            trackError(e);
            setIsDownloadingProcedure(false);
        }
    }



    const [isUpdatingProcedures, setIsUpdatingProcedures] = useState(false);
    const deleteProcedure = async (fileId, appId) => {
        try {
            setIsUpdatingProcedures(true);
            await ApiClient.delete(ApiRoutes.File.DeleteApplicationFile({ fileId, appId }));

            // remove the file from the state
            const files = state.files.filter(function (obj) {
                return obj.fileId !== fileId;
            });

            setState({ files: files })

            enqueueSnackbar("File removed", { variant: "success" });
            setIsUpdatingProcedures(false);

            return files;

        } catch (e) {
            trackError(e);
            setIsUpdatingProcedures(false);
        }
    }

    const toggleVisibilityClick = async (fileId, value) => {
        try {
            setIsUpdatingProcedures(true);
            let visible = !value;

            await ApiClient.put(ApiRoutes.File.SetVisibility({ fileId, visible }));

            // change visibility of file in state
            const files = state.files.map(f => f.fileId === fileId ?
                { ...f, isVisible: !f.isVisible } : f);
            setState({ files: files })

            enqueueSnackbar("Visibility changed", { variant: "success" });
            setIsUpdatingProcedures(false);

            return files;
        } catch (e) {
            trackError(e);
            setIsUpdatingProcedures(false);
        }
    }

    const executeOperation = async (action, loaderSetter, successMessage, errorMessage) => {
        try {
            loaderSetter(true);

            await action();

            if (!isNullOrWhiteSpace(successMessage))
                enqueueSnackbar(successMessage, { variant: "success" });

            loaderSetter(false);
        } catch (error) {
            console.log(JSON.stringify(error, null, 4));
            trackError(error);
            enqueueSnackbar(errorMessage ?? DefaultValues.DefaltErrorMessage);
            loaderSetter(false);
        }
    }

    const loadPowerBIExtraFields = async () => {
        try {
            const extraFields = await ApiClient.get(ApiRoutes.Search.GetPowerBIExtraFields());

            if (extraFields && extraFields.data)
                setState({ powerBIExtraFields: extraFields.data });
        } catch (error) {
            console.log(JSON.stringify(error, null, 4));
            trackError(error);
            enqueueSnackbar(error ?? DefaultValues.DefaltErrorMessage);
        }
    }

    return (
        <Provider value={{
            state,
            isLoadingApplications,
            loadApplications,
            loadRunningApplications,
            cleanApplications,
            isLoadingApplication,
            loadApplication,
            loadRunningApplication,
            cleanApplication,
            isRemovingAADRole,
            removeAADRole,
            isAddingAADRole,
            addAADRole,
            isUpdatingRole,
            updateRole,
            isLoadingGroups,
            loadGroupOptions,
            isLoadingSodActivities,
            loadSodActivitiesOptions,
            isAddingAADApplication,
            addAADApplication,
            isUpdatingApplication,
            updateApplication,
            isRemovingAADApplication,
            removeAADApplication,
            isLoadingAvailableAppOptions,
            loadAvailableAppOptions,
            loadRole,
            cleanRole,
            isLoadingRole,
            setIsLoadingRole,
            loadRoleMembers,
            cleanRoleMembers,
            isLoadingRoleMembers,
            setIsLoadingRoleMembers,
            cleanSodActivitiesOption,
            toggleAppRolesTableClick,
            loadApplicationFiles,
            getApplicationFiles,
            cleanAppFiles,
            toggleOpenProceduresClick,
            toggleOpenAuthenticationsClick,
            toggleOpenIdentityClick,
            isLoadingIdentity,
            isLoadingProcedures,
            loadProcedures,
            cleanProcedures,
            isUploadingProcedures,
            uploadProcedures,
            isUpdatingProcedures,
            deleteProcedure,
            toggleVisibilityClick,
            isDownloadingProcedure,
            downloadProcedure,
            loadPowerBIExtraFields,
            downloadProcedureById
        }}>
            {props.children}
        </Provider>
    );
}
import { TableRow, Typography } from "@material-ui/core"
import { ProgressBar } from "../ProgressBar"

export const TableInfoRow = ({ loading, total, emptyText }) => {
    if (loading)
        return <TableRow>
            <td colSpan="10000">
                <ProgressBar loading={loading} />
            </td>
        </TableRow>
    if (!loading && total === 0)
        return <TableRow>
            <td colSpan="10000">
                <Typography style={{ fontWeight: 'bold', padding: '30px' }} variant="body2"><center>{emptyText ?? "no data"}</center></Typography>
            </td>
        </TableRow>
    return null;
}
import { useState, useEffect, useContext, useMemo } from 'react';
import { UserContext } from '../../../../../contexts';
import { Formats } from '../../../../../utils';
import Moment from 'moment';

export function useSalesforce() {
    const [hookState, setHookState] = useState();

    const {
        state
    } = useContext(UserContext);

    const salesforceData = useMemo(() => {
        let user = JSON.parse(JSON.stringify(state.salesforceUser)) // deep clone
        if (state.salesforceUser.userLastModifiedDate)
            user.userLastModifiedDate = Moment(state.salesforceUser.userLastModifiedDate, 'DD/MM/YYYY').format(Formats.DateFormat)
        if (state.salesforceUser.userLastLoginDate)
            user.userLastLoginDate = Moment(state.salesforceUser.userLastLoginDate, 'DD/MM/YYYY').format(Formats.DateFormat)
        if (state.salesforceUser.userCreatedDate)
            user.userCreatedDate = Moment(state.salesforceUser.userCreatedDate, 'DD/MM/YYYY').format(Formats.DateFormat)
        return {
            user: {
                data: user,
                fields: [
                    { property: "samlId", label: "SAML ID" },
                    { property: "fullName", label: "Full Name" },
                    { property: "username", label: "Username" },
                    { property: "adfsId", label: "ADFS ID" },
                    { property: "isActive", label: "Active" },
                    { property: "email", label: "Email" },
                    { property: "alias", label: "Alias" },
                    // { property: "isInactiveUser", label: "Inactive User" }
                ]
            },
            profile: {
                data: user,
                fields: [
                    { property: "userBeanstoreId", label: "Beanstore ID" },
                    { property: "userCompany", label: "Company" },
                    { property: "profileName", label: "Profile Name" },
                    { property: "legacyRoleName", label: "Role Name" },
                    { property: "userShop", label: "Shop" },
                    { property: "otherCompanies", label: "Other Companies" },
                    { property: "otherShops", label: "Other Shops" }
                ]
            },
            techInfo: {
                data: user,
                fields: [
                    { property: "userCreatedDate", label: "Created Date" },
                    { property: "userCreatedById", label: "Created by ID" },
                    { property: "userLastLoginDate", label: "Last Login Date" },
                    { property: "userLastModifiedDate", label: "Last Modified Date" },
                    { property: "userLastModifiedById", label: "Last Modified by ID" },
                    { property: "notManagedByFIM", label: "Not Managed by FIM" }
                ]
            },
            salesAvisors: user?.salesAdvisors ?? []
        };
    }, [state.salesforceUser]);

    useEffect(() => {
        setHookState(salesforceData)
    }, [salesforceData]);

    return hookState;
}
import { useState, useEffect, useContext, useMemo } from 'react';
import { UserContext } from '../../../../../contexts';

export function useBeanstore() {
    const [hookState, setHookState] = useState({
        sellers: {
            data: []
        },
        cashiers: {
            data: []
        },
        availableAreas: [],
    });

    const {
        state
    } = useContext(UserContext);

    const beanstoreData = useMemo(() => {
        // enable click only on available areas
        const availableAreas = ['emea', 'emea2', 'usa', 'jako', 'apac','china']
            .map(x => {
                if (state.beanstoreCashiers.some(y => y.area?.toLowerCase() === x) ||
                    state.beanstoreSellers.some(y => y.area?.toLowerCase() === x))
                    return {
                        enabled: true,
                        text: x
                    };
                return {
                    enabled: false,
                    text: x
                };
            });

        return {
            sellers: {
                data: state.beanstoreSellers,
                fields: [
                    { property: "beanstoreId", label: "Beanstore Salesperson ID" },
                    { property: "firstName", label: "First Name" },
                    { property: "lastName", label: "Last Name" }
                ]
            },
            cashiers: {
                data: state.beanstoreCashiers,
                fields: [
                    { property: "beanstoreId", label: "Beanstore ID" },
                    { property: "firstName", label: "First Name" },
                    { property: "lastName", label: "Last Name" }
                ]
            },
            availableAreas: availableAreas
        }

    }, [state.beanstoreData]);

    useEffect(() => {
        setHookState(beanstoreData)
    }, [beanstoreData]);

    return hookState;
}
import React from "react";
import Footer from "./Footer";
import NavBar from "./NavBar";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { UserContext, InsightContext } from "./../../contexts";
import { loginRequest } from "../../authConfig";

export const PageLayout = (props) => {
    const isAuthenticated = useIsAuthenticated();
    const { loadApplicationUser } = React.useContext(UserContext);
    const { trackError } = React.useContext(InsightContext);
    const { instance } = useMsal();
    const authRequest = { ...loginRequest };

    React.useEffect(() => {
        if (!instance.getActiveAccount())
            instance.loginRedirect(authRequest).catch((error) => {
                trackError('MSAL auto-login loginRedirect error!');
                trackError(error);
            });
    }, [])

    React.useEffect(() => {
        if (isAuthenticated)
            loadApplicationUser();
    }, [isAuthenticated])

    return (
        <>
            <NavBar />
            {props.children}
            <Footer />
        </>
    );
};
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Grid, Typography } from '@material-ui/core';

import { ApplicationContext } from '../../contexts';
import useStyles from '../../styles/useStyles';

const DownloadPage = (props) => {
    const {
        downloadProcedure,
        isDownloadingProcedure
    } = useContext(ApplicationContext);

    let { fileId, fileName } = useParams();

    useEffect(() => {
        if (!!fileId && !!fileName) downloadProcedure(fileId, fileName, '_self');
    }, [fileId, fileName]);

    const classes = useStyles();

    return (
        <div className={classes.mainSectionWrapper}>
            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container spacing={1} item xs={12} md={10} className={classes.criteriaContainer}  >
                    <Grid item xs={12}>
                        {isDownloadingProcedure
                            ?
                            <Typography variant="h6">
                                Download in progress, dont close the window...
                            </Typography>
                            :
                            <Typography variant="h6">
                                Download completed, you can close the window.
                            </Typography>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default DownloadPage;
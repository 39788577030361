import React, { useMemo } from "react";
// import { Grid, ButtonGroup, Button, IconButton, Divider, LinearProgress } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Button, Grid, IconButton, List, ListItem, MenuItem, TextField } from "@material-ui/core";

// Form with an editable list of filterproperty object.
// Next to each input are buttons for insert and remove.
// If the list is empty, there is a button to add an item.
export const FilterList = ({ onSubmit, properties, loading }) => {
    const {
        control,
        handleSubmit,
    } = useForm();
    const {
        fields,
        append: appendFilter,
        remove: removeFilter,
    } = useFieldArray({
        control,
        name: "filters"
    });

    return (
        <form onSubmit={handleSubmit(data => onSubmit(data))}>
            <Grid container justifyContent="flex-end">
                <List>
                    {fields.map((item, index) => (
                        <ListItem key={item.id}>
                            <Grid container spacing={2}>
                                <Grid item xs={5}>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                select
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                            >
                                                {properties.map(p => {
                                                    const disabled = fields.some(x => x.propertyName === p.propertyName);
                                                    return <MenuItem disabled={disabled} value={p.propertyName}>{p.propertyDisplayName}</MenuItem>;
                                                }
                                                )}
                                            </TextField>
                                        )}
                                        name={`filters.${index}.propertyName`}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        render={({ field }) =>
                                            <TextField
                                                {...field}
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                            />}
                                        name={`filters.${index}.value`}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton
                                        size="small"
                                        type="button"
                                        onClick={() => removeFilter(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </ListItem>
                    ))}
                </List>
            </Grid>
            <Grid container justifyContent="flex-end">
                <Grid item xs={3}>
                    <Button
                        size="small"
                        type="button"
                        disabled={loading || fields.length >= properties.length}
                        onClick={() => appendFilter(properties.find(p => !fields.map(f => f.propertyName).includes(p.propertyName)))}
                        startIcon={<AddIcon />}
                    >Add filter</Button>
                </Grid>
                <Grid item xs={2}>
                    <Button
                        size="small"
                        variant="outlined"
                        type="submit"
                        startIcon={<SearchIcon />}
                    >search</Button>
                </Grid>
            </Grid>
        </form>
    );
};
